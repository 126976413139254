.access {
  text-align: center;
  height: 100vh;
  height: 100dvh;
  text-align: center;
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* Media query for larger screens (desktops) */
}
.access .header {
  padding: 1.88rem 1.88rem;
}
.access.default-theme {
  background-color: rgb(216, 223, 221);
  background-image: url("img/background.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.access.default-theme .logo-img {
  background-image: url("img/logos//mirigi.png");
  width: 70vw;
  height: auto;
  max-width: 260px;
  aspect-ratio: 260/70;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.access.default-theme .child-view {
  z-index: 3;
  position: relative;
}
.access.default-theme h1 {
  color: white;
}
.access.default-theme label {
  display: block;
}
.access.default-theme .icon-color {
  color: black;
}
.access.default-theme .form-input {
  margin: 0.938rem;
}
.access.default-theme .ant-input {
  border-radius: 0;
}
.access.default-theme .option-page {
  box-shadow: -2px 0 15px black;
  background-color: rgb(216, 223, 221);
  background-image: url("img/background.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  border-left: 5px solid rgb(0, 209, 184);
  display: flex;
  flex-direction: column;
  align-content: center;
}
.access.default-theme .footer {
  border-top: 1px solid rgb(0, 209, 184);
}
.access.default-theme .footer a:hover {
  color: rgb(102, 249, 234);
}
.access.default-theme .ant-form {
  border-top: 3px solid rgb(0, 209, 184);
  background: rgba(0, 0, 0, 0.1);
  max-width: 350px;
  margin: auto;
  padding: 1.56rem;
  padding-bottom: 0.6rem;
  margin-top: 3rem;
}
.access.default-theme .ant-btn {
  width: 100%;
  margin: 2rem 0;
  border-radius: 0px;
  color: rgb(255, 255, 255);
  border: 0;
  background-color: rgb(0, 209, 184);
}
.access.default-theme a {
  color: rgb(214, 220, 248);
  cursor: pointer;
}
.access.default-theme .back-link {
  text-align: left;
  background: rgb(24, 26, 24);
  padding: 0.938rem;
  border-bottom: 1px solid rgb(0, 209, 184);
  font-size: 1.6rem;
  color: white;
}
.access.default-theme .back-link p {
  display: inline-block;
  margin: 0px 0.938rem;
}
.access.default-theme .back-link a {
  color: rgb(231, 255, 254);
}
.access.default-theme .back-link a:hover {
  color: rgb(102, 249, 234);
}
.access.joy-theme {
  background-color: black;
}
.access.joy-theme input:-webkit-autofill,
.access.joy-theme input:-webkit-autofill:hover,
.access.joy-theme input:-webkit-autofill:focus,
.access.joy-theme input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px black inset !important;
  -webkit-text-fill-color: white !important;
  transition: background-color 5000s ease-in-out 0s;
}
.access.joy-theme .header {
  padding: 0;
}
.access.joy-theme .logo-img {
  background: url("img/logos//joy_white.png");
  width: 80vw;
  height: auto;
  max-width: 260px;
  aspect-ratio: 302/250;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.access.joy-theme .child-view {
  z-index: 3;
  position: relative;
}
.access.joy-theme h1 {
  color: white;
}
.access.joy-theme .login-form {
  background: none;
  max-width: 350px;
  margin: auto;
  padding: 1.56rem;
}
.access.joy-theme .icon-color {
  color: white;
}
.access.joy-theme .ant-input {
  border-radius: 0;
  background: black;
  border-radius: 1rem;
  color: white;
}
.access.joy-theme .ant-btn {
  width: 100%;
  margin: 2rem 0;
  border-radius: 0px;
  color: black;
  border-radius: 1rem;
  background-color: #ffffff;
  border: none;
}
.access.joy-theme .separator {
  width: 1px;
  height: 50%;
  background-color: white;
}
.access.joy-theme .ant-form {
  background: rgb(30, 30, 30);
  border: 1px solid grey;
  border-top: 3px solid grey;
  border-radius: 10px;
  max-width: 300px;
  margin: auto;
  padding: 1.56rem;
  padding-bottom: 0.6rem;
}
.access.joy-theme .ant-form .header {
  padding: 1.88rem 1.88rem;
}
.access.joy-theme .option-page {
  box-shadow: -2px 0 15px black;
  background-color: black;
  background-size: cover;
  margin: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  border-left: 5px solid white;
  display: flex;
  flex-direction: column;
  align-content: center;
}
.access.joy-theme .footer {
  border-top: 3px solid white;
}
.access.joy-theme .footer a:hover {
  color: gray;
}
.access.joy-theme a {
  color: rgb(214, 220, 248);
  cursor: pointer;
}
.access.joy-theme .back-link {
  text-align: left;
  background: rgb(24, 26, 24);
  padding: 0.938rem;
  border-bottom: 1px solid #ffffff;
  font-size: 1.6rem;
  color: white;
}
.access.joy-theme .back-link p {
  display: inline-block;
  margin: 0px 0.938rem;
}
.access.joy-theme .back-link a {
  color: rgb(231, 255, 254);
}
.access.joy-theme .back-link a:hover {
  color: rgb(102, 249, 234);
}
.access .portrait {
  display: flex;
  align-items: center;
}
.access .landscape {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-grow: 1;
  margin: 1rem;
}
.access .landscape .header {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.access .landscape .login-form-container {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.access .portrait {
  flex-direction: column;
}
.access .footer {
  background: rgb(24, 26, 24);
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  margin-top: auto;
}
.access .footer a {
  color: rgb(231, 255, 254);
  font-size: 1.2rem;
  line-height: 2.1rem;
}
.access .error-msg {
  color: red;
}
.access .ant-alert-error {
  border: 1px solid rgb(196, 26, 26);
  color: rgb(130, 65, 65);
  margin: 20px;
  background: rgba(123, 76, 76, 0.3);
  box-shadow: 0px 0px 16px rgb(252, 169, 169);
}
.access .ant-alert-error .ant-alert-message {
  color: rgb(253, 110, 110);
}
.access .ant-alert-error .ant-alert-description {
  color: rgb(239, 203, 203);
}
.access .ant-alert-success {
  border: 1px solid rgb(82, 196, 26);
  background-color: rgba(99, 123, 76, 0.3);
  margin: 20px;
  box-shadow: 0px 0px 16px rgb(176, 252, 169);
}
.access .ant-alert-success .ant-alert-message {
  color: rgb(156, 253, 110);
}
.access .ant-alert-success .ant-alert-description {
  color: rgb(215, 239, 203);
}
@media (max-width: 700px) {
  .access .landscape .logo-img {
    width: 20vw !important;
    max-width: 180px !important;
  }
  .access .landscape .ant-form {
    max-width: 250px;
  }
}
@media (min-width: 1200px) {
  .access.default-theme .logo-img {
    width: 40vw !important;
    max-width: 450px !important;
  }
  .access.joy-theme .logo-img {
    width: 40vw !important;
    max-width: 800px !important;
  }
}

a {
  text-decoration: none;
  color: rgb(67, 86, 171);
}

a:hover {
  color: white;
}

#theme-container {
  height: 100%;
}

.loading-screen {
  background: rgb(65, 64, 68);
  height: 100vh;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-screen.light {
  background: rgba(0, 0, 0, 0.7);
  z-index: 500;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
}
.loading-screen .loading-icon {
  color: white;
}

.frame {
  background: rgba(255, 255, 255, 0.63);
  padding: 15px;
  margin: 25px;
}
@media (max-width: 480px) {
  .frame {
    margin: 25px 0px;
  }
}
@media (min-width: 480px) and (max-width: 680px) {
  .frame {
    margin: 25px 0px;
  }
}
@media (min-width: 680px) and (max-width: 900px) {
  .frame {
    margin: 25px 0px;
  }
}

.settings .mail-list {
  margin-bottom: 1em;
  padding-left: 0;
  list-style: none;
}
.settings .mail-list li {
  display: flex;
  width: 85%;
  background: white;
  justify-content: space-between;
  margin: auto;
  padding: 8px 10px;
  margin: 10px auto;
}
.settings .mail-list li p {
  color: rgb(52, 81, 86);
  margin-bottom: 0;
  font-size: 1.3rem;
}
.settings .mail-list li .unlink {
  color: rgb(250, 150, 150);
  font-size: 1.3rem;
  cursor: pointer;
}
.settings .ant-card-bordered {
  border: 1px solid rgb(232, 232, 232);
  width: 200px;
  text-align: center;
  margin: auto;
  width: fit-content;
  font-family: courier;
}
.settings .ant-card-bordered .ant-card-body {
  padding: 1.3rem 3rem;
}
.settings .setting-option {
  display: flex;
  width: fit-content;
  margin: auto;
  padding: 0.2rem;
  margin-top: 1rem;
}
.settings .setting-option p {
  text-align: right;
  margin-bottom: 0;
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.85);
  width: 110px;
  line-height: 30px;
  font-size: 14px;
}
.settings .setting-option .ant-select {
  width: 15rem;
  align-items: center;
}
.settings .mail {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}
.settings .mail p {
  color: rgba(0, 0, 0, 0.85);
  margin: 0 0.2rem;
  font-size: 14px;
}
.settings .mail .own-email {
  color: rgb(120, 153, 137);
}
.settings .change-password {
  margin: auto;
}
.settings .change-password .submit-box {
  display: flex;
  justify-content: center;
}
.settings .change-password .submit-box .reset-msg {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}
.settings .change-password .submit-box .reset-msg.success {
  color: rgb(8, 109, 57);
}
.settings .change-password .submit-box .reset-msg.error {
  color: rgb(163, 20, 20);
}
.settings .notifications {
  display: grid;
  grid-template-columns: auto auto;
  margin: auto;
}
@media (max-width: 480px) {
  .settings .notifications {
    grid-template-columns: auto auto;
  }
}
@media (min-width: 480px) and (max-width: 680px) {
  .settings .notifications {
    grid-template-columns: auto auto;
  }
}
@media (min-width: 680px) and (max-width: 900px) {
  .settings .notifications {
    grid-template-columns: auto auto;
  }
}
@media (min-width: 900px) and (max-width: 1250px) {
  .settings .notifications {
    grid-template-columns: auto auto auto;
  }
}
@media (min-width: 1250px) and (max-width: 1500px) {
  .settings .notifications {
    width: 70%;
    grid-template-columns: auto auto auto;
  }
}
@media (min-width: 1500px) and (max-width: 2000px) {
  .settings .notifications {
    width: 70%;
    grid-template-columns: auto auto auto;
  }
}
@media (min-width: 2000px) {
  .settings .notifications {
    width: 70%;
    grid-template-columns: auto auto auto;
  }
}
.settings .notification-switch {
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin: 0.3rem auto;
}
@media (max-width: 480px) {
  .settings .notification-switch {
    width: 70%;
  }
}
@media (min-width: 480px) and (max-width: 680px) {
  .settings .notification-switch {
    width: 60%;
  }
}
@media (min-width: 680px) and (max-width: 900px) {
  .settings .notification-switch {
    width: 50%;
  }
}
@media (min-width: 680px) and (max-width: 900px) {
  .settings .notification-switch {
    width: 50%;
  }
}
@media (min-width: 900px) and (max-width: 1250px) {
  .settings .notification-switch {
    width: 50%;
  }
}
@media (min-width: 1250px) and (max-width: 1500px) {
  .settings .notification-switch {
    width: 50%;
  }
}
@media (min-width: 1500px) and (max-width: 2000px) {
  .settings .notification-switch {
    width: 50%;
  }
}
@media (min-width: 2000px) {
  .settings .notification-switch {
    width: 40%;
  }
}
.settings .version-displayer {
  filter: drop-shadow(0px 0px 2px rgba(255, 255, 255, 0.6901960784));
  color: black;
  font-weight: bolder;
  margin-bottom: calc(env(safe-area-inset-bottom) * 0.5);
  display: inline-block;
  margin-left: 1rem;
}

.dashboard {
  display: flex;
  flex-flow: column;
  height: 100vh;
  height: 100dvh;
  background: rgb(99, 102, 106);
  overflow: hidden;
  position: relative;
}
.dashboard ::-webkit-scrollbar {
  width: 0px;
  display: none;
}
.dashboard h1 {
  text-align: center;
}
.dashboard .inner-dashboard {
  height: 100vh;
  height: 100dvh;
  width: 100%;
  overflow-y: scroll;
}
.dashboard .hidden-menu {
  flex-shrink: 0;
  box-shadow: inset 0px 30px 30px -22px black, inset 0px -22px 30px -22px black;
  font-family: helvetica;
  text-decoration: none;
  font-size: 1.25rem;
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 0px;
  overflow: hidden;
  background: rgb(51, 51, 51);
}
.dashboard .hidden-menu ul {
  list-style: none;
  padding: 0;
  padding-bottom: 0.5rem;
  margin: 0;
}
.dashboard .hidden-menu ul li {
  color: white;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-family: helvetica;
  cursor: pointer;
  height: 21px;
  margin: 0.5rem 0px;
}
.dashboard .hidden-menu .inner {
  flex-shrink: 0;
  padding: 0px;
  padding-top: 20px;
}
.dashboard .hidden-menu .ant-divider-horizontal {
  margin: 0.82rem auto;
  width: 90%;
  background: rgb(91, 91, 91);
}
.dashboard .hidden-menu .ant-form {
  background: none;
}
.dashboard .hidden-menu .options {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  font-size: 1.3rem;
}
.dashboard .hidden-menu .options a {
  color: white;
  text-decoration: none;
}
.dashboard .hidden-menu .options a i {
  margin-right: 6px;
}
.dashboard .hidden-menu.hidden {
  max-height: 0;
  transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);
}
.dashboard .hidden-menu.visible {
  max-height: 2000px;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
}
.dashboard .header {
  display: flex;
  flex-shrink: 0;
  width: 100%;
  height: 13rem;
  position: relative;
  background: white;
  text-align: center;
}
.dashboard .header::before {
  content: "";
  position: absolute;
  display: block;
  top: calc(-2 * constant(safe-area-inset-top));
  top: calc(-2 * env(safe-area-inset-top));
  height: constant(safe-area-inset-top);
  height: env(safe-area-inset-top);
  width: 100%;
  box-shadow: 0px calc(1.5 * constant(safe-area-inset-top)) calc(1.5 * constant(safe-area-inset-top)) rgba(0, 0, 0, 0.3137254902);
  box-shadow: 0px calc(1.5 * env(safe-area-inset-top)) calc(1.5 * env(safe-area-inset-top)) rgba(0, 0, 0, 0.3137254902);
}
.dashboard .header .toggle-menu {
  position: absolute;
  bottom: 5px;
  right: 5px;
  display: inline-flex;
  cursor: pointer;
}
.dashboard .header .toggle-menu h3 {
  display: none;
}
.dashboard .header .toggle-menu .toggle-menu-content {
  display: flex;
  align-items: center;
}
.dashboard .header .toggle-menu .toggle-menu-content .toggle-menu-label {
  line-height: normal;
  padding-right: 0.32rem;
  font-size: 1.1rem;
}
.dashboard .header .toggle-menu .toggle-menu-content .toggle-menu-button {
  margin-top: 0;
  font-size: 1.8rem;
  line-height: 0;
  margin-right: 5px;
  margin-bottom: 5px;
}
.dashboard .header .toggle-menu-settings {
  margin-top: 0;
  font-size: 1.5rem;
  line-height: 1.5rem;
  margin-left: 5px;
  margin-bottom: 3px;
  margin-right: 5px;
}
.dashboard .header .toggle-menu-button.hidden {
  transform: rotate(0deg);
  transition: transform 0.3s;
}
.dashboard .header .toggle-menu-button.visible {
  transform: rotate(180deg);
  transition: transform 0.3s;
}
.dashboard .header img {
  height: 5rem;
  width: 80%;
  margin: auto;
  object-fit: scale-down;
  justify-content: center;
  align-items: center;
}

.choose-another-unit-or-logout-modal-component li {
  display: flex;
  justify-content: center;
  font-weight: bold;
  margin-top: 0.5rem;
}

.pageview-banner {
  display: none;
}

.page-header {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}
.page-header h1 {
  font-size: 2.5rem;
  letter-spacing: 2px;
  color: rgb(99, 102, 106);
  text-transform: capitalize;
  margin-top: 4rem;
  margin-left: 1.4rem;
  text-align: left;
  font-weight: normal;
}
.page-header .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: rgba(0, 0, 0, 0.64);
  position: fixed;
  z-index: 100;
  top: 0;
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}
.page-header .top::before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  height: constant(safe-area-inset-top);
  height: env(safe-area-inset-top);
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2509803922);
}
.page-header .top .back-link, .page-header .top .info-button {
  justify-content: center;
  height: 4rem;
  width: 8rem;
  display: flex;
}
.page-header .top .back-link .icon, .page-header .top .info-button .icon {
  align-self: center;
  color: white;
  font-size: 2rem;
}
.page-header .top .back-link {
  padding-right: 4rem;
}
.page-header .top .info-button {
  padding-left: 4rem;
}
.page-header h1.title {
  text-align: left;
}

.page-switcher {
  position: absolute;
  top: 0;
  width: 100%;
}

.slide-enter, .slide-exit-done {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  will-change: transform;
}

.slide-enter-active {
  -webkit-transform: none;
  transform: none;
  transition: transform 300ms ease-out;
}

.slide-exit, .slide-enter-done {
  -webkit-transform: none;
  transform: none;
  will-change: transform;
}

.slide-exit-active {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  transition: transform 300ms ease-out;
}

.page-description p {
  white-space: pre-wrap;
}

.guest .top {
  display: flex;
}
.guest .top img {
  margin: auto;
}
.guest .valet .page-frame .board {
  display: flex;
  margin-top: 30px;
  height: calc(100vh - 230px);
  height: calc(100dvh - 230px);
}
.guest .valet .page-frame .board .vehicle {
  width: 80%;
  height: 100%;
  max-width: 500px;
  margin: auto;
}
.guest .valet .page-frame .board .vehicle .vehicle-info .vehicle-state {
  width: 100%;
  font-size: 2rem;
  bottom: 4rem;
}
.guest .ant-alert-info {
  margin: auto;
}

.md-renderer.text.frame div > span > p {
  color: rgba(0, 0, 0, 0.9);
  font-weight: 400;
}
.md-renderer.text.frame div > span > p:has(img) {
  display: flex;
  justify-content: center;
}
.md-renderer.text.frame .pdf-canvas {
  border: "1px solid black";
  background-color: "#f0f0f0";
  padding-left: 0;
  padding-right: 0;
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 800px;
}
.md-renderer.text.frame .buttons-separator {
  display: flex;
  gap: 2rem;
}
.md-renderer.text.frame .pdf-page-selector {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 2rem;
}

.vehicle {
  float: left;
  width: 216px;
  height: 300px;
  margin: 15px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
}
.vehicle .logo-bar {
  background-color: rgb(241, 241, 241);
  text-align: center;
  line-height: 80px;
  height: 80px;
  margin: 0px;
}
.vehicle .logo-bar .logo-make {
  max-height: 66px;
  max-width: 130px;
  vertical-align: middle;
}
.vehicle .vehicle-info {
  background-color: rgb(221, 221, 221);
  color: rgb(119, 119, 119);
  margin: 0px;
  flex-grow: 1;
  position: relative;
  display: block;
  padding: 0.8rem;
  position: relative;
  height: 100%;
}
.vehicle .vehicle-info .vehicle-tag {
  font-size: 2.375rem;
  margin: 0px;
}
.vehicle .vehicle-info .vehicle-make {
  font-size: 1.25rem;
  margin: 0px;
}
.vehicle .vehicle-info .vehicle-state {
  text-align: center;
  font-size: 1.375rem;
  width: 216px;
  position: absolute;
  bottom: 8px;
  right: 0px;
  vertical-align: bottom;
  margin: 0px;
}
.vehicle .vehicle-info .button-holder {
  position: absolute;
  width: calc(100% - 1.6rem);
  bottom: 8px;
}
.vehicle .vehicle-info .button-holder .flat-button {
  font-family: inherit;
  color: white;
  font-size: 1rem;
  font-weight: normal;
  border: solid 1px white;
  line-height: 2.75rem;
  text-align: center;
  margin-top: 5px;
  -webkit-appearance: none;
  opacity: 1;
  cursor: pointer;
  width: 100%;
}
.vehicle .vehicle-info .button-holder .flat-button.carwash {
  background-color: rgb(136, 156, 198);
}
.vehicle .vehicle-info .button-holder .flat-button.request {
  background-color: rgb(140, 191, 173);
}
.vehicle .vehicle-info .button-holder .flat-button.cancel {
  background-color: rgb(201, 107, 107);
}
.vehicle.ready .vehicle-info {
  background: rgb(99, 102, 106);
  color: rgb(232, 232, 232);
}
.vehicle.ready .logo-bar {
  background: rgba(255, 255, 255, 0.45);
}
.vehicle.requested .vehicle-info {
  background: rgb(153, 153, 153);
  color: rgb(232, 232, 232);
}
.vehicle.requested .logo-bar {
  background: rgba(255, 255, 255, 0.45);
}
.vehicle.carwash_requested .vehicle-info {
  background: rgb(153, 153, 153);
  color: rgb(232, 232, 232);
}
.vehicle.carwash_requested .logo-bar {
  background: rgba(255, 255, 255, 0.45);
}
.vehicle.parked .vehicle-info {
  background: rgba(203, 203, 203, 0.78);
  color: rgb(121, 121, 121);
}
.vehicle.parked .logo-bar {
  background: rgba(255, 255, 255, 0.45);
}
.vehicle.washing .vehicle-info {
  background: rgba(255, 255, 255, 0.45);
  color: #577c9a;
}
.vehicle.washing .logo-bar {
  background: rgba(255, 255, 255, 0.45);
}
.vehicle.topark .vehicle-info {
  background: rgba(255, 255, 255, 0.45);
  color: rgb(187, 120, 96);
}
.vehicle.topark .logo-bar {
  background: rgba(255, 255, 255, 0.45);
}
.vehicle.out .vehicle-info {
  background: rgba(255, 255, 255, 0.45);
  color: rgb(132, 132, 132);
}
.vehicle.out .logo-bar {
  background: rgba(255, 255, 255, 0.45);
}

.hidden-message {
  opacity: 0;
  text-align: center;
  width: calc(100% - 1rem);
  position: absolute;
  bottom: 60px;
  margin: auto;
}

@keyframes spontaneous-apparition-animation {
  0% {
    opacity: 0;
  }
  3% {
    opacity: 100;
  }
  75% {
    opacity: 100;
  }
  100% {
    opacity: 0;
  }
}
.spontaneous-apparition {
  animation-name: spontaneous-apparition-animation;
  animation-duration: 4s;
}

@media (max-width: 900px) {
  .valet .board {
    flex-wrap: nowrap;
    width: 100%;
    height: unset;
    overflow-x: scroll;
    margin-top: 10rem;
  }
}
.messages .readed {
  color: grey;
}

.page-ul .body ul {
  padding: 0;
}
.page-ul .body ul li {
  margin: 0;
  padding: 5px;
  color: rgb(77, 77, 77);
}
.page-ul .body ul li strong {
  color: rgb(81, 95, 132);
  font-weight: normal;
}

.text {
  font-size: 14px;
  font-weight: lighter;
}

.custom-form {
  background: none;
}
.custom-form .form-label {
  margin: 0;
  padding: 0;
  background: none;
  display: inline-flex;
}
.custom-form .form-input {
  padding: 10px;
  display: block;
}
.custom-form .form-input label {
  color: rgb(41, 67, 86);
  margin: 15px 5px;
}

.ant-input-search {
  margin: 0.5rem !important;
}

.submit-button-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 1rem;
  background-color: white;
}

.guest.custom-page-wrapper {
  height: 100vh;
  height: 100dvh;
  top: 0;
  position: absolute;
  width: 100%;
  z-index: 100;
}

.event-row {
  display: flex;
  justify-content: space-between;
}

.event-bg {
  width: 98%;
}
.event-bg .line {
  width: 100%;
  height: 0.5px;
  background-color: #c5c5c5;
  margin: 1rem 0 1rem;
}

.center-options {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.center-date {
  margin: 5px 15px 5px 0px;
}

.event-column {
  flex: 1;
  padding: 10px;
  box-sizing: border-box;
}
.event-column p {
  text-align: center;
  font-size: 18px;
}

.event-title {
  color: #000000;
  font-size: 20px;
}

.centertext {
  text-align: center;
  box-sizing: border-box;
  margin-top: 1rem;
}

.page-frame.servicios .page-frame .frame .contact-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 2rem;
  padding: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #fafafa;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 30rem;
}
.page-frame.servicios .page-frame .frame .contact-box img {
  width: 150px;
  height: 150px;
  border-radius: 90%;
  padding: 0.5rem;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.page-frame.servicios .page-frame .frame .contact-box .contact-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  background: #fff;
  width: 100%;
  max-width: 30rem;
  padding: 1.5rem;
  border-radius: 8px;
}
.page-frame.servicios .page-frame .frame .contact-box .contact-item h1 {
  text-align: left;
  font-weight: 400;
  font-size: 1.6rem;
  margin: 0;
  color: #333;
}
.page-frame.servicios .page-frame .frame .contact-box .contact-item .position {
  font-size: 1rem;
  color: rgb(177.5, 177.5, 177.5);
  margin-bottom: 1rem;
}
.page-frame.servicios .page-frame .frame .contact-box .contact-item .contact {
  font-size: 1rem;
  color: #666;
}
.page-frame.servicios .page-frame .frame .contact-box .contact-item p, .page-frame.servicios .page-frame .frame .contact-box .contact-item a {
  font-size: 0.875rem;
}
@media (max-width: 400px) {
  .page-frame.servicios .contact-box {
    flex-direction: column !important;
    align-items: center;
    width: 60vw !important;
    padding: 1rem;
  }
  .page-frame.servicios .contact-box img {
    width: 40vw !important;
    height: auto;
    margin-bottom: 1rem;
  }
  .page-frame.servicios .contact-box .contact-item {
    width: 100%;
    text-align: center;
  }
  .page-frame.servicios .contact-box .contact-item h1 {
    text-align: center !important;
  }
}

.list-item-request {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0 1rem 0;
}
.list-item-request .header-item {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
}
.list-item-request .header-item .header-description {
  display: flex;
  flex-direction: column;
}
.list-item-request .header-item .header-description .header-title {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.list-item-request .header-item .header-description .header-title span {
  padding-bottom: 7px;
}
.list-item-request .header-item .header-description .container-state {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
}
.list-item-request .header-item .header-description .container-state .descriptions-req {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}
.list-item-request .header-item h2 {
  margin: 0;
}
.list-item-request .header-item .container-actions {
  display: flex;
  align-items: flex-start;
  margin-right: 2rem;
  gap: 1rem;
  flex-wrap: wrap;
}
.list-item-request .content-item {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  gap: 2rem;
}
.list-item-request .content-item .collapse {
  background-color: #fff;
}
.list-item-request .content-item .details-item {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  border-top: 1px solid rgba(115, 115, 115, 0.3490196078);
}
.list-item-request .content-item .details-item .content-details {
  display: flex;
  flex-direction: column;
}
.list-item-request .content-item .container-messages-chat {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  margin: 0.5rem 0 1rem 0;
  padding: 0.5rem;
  max-height: 25rem;
  overflow-y: scroll;
}
.list-item-request .content-item .container-messages-chat .item-container {
  display: flex;
}
.list-item-request .content-item .container-messages-chat .item-container .item {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  margin: 0.5rem;
  background-color: #ebebeb;
  border-radius: 10px;
  z-index: 0;
}
.list-item-request .content-item .container-messages-chat .item-container .item .title-message h3 {
  margin: 0;
}
.list-item-request .content-item .container-messages-chat .item-container .item .message {
  display: flex;
  padding: 0.5rem;
  gap: 0.5rem;
}
.list-item-request .content-item .container-messages-chat .item-container .item .message p {
  flex: 5;
}
.list-item-request .content-item .container-messages-chat .item-container .item .date-message {
  display: flex;
  justify-content: flex-end;
  padding-right: 0.5rem;
}
.list-item-request .content-item .container-messages-chat .item-container .item .date-message p {
  text-align: center;
  font-size: 12px;
}
.list-item-request .content-item .container-messages-chat .item-container.self {
  justify-content: flex-end;
  margin-left: 2rem;
}
.list-item-request .content-item .container-messages-chat .item.self {
  background-color: #dcf8c6;
}
.list-item-request .content-item .container-messages-chat .item.self, .list-item-request .content-item .container-messages-chat .item.other {
  box-shadow: 0.5px 0.5px 0.5px rgba(105, 105, 105, 0.384);
}
.list-item-request .content-item .container-messages-chat .item-container.other {
  justify-content: flex-start;
  margin-right: 2rem;
}
.list-item-request .content-item .container-messages-chat .item-create, .list-item-request .content-item .container-messages-chat .item-assign, .list-item-request .content-item .container-messages-chat .item-ack, .list-item-request .content-item .container-messages-chat .item-close, .list-item-request .content-item .container-messages-chat .item-cancel, .list-item-request .content-item .container-messages-chat .item-reopen {
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  margin: 0.5rem 1.5rem 0.5rem 1.5rem;
  border-radius: 10px;
  text-align: center;
}
.list-item-request .content-item .container-messages-chat .item-create {
  background-color: rgba(250, 217, 169, 0.5215686275);
}
.list-item-request .content-item .container-messages-chat .item-assign {
  background-color: rgba(151, 201, 255, 0.5215686275);
}
.list-item-request .content-item .container-messages-chat .item-ack {
  background-color: rgba(151, 255, 250, 0.5215686275);
}
.list-item-request .content-item .container-messages-chat .item-close {
  background-color: rgba(255, 151, 151, 0.5215686275);
}
.list-item-request .content-item .container-messages-chat .item-cancel {
  background-color: rgba(255, 151, 151, 0.5215686275);
}
.list-item-request .content-item .container-messages-chat .item-reopen {
  background-color: rgba(248, 255, 151, 0.6509803922);
}
.list-item-request .content-item .text-chat {
  display: flex;
  position: relative;
  gap: 0.5rem;
}
.list-item-request .content-item .text-chat .button-submit {
  display: flex;
  align-items: flex-end;
  padding-bottom: 0.5rem;
}
.list-item-request .content-item .text-chat .button-submit .button-send-msg {
  color: white;
  background-color: black;
  border-color: #3f3f3f;
}
.list-item-request .content-item .text-chat .button-go-down-chat {
  position: absolute;
  right: 50%;
  bottom: 140%;
  color: black;
  background-color: white;
  border-color: #3f3f3f;
  z-index: 100;
}

.calendar {
  display: flex;
  justify-content: center;
  margin: auto;
  text-align: center;
}
.calendar .cell-wrapper {
  display: flex;
  flex-flow: column;
  justify-content: center;
  width: 60%;
}
.calendar .cell-wrapper .calendar-cell {
  position: relative;
  background: white;
  border: none;
  margin: 1px;
  font-style: 1.3rem;
  padding: 1.3rem 0;
}
.calendar .cell-wrapper .navigation-bar {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  height: 4rem;
  line-height: 4rem;
  font-size: 2rem;
}
.calendar .cell-wrapper .from {
  font-size: 2rem;
}
.calendar .cell-wrapper .until {
  font-size: 1rem;
}

.item-list {
  display: flex;
  flex-flow: column;
}
.item-list .reservation-comment {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1rem;
  color: #ff0000;
}
.item-list .reservation-comment p {
  text-align: center;
  margin: 0;
}
.item-list .chooser-cell {
  display: flex;
  position: relative;
  flex-flow: row;
  min-height: 4rem;
  align-items: center;
  gap: 0.5rem;
  margin: 0.2rem 0 0.2rem 0;
  padding: 0.2rem 0 0.2rem 0;
  border-radius: 8px;
}
.item-list .chooser-cell .item-name, .item-list .chooser-cell .item-description, .item-list .chooser-cell .item-price {
  padding: 0 0.5rem;
  flex: 1;
}
.item-list .chooser-cell .item-price {
  text-align: center;
}
.item-list .chooser-cell .item-name {
  font-weight: bold;
  text-align: center;
}
.item-list .chooser-cell .item-description p {
  margin: 0;
}
.item-list .chooser-cell .item-availability {
  text-align: center;
  width: 8rem;
}
.item-list .chooser-cell .item-availability .availability-element .loading-component {
  display: flex;
  justify-content: center;
  align-items: center;
}
.item-list .chooser-cell .item-availability .availability-element .loading-component .ant-spin-spinning {
  display: flex;
}
.item-list .chooser-cell .item-availability .availability-element .loading-component .ant-spin-spinning .anticon-spin::before {
  display: flex;
  justify-content: center;
  align-items: center;
}
.item-list .chooser-cell .item-availability .availability-element > div {
  display: flex;
  flex-flow: row;
  align-items: center;
}
.item-list .chooser-cell .item-availability .availability-element > div .ant-input-number {
  margin-right: 0.188rem;
}
.item-list .chooser-cell .item-availability .availability-element > div .ant-input-number .ant-input-number-handler-wrap {
  display: none;
}
.item-list .chooser-cell .item-price {
  text-align: center;
}
.item-list .chooser-cell::after {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 0.062rem;
  content: "";
  background-image: linear-gradient(to right, transparent, rgb(230, 230, 230), transparent);
}

.ant-fullcalendar-fullscreen {
  max-width: 73vh;
  width: calc(100vw - 1rem);
  margin: auto;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-header {
  position: relative;
}
.ant-fullcalendar-fullscreen .ant-select-selection--single {
  background: none;
  border-bottom: 1px solid;
  border: none;
  border-bottom: 1px solid;
  border-radius: 0;
}
.ant-fullcalendar-fullscreen .ant-select-selection--single .ant-select-arrow {
  color: inherit;
}
.ant-fullcalendar-fullscreen .ant-select-selection--single:focus {
  border-top: none;
  border-left: none;
  border-right: none;
  box-shadow: none;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-header {
  background: grey;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
}
.ant-fullcalendar-fullscreen .ant-radio-group {
  display: none;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-calendar-body {
  padding: 0;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-calendar-body .ant-fullcalendar-cell {
  background: none;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-calendar-body span.ant-fullcalendar-column-header-inner {
  color: white;
  text-align: center;
  font-weight: bold;
  margin: 0.5rem 0;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-calendar-body .ant-fullcalendar-column-header {
  padding-right: 0;
  padding-bottom: 0;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-calendar-body .date-slot {
  background: white;
  height: 0;
  padding-bottom: 100%;
  border: 1px solid transparent;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-calendar-body .date-slot a {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-calendar-body .date-slot .message {
  font-size: 0.9rem;
  margin: 0px;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-calendar-body .ant-fullcalendar-cell.ant-fullcalendar-next-month-btn-day .date-slot,
.ant-fullcalendar-fullscreen .ant-fullcalendar-calendar-body .ant-fullcalendar-cell.ant-fullcalendar-disabled-cell .date-slot {
  opacity: 0.5;
  background: none;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-calendar-body tr {
  padding: 0;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-calendar-body thead {
  background: grey;
  font-size: 1rem;
}

.ant-modal-content {
  position: relative;
  border-radius: 0 !important;
  overflow-y: auto;
}
.ant-modal-content h1 {
  text-align: center;
}

h1.item-list-title {
  font-size: 2.5rem;
  border-bottom: 1px solid;
  margin-bottom: 2rem;
}

.ant-dropdown-menu {
  height: 250px;
  overflow-y: scroll;
}

#root .date-picker a:hover {
  color: black;
}

.forme {
  background: rgb(210, 232, 217) !important;
}

.foranother {
  background: rgb(201, 201, 201) !important;
}

.closed {
  background: rgb(201, 201, 201) !important;
}

.loading::after {
  content: "";
  background-color: rgba(0, 0, 0, 0.1254901961);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.automation.box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.automation.box .widgets-box {
  display: flex;
  flex-direction: column;
  margin: 15px;
}
.automation.box .widgets-box h2 {
  background: white;
  padding: 0.5rem;
  margin-bottom: 0;
  text-align: center;
}
.automation.box .widgets-box h3 {
  text-align: center;
  font-weight: bold;
  color: white;
  height: 2.5rem;
  font-size: 1.5rem;
}
.automation.box .widgets-box .zones {
  background: grey;
  padding: 10px;
  display: flex;
}
.automation.box .widgets-box .zones .zone {
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.automation.box .widgets-box .zones .zone .ant-btn {
  background: none;
  border-radius: 0;
  margin: 0.3rem;
  color: white;
  line-height: 2.5rem;
  height: 2.5rem;
  padding: 0 2rem;
}
.automation.box .widgets-box .zones .widget.active {
  background: white;
  color: grey;
}
@media (max-width: 900px) {
  .automation.box .widgets-box {
    margin: 10px auto;
    width: 90%;
  }
  .automation.box .widgets-box .zones {
    display: flex;
    flex-direction: column;
  }
  .automation.box .widgets-box .zones .zone {
    width: 100%;
  }
}

.weather .board .square {
  background-size: 6rem;
  background-repeat: no-repeat;
  background-position: center;
}
.weather .weather-footer {
  position: fixed;
  bottom: 0;
  padding: 0.5rem;
}

.camera {
  padding: 0.7rem;
  background: rgba(255, 255, 255, 0.52);
}
.camera img {
  width: 100%;
  max-height: 82vh;
}

.events .event img {
  float: right;
  max-width: 100px;
  max-height: 100px;
}

.events .event {
  margin: 2px 0;
  padding: 2px 0;
  background: white;
}
.events .event:after {
  content: "";
  display: table;
  clear: both;
}

.events {
  background: lightgrey;
}

.invitations .container-add-guest {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 2rem;
}
.invitations .item-invitation li .container-header-guest {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.invitations .item-invitation li .container-header-guest .header-guest {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  row-gap: 0;
}
.invitations .item-invitation li .container-header-guest .container-details-guest {
  flex-grow: 1;
  text-align: end;
}
.invitations .item-invitation li .body-guest {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}
.invitations .item-invitation li .body-guest .item-auth-active {
  display: flex;
  gap: 0.5rem;
  margin-left: 1rem;
  align-items: baseline;
}
.invitations .item-invitation li .buttons-guest {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.invitations .container-pagination {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
.invitations .autho-details {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
}
.invitations .autho-details .title-bar {
  display: flex;
  justify-content: space-between;
}
.invitations .autho-details .title-bar h3 {
  font-size: 20px;
}
.invitations .autho-details .title-bar .buttons-modal-guest {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  justify-content: flex-end;
}
.invitations .autho-details .content .container-auth-details {
  display: flex;
  flex-direction: column;
  margin: 1rem;
}
.invitations .autho-details .content .title {
  display: flex;
  justify-content: center;
  border-radius: 3px;
  background-color: rgba(150, 209, 150, 0.7098039216);
}
.invitations .autho-details .content .title h3 {
  margin: 0;
  padding: 0.5rem;
  font-weight: bold;
}
.invitations .entries-details {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
}
.invitations .entries-details .header-event {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.invitations .entries-details .header-event .type-of-event {
  display: flex;
  align-items: center;
}
.invitations .entries-details .header-event .check-in {
  color: green;
}
.invitations .entries-details .header-event .check-out {
  color: red;
}
.invitations .entries-details .event-hr {
  margin: 1rem 0 1rem 0;
}
.invitations .entries-details .container-message-event {
  text-align: center;
  background-color: #f5f5f5;
  padding: 1rem;
  border-radius: 8px;
}
.invitations .entries-details .container-message-event .title-message-event {
  color: #333;
  font-size: 15px;
  margin-bottom: 10px;
}
.invitations .entries-details .container-message-event .p-message-event {
  color: #666;
  font-size: 14px;
  margin: 0;
}
.invitations .container-add-items {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}
.invitations .container-add-items .item-guest-entry {
  background-color: rgba(255, 255, 255, 0.51);
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.invitations .container-add-items .item-guest-entry .header-item {
  display: flex;
  justify-content: space-between;
}
.invitations .container-add-items .item-guest-entry .header-item .container-frequency-select {
  display: flex;
  align-items: center;
}
.invitations .container-add-items .item-guest-entry .header-item .container-frequency-select .frequency-label {
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.85);
}
.invitations .container-add-items .item-guest-entry .header-item .container-frequency-select .select {
  background: rgba(255, 255, 255, 0.51);
  padding: 5px;
  border: 1px solid #ccc;
}
.invitations .container-add-items .item-guest-entry .line {
  flex-grow: 1;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.85);
  margin-bottom: 20px;
  margin-top: 20px;
  margin-right: 40px;
}
.invitations .container-add-items .item-guest-entry .form-item-autho {
  margin-bottom: 20px;
}
.invitations .container-add-items .item-guest-entry .form-item-autho .form-label-autho {
  color: rgba(0, 0, 0, 0.85);
  display: block;
  margin-bottom: 5px;
}
.invitations .container-add-items .item-guest-entry .form-item-autho .checkbox-group-label {
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 10px;
}
.invitations .container-add-items .item-guest-entry .form-item-autho .checkbox-list {
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
}
.invitations .container-add-items .item-guest-entry .form-item-autho .checkbox-list .checkbox-list-item {
  margin-right: 20px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.invitations .container-add-items .item-guest-entry .form-item-autho .checkbox-list .checkbox-list-item .checkbox-input {
  margin-right: 5px;
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid;
  border-radius: 3px;
  cursor: pointer;
}
.invitations .container-add-items .item-guest-entry .form-item-autho .checkbox-list .checkbox-list-item .checkbox-input:checked {
  background-color: #19d8ff;
}
.invitations .save-button {
  margin: 1rem;
}
.invitations .container-button-item {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin: 1rem;
}
.invitations .container-button-item .container-plus-circle {
  font-size: 40px;
}
.invitations .container-button-item h3 {
  margin: 0;
}
.invitations .container-buttons .buttons {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.settings-container {
  display: block;
  margin: 25px;
}
.settings-container .anticon {
  margin-right: 4px;
}

.item-list-alert {
  margin-bottom: 20px;
  padding-left: 0;
  list-style: none;
}

.item-alert {
  margin-bottom: 8px;
}

.corner h2 {
  color: red;
}

.square.active {
  position: relative;
}

.ribbon {
  position: absolute;
  left: -1px;
  top: -1px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}
.ribbon span {
  font-size: 12px;
  font-weight: bold;
  color: #FFF;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  width: 100px;
  display: block;
  background: #79A70A;
  background: linear-gradient(#eb4634 0%, #802a20 100%);
  box-shadow: 0 3px 10px -5px rgb(0, 0, 0);
  position: absolute;
  top: 19px;
  left: -21px;
}

.body-alert {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  min-height: 100dvh;
}
.body-alert .container-emergency {
  position: fixed;
  width: 35rem;
  min-height: 20rem;
  max-width: 90vw;
  margin-bottom: 10rem;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.5);
}
.body-alert .container-emergency .help-title {
  border-bottom: 2px solid black;
  padding: 5px;
  text-align: center;
}
.body-alert .container-emergency .help-text {
  font-size: 18px;
  color: #e74c3c;
  text-align: center;
  padding-top: 1.5rem;
}
.body-alert .container-emergency .footer-container {
  position: relative;
  height: 4rem;
}
.body-alert .container-emergency .footer-container div.date {
  position: absolute;
  bottom: 0rem;
  left: 0rem;
  padding: 0;
}
.body-alert .container-emergency .footer-container .cancel-button {
  position: absolute;
  bottom: 0rem;
  right: 0rem;
}
.body-alert .container-emergency .footer-container .cannot-cancel {
  position: absolute;
  bottom: 0rem;
  right: 0rem;
  margin: auto;
  background-color: #f2f2f2;
  color: #333;
  border: 1px solid #ddd;
  padding: 8px 12px;
  border-radius: 4px;
}
.body-alert .container-emergency .emergency-state {
  background-color: #f2f2f2;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  text-align: center;
  margin-top: 1.5rem;
}
.body-alert .container-emergency .emergency-state .emergency-state-title {
  text-align: center;
  font-size: 18px;
  color: #333;
  margin: 0;
}

.container-help-requested {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #e74c3c;
  padding: 1rem;
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}
.container-help-requested .title-help-requested h1 {
  color: white;
  text-align: center;
  margin: 0;
}
.container-help-requested .button-help-requested {
  text-align: right;
}

.back-link-emergency {
  position: absolute;
  color: white;
  top: 1rem;
  left: 1rem;
  font-size: 2rem;
  padding: 0.5rem;
  cursor: pointer;
}

.expenses .container-body-expenses {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
}
.expenses .container-body-expenses .container-header-pay {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 1rem;
  max-width: 90vw;
}
.expenses .container-body-expenses .container-header-pay .select-type-account {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
.expenses .container-body-expenses .container-header-pay .select-type-account h4 {
  margin: 0;
  text-align: center;
}
.expenses .container-body-expenses .container-header-pay.page-error {
  margin-bottom: 1rem;
}
.expenses .container-body-expenses .container-content-expenses {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  width: 40rem;
  max-width: 90vw;
  margin-bottom: 20rem;
  background-color: white;
}
.expenses .container-body-expenses .container-content-expenses .line {
  width: 100%;
  height: 0.5px;
  background-color: #c5c5c5;
}
.expenses .container-body-expenses .container-content-expenses .container-current-balance {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 1rem;
}
.expenses .container-body-expenses .container-content-expenses .container-current-balance .current-balance {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}
.expenses .container-body-expenses .container-content-expenses .container-current-balance .current-balance p, .expenses .container-body-expenses .container-content-expenses .container-current-balance .current-balance h2 {
  margin: 0;
  text-align: center;
}
.expenses .container-body-expenses .container-content-expenses .container-current-balance .current-balance p {
  font-size: 20px;
}
.expenses .container-body-expenses .container-content-expenses .container-current-balance .current-balance .container-amount-help {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  align-items: center;
  background-color: #ededed;
  margin: 0;
  padding: 5px;
  border-radius: 10px;
  flex-wrap: wrap;
}
.expenses .container-body-expenses .container-content-expenses .container-current-balance .next-due-date {
  display: flex;
  justify-content: center;
}
.expenses .container-body-expenses .container-content-expenses .container-current-balance .next-due-date p {
  margin: 0;
  text-align: center;
}
.expenses .container-body-expenses .container-content-expenses .container-pay-button {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}
.expenses .container-body-expenses .container-content-expenses .container-pay-button .pay-button {
  width: 100%;
}
.expenses .container-transactions {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
}
.expenses .container-transactions .even-row {
  background-color: #f0f0f0;
}
.expenses .container-transactions .odd-row {
  background-color: #ffffff;
}
.expenses .container-transactions .container-icon-pdf {
  font-size: 20px;
  padding-left: 38%;
  color: #0070C0;
}
.expenses .container-transactions .ant-spin-container {
  display: flex;
  flex-direction: column;
}
.expenses .container-transactions .ant-spin-container ul {
  display: flex;
  justify-content: center;
}
.expenses .container-link-pdf {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
}
.expenses .container-pdf {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.expenses .container-pdf #pdf-canvas {
  width: 80%;
}
.expenses .container-pay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.expenses .container-pay .container-box-pay {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.5rem;
  background-color: white;
  max-width: 90vw;
  margin: 2rem 0 5rem;
}
.expenses .container-pay .container-box-pay .container-header-balance {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
}
.expenses .container-pay .container-box-pay .container-header-balance .container-total-input {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.expenses .container-pay .container-box-pay .container-header-balance .container-total-input h2 {
  margin: 0;
}
.expenses .container-pay .container-box-pay .container-header-balance .container-balance {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  background-color: #ededed;
  margin: 0;
  padding: 5px;
  border-radius: 10px;
  flex-wrap: wrap;
}
.expenses .container-pay .container-box-pay .container-header-balance .container-balance p {
  margin: 0;
  text-align: center;
}
.expenses .container-pay .container-box-pay .container-header-balance .container-balance .icon-question-circle {
  font-size: 20px;
  color: rgb(0, 126, 255);
}
.expenses .container-pay .container-box-pay .line {
  width: 100%;
  height: 0.5px;
  background-color: #c5c5c5;
}
.expenses .container-pay .container-box-pay .container-payment-methods {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.expenses .container-pay .container-box-pay .container-payment-methods h2 {
  text-align: center;
}
.expenses .container-pay .container-box-pay .container-payment-methods .container-methods {
  display: flex;
  align-items: center;
  gap: 1rem;
  max-width: 80vw;
}
.expenses .container-pay .container-box-pay .container-payment-methods .container-methods .left-arrow, .expenses .container-pay .container-box-pay .container-payment-methods .container-methods .right-arrow {
  padding: 0.5rem;
  transition: background-color 1s ease;
  cursor: pointer;
}
.expenses .container-pay .container-box-pay .container-payment-methods .container-methods .left-arrow:hover, .expenses .container-pay .container-box-pay .container-payment-methods .container-methods .right-arrow:hover {
  background-color: #ededed;
  border-radius: 5px;
}
.expenses .container-pay .container-box-pay .container-payment-methods .container-methods .items-payment-methods {
  display: flex;
  padding: 1.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  overflow-x: scroll;
  flex-wrap: nowrap;
  gap: 1.5rem;
  max-width: 70vw;
  margin-top: 1rem;
  background-color: #ededed;
  border-radius: 10px;
  scroll-behavior: smooth;
  border: 1px solid #ddd;
}
.expenses .container-pay .container-box-pay .container-payment-methods .container-methods .items-payment-methods .card, .expenses .container-pay .container-box-pay .container-payment-methods .container-methods .items-payment-methods .card-selected {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
  max-width: 8rem;
  max-height: 13rem;
  border-radius: 10px;
}
.expenses .container-pay .container-box-pay .container-payment-methods .container-methods .items-payment-methods .card-selected {
  padding: 1rem;
  background-color: rgba(0, 126, 255, 0.4392156863);
  border-color: rgba(0, 126, 255, 0.4392156863);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
.expenses .container-pay .container-box-pay .container-payment-methods .container-methods .items-payment-methods .card-image {
  width: 6rem;
  height: 6rem;
  align-items: center;
}
.expenses .container-pay .container-box-pay .container-next-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 1rem;
  width: 100%;
  margin-top: 1.5rem;
}
.expenses .container-pay .container-box-pay .container-next-button .next-button {
  width: 100%;
}
.expenses .container-pay .container-box-pay p {
  margin: 0;
  text-align: center;
}
.expenses .container-trans-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 50%;
}
.expenses .container-trans-info .container-content-trans-info {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  max-width: 60vw;
  margin-bottom: 20rem;
  background-color: white;
}
.expenses .container-trans-info .container-content-trans-info .container-icon {
  display: flex;
  justify-content: center;
}
.expenses .container-trans-info .container-content-trans-info .container-body-trans-info {
  display: flex;
  flex-direction: column;
}
.expenses .container-trans-info .container-content-trans-info .container-body-trans-info .container-title {
  display: flex;
  justify-content: center;
}
.expenses .container-trans-info .container-content-trans-info .container-body-trans-info .container-desc {
  display: flex;
  justify-content: center;
}

.polls .container-polls-items .container-item .li-poll {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  margin: 25px 0px;
  margin-top: 6px;
  background: none;
  border-top: 1px dotted;
  padding: 0.5rem 0px 0px 1rem;
}
.polls .container-polls-items .container-item .li-poll .header-poll {
  display: flex;
  justify-content: space-between;
  padding-right: 0.5rem;
}
.polls .container-polls-items .container-item .li-poll .header-poll h1 {
  text-align: initial;
  margin: 0;
}
.polls .container-polls-items .container-item .li-poll .button-answer-poll {
  margin: 1rem 1rem 0 0;
}
.polls .container-polls-items .container-pagination {
  display: flex;
  justify-content: center;
}
.polls .container-body-poll {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  min-height: 100dvh;
}
.polls .container-body-poll .container-content-poll {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  width: 60%;
  max-width: 90vw;
  margin-bottom: 20rem;
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.5);
  font-size: 14px;
}
.polls .container-body-poll .container-content-poll .container-desc h2, .polls .container-body-poll .container-content-poll .container-desc p {
  margin: 0;
}
.polls .container-body-poll .container-content-poll .container-checkbox-group {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}
.polls .container-body-poll .container-content-poll .container-checkbox-group .container-checkbox-item {
  margin: 0;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.justify-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.plus-minus-container {
  border: 1px solid;
  border-radius: 4px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.plus-minus-container .hover-grow {
  cursor: pointer;
}
.plus-minus-container .hover-grow:hover {
  transform: scale(1.2);
}

@keyframes antSlideUp {
  0% {
    transform: translateY(100%);
    transformorigin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    transformorigin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideOut {
  0% {
    transform: translateY(0);
    transformorigin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    transformorigin: 0% 0%;
    opacity: 0;
  }
}
.ant-modal-slide-up-enter,
.ant-modal-slide-up-appear {
  transform: scale(0);
  transform-origin: 0% 0%;
  opacity: 0;
  animation-play-state: paused;
  animation-duration: 0.2s;
  animation-fill-mode: both;
}

.ant-modal-slide-up-leave {
  animation-play-state: paused;
  animation-duration: 0.2s;
  animation-fill-mode: both;
}

.ant-modal-slide-up-enter.ant-modal-slide-up-enter-active,
.ant-modal-slide-up-appear.ant-modal-slide-up-appear-active {
  animation-name: antSlideUp;
  animation-play-state: running;
}

.ant-modal-slide-up-leave.ant-modal-slide-up-leave-active {
  animation-name: antSlideOut;
  animation-play-state: running;
  pointer-events: none;
}

.modal {
  max-height: 75vh;
  overflow-y: auto;
  top: 10vh;
  display: flex;
}
.modal.mobile {
  width: 100vw !important;
  bottom: 0;
  left: 0;
  top: auto;
  max-height: 80vh;
  max-width: 100vw;
  margin: 0;
  position: fixed;
  border-radius: 15px 15px 0 0;
}
.modal.mobile .ant-modal-header {
  width: 100%;
}
.modal.mobile .ant-modal-content {
  width: 100%;
}
.modal.mobile .ant-modal-footer {
  width: 100%;
}
.modal.mobile .dish-variants-modal-footer button {
  height: 3rem;
}

.dish-variants-modal-footer {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.dish-variants-modal-footer button[name=add-variation-button] {
  width: 60%;
  min-width: fit-content;
  border-color: black;
  color: black;
}
.dish-variants-modal-footer button[name=close-variation-modal] {
  width: 35%;
}

.order-is-old-modal .order-is-old-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.order-is-old-modal .order-is-old-modal-container .order-is-old-modal-buttons-container {
  display: flex;
  gap: 1rem;
  width: fit-content;
  margin-top: 2rem;
}

.quantity-in-order {
  text-align: center;
  border: black 1px solid;
  border-radius: 3px;
  width: 24px;
  height: 24px;
  color: black;
  -webkit-transition: 0.15s ease-in-out;
  -moz-transition: 0.15s ease-in-out;
  -ms-transition: 0.15s ease-in-out;
  -o-transition: 0.15s ease-in-out;
  transition: 0.15s ease-in-out;
}
.quantity-in-order:hover {
  color: white;
  background-color: black;
}
.quantity-in-order.bg-black {
  color: white;
  background-color: black;
}
.quantity-in-order.bg-black:hover {
  color: black;
  background-color: white;
}

.category-title {
  padding-top: 5px;
  padding-bottom: 3px;
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #f0f2f5;
  width: calc(100% + 40px);
  margin-left: -20px;
  box-shadow: 0 4px 6px -6px black;
}

.food-order-layout {
  width: 100%;
  height: calc(100vh - 4rem);
  height: calc(100dvh - 4rem);
}

.cart-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 5px;
}

.alert-container {
  position: fixed;
  top: 10%;
  left: 10%;
  width: 80%;
  z-index: 10000;
}

.alert {
  margin-bottom: 1rem !important;
}

.my-orders-button {
  margin-right: 2vw;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  text-align: start;
  align-items: center;
  width: 100%;
}

.order-changed-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-right: 10px;
}

.modal-price-difference {
  font-weight: bold;
  float: left;
}

.two-buttons-container {
  display: flex;
  gap: 1rem;
}

.title-jump-animation {
  animation: mymove 1s 1;
}

@keyframes mymove {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}
.highlight {
  color: red !important;
}

.ant-modal-content {
  width: 100%;
}

.confirm-modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.ant-modal-header {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 10;
  background-color: rgb(240, 242, 245) !important;
}

.ant-modal-close {
  position: sticky !important;
  position: -webkit-sticky !important;
  top: 0;
  z-index: 100 !important;
}

.ant-modal-footer {
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
  z-index: 10;
  background-color: rgb(240, 242, 245) !important;
}

.menu-header {
  background-color: #fff;
  border-bottom: 1px solid #e5e7eb;
  padding: 12px;
}
.menu-header .container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.menu-header .scroll-container {
  position: relative;
}
.menu-header .scroll-container .react-horizontal-scrolling-menu--scroll-container {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.menu-header .scroll-container .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}
.menu-header .scroll-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: white;
  border: 1px solid #e5e7eb;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  transition: all 0.2s ease;
  padding: 0;
}
.menu-header .scroll-arrow:hover {
  background-color: #f9fafb;
  border-color: #2563eb;
}
.menu-header .scroll-arrow:hover .arrow-icon {
  border-color: #2563eb;
}
.menu-header .scroll-arrow:disabled {
  opacity: 0;
  cursor: default;
}
.menu-header .scroll-arrow.left-arrow {
  left: 0;
}
.menu-header .scroll-arrow.left-arrow .arrow-icon {
  margin-left: 4px;
  transform: rotate(45deg);
}
.menu-header .scroll-arrow.right-arrow {
  right: 0;
}
.menu-header .scroll-arrow.right-arrow .arrow-icon {
  margin-right: 4px;
  transform: rotate(-135deg);
}
.menu-header .scroll-arrow .arrow-icon {
  width: 8px;
  height: 8px;
  border-left: 2px solid #666;
  border-bottom: 2px solid #666;
  transition: border-color 0.2s ease;
}
.menu-header .category-button {
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  background-color: #f9fafb;
  color: #374151;
  cursor: pointer;
  display: flex;
  margin-right: 8px;
  align-items: center;
  gap: 4px;
  transition: all 0.2s ease;
  font-size: 14px;
  white-space: nowrap;
  scroll-snap-align: start;
}
.menu-header .category-button:hover {
  background-color: #f0f7ff;
  color: #2563eb;
  border-color: #2563eb;
}
.menu-header .category-button.selected {
  background-color: #f0f7ff;
  color: #2563eb;
  border-color: #2563eb;
}
.menu-header .tag-section {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}
.menu-header .tag-button {
  padding: 6px 12px;
  border-radius: 20px;
  margin-right: 8px;
  margin-bottom: 8px;
  border: 1px solid #e5e7eb;
  background-color: #f9fafb;
  color: #4b5563;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 13px;
}
.menu-header .tag-button:hover {
  background-color: #f0fdf4;
  color: #059669;
}
.menu-header .tag-button.selected {
  background-color: #ecfdf5;
  color: #059669;
  border-color: #059669;
  border-width: 2px;
}
.menu-header .tag-button.selected:hover {
  background-color: #ecfdf5;
  color: #059669;
}

.square {
  color: rgb(214, 214, 214);
  background: rgb(99, 102, 106);
  text-decoration: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  margin: 1px;
  border: solid 2px rgb(141, 143, 150);
  width: calc(33.3333333vw - 2px);
  height: calc(33.3333333vw - 2px);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
}
.square .title {
  font-size: 1.25rem;
  grid-column-start: 1;
  grid-column-end: span 2;
  grid-row-start: 4;
  align-self: center;
  justify-self: center;
  text-align: center;
  margin: 0;
  padding: 0.5rem;
}
.square .title a {
  text-decoration: none;
}
.square .widget {
  font-size: 2rem;
  grid-row-start: 1;
  grid-row-end: span 2;
  grid-column-start: 2;
  margin: 0.5rem 1rem 0 0;
  justify-self: end;
  align-self: start;
}
.square .widget-icons {
  display: flex;
  gap: 1.5rem;
}
@media (max-width: 480px) {
  .square {
    margin: 1px;
    width: calc(33.3333333vw - 2px);
    height: calc(33.3333333vw - 2px);
  }
}
@media (min-width: 480px) and (max-width: 680px) {
  .square {
    margin: 1px;
    width: calc(33.3333333vw - 2px);
    height: calc(33.3333333vw - 2px);
  }
}
@media (min-width: 680px) and (max-width: 900px) {
  .square {
    margin: 1px;
    width: calc(25vw - 2px);
    height: calc(25vw - 2px);
  }
}
@media (min-width: 900px) and (max-width: 1250px) {
  .square {
    margin: 2px;
    width: calc(20vw - 4px);
    height: calc(20vw - 4px);
  }
}
@media (min-width: 1250px) and (max-width: 1500px) {
  .square {
    margin: 2px;
    width: calc(16.666666666vw - 4px);
    height: calc(16.666666666vw - 4px);
  }
}
@media (min-width: 1500px) and (max-width: 2000px) {
  .square {
    margin: 5px;
    width: calc(14.285vw - 10px);
    height: calc(14.285vw - 10px);
  }
}
@media (min-width: 2000px) {
  .square {
    margin: 5px;
    width: calc(12.5vw - 10px);
    height: calc(12.5vw - 10px);
  }
}

.page-ul {
  list-style: none;
  padding: 0;
  margin-bottom: 1rem;
}
.page-ul .filterBar {
  margin: 0 25px;
  text-align: right;
}
.page-ul .li-request {
  padding: 5px 0px 5px 15px;
  margin: 25px;
  margin-top: 6px;
  background: rgba(255, 255, 255, 0.63);
  overflow: hidden;
}
@media (max-width: 480px) {
  .page-ul .li-request {
    margin: 20px 0px;
  }
}
@media (min-width: 480px) and (max-width: 680px) {
  .page-ul .li-request {
    margin: 20px 0px;
  }
}
@media (min-width: 680px) and (max-width: 900px) {
  .page-ul .li-request {
    margin: 20px 0px;
  }
}
.page-ul .title {
  font-size: 2rem;
  margin: 7px 0px;
  font-weight: normal;
}
.page-ul .date {
  float: right;
  margin: 5px 15px 5px 0px;
  color: grey;
}
.page-ul .state.ribbon {
  display: block;
  padding: 5px 5ex;
  width: 15rem;
  text-align: center;
  background: black;
  color: white;
  opacity: 0.3;
  /*float:right;*/
  position: absolute;
  top: 2rem;
  right: -4rem;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.page-ul .state {
  display: block;
  padding: 5px 10px;
  width: 10rem;
  text-align: right;
  background: grey;
  color: white;
  opacity: 1;
  /*float:right;*/
  position: absolute;
  top: 15px;
  right: 0px;
}
.page-ul .link {
  font-weight: bold;
  cursor: pointer;
}
.page-ul .body ul > .li-request {
  background: none;
  color: unset;
}

.page-ul-enter {
  opacity: 0;
  max-height: 0;
  transition: max-height 0.5s ease-out, opacity 0.3s ease-out;
}

.page-ul-enter.page-ul-enter-active {
  max-height: 500px;
  opacity: 1;
  transition: max-height 0.5s ease-in, opacity 0.3s ease-in;
}

.board-enter {
  opacity: 0;
  transition: opacity 0.3s ease-in, transform 0.3s;
  transform: translateY(30px);
}

.board-enter.board-enter-active {
  transform: translateY(0px);
  opacity: 1;
}

.board {
  border-top: 2px solid black;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
}
.board .board-item {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
}

.page-frame {
  background: rgb(233, 233, 233);
  margin: 0;
  position: absolute;
  top: 0;
  padding: 0px;
  width: 100%;
  overflow: hidden;
  height: 100vh;
  height: 100dvh;
  z-index: 100;
}
.page-frame .board {
  border: 0;
  height: fit-content;
}
.page-frame .page-container {
  height: 100vh;
  height: 100dvh;
  width: 100%;
  overflow: scroll;
}
.page-frame img {
  max-width: 90vw;
  max-height: auto;
}
.page-frame.deliveries li.delivered {
  opacity: 0.9;
}

.paginaton-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  flex-wrap: wrap;
}

.pagination-button {
  border: 2px solid transparent;
  border-radius: 20px;
  transition: border-color 0.5s;
  background: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.pagination-button:hover {
  border: 2px solid black;
}

.pagination-button:disabled {
  cursor: not-allowed;
}

.auth-error-component {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.76);
}
.auth-error-component .centered-container {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  width: 25rem;
  height: 15rem;
  padding: 1rem;
  background-color: rgb(255, 255, 255);
  filter: drop-shadow(6px 6px 3px rgba(46, 46, 46, 0.795));
}
.auth-error-component .centered-container .message-title {
  font-size: 2rem;
}
.auth-error-component .centered-container .button-holder {
  display: flex;
  flex-flow: row;
  width: 100%;
  height: 4rem;
}
.auth-error-component .centered-container .button-holder * {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  padding: 1px;
}
.auth-error-component .centered-container .button-holder .ignore {
  background-color: rgb(209, 209, 209);
}
.auth-error-component .centered-container .button-holder .logout {
  background-color: rgb(209, 209, 209);
}

div.md-renderer > div > span ul > li, div.md-renderer > div > span h1, div.md-renderer > div > span h2, div.md-renderer > div > span h3, div.md-renderer > div > span h4, div.md-renderer > div > span h5, div.md-renderer > div > span h6 {
  color: unset;
}
div.md-renderer > div > span ul {
  list-style-position: inside;
  list-style-type: disc;
}
div.md-renderer img {
  max-width: 100%;
}

.enabled-scrollable {
  -webkit-overflow-scrolling: touch;
  height: 100%;
  width: 100%;
  overflow: scroll;
}

.disabled-scrollable {
  overflow: hidden;
}

#zoomable-images-renderer {
  height: 70vh;
  overflow: scroll;
}
#zoomable-images-renderer img {
  transform: translate(-50%, -50%);
}

.zoomable-image {
  text-align: right;
  position: relative;
}
.zoomable-image .zoomable-visible-flag {
  position: absolute;
  bottom: 0;
  right: 0;
  padding-right: 3rem;
  padding-top: 3rem;
  padding-left: 9rem;
  background-image: linear-gradient(to top left, rgba(0, 0, 0, 0.3333333333), transparent, transparent);
}

.container-box {
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.5);
  background-color: white;
}

.message-alert {
  max-width: 90vw;
}

html, body, #root {
  overflow-y: overlay;
  height: 100%;
  margin: 0;
  font-family: "Helvetica";
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}
@media (max-width: 480px) {
  html, body, #root {
    font-size: 12px;
  }
}
@media (min-width: 480px) and (max-width: 680px) {
  html, body, #root {
    font-size: 14px;
  }
}
@media (min-width: 680px) and (max-width: 900px) {
  html, body, #root {
    font-size: 14px;
  }
}
@media (min-width: 900px) and (max-width: 1250px) {
  html, body, #root {
    font-size: 16px;
  }
}
@media (min-width: 1250px) and (max-width: 1500px) {
  html, body, #root {
    font-size: 16px;
  }
}
@media (min-width: 1500px) and (max-width: 2000px) {
  html, body, #root {
    font-size: 16px;
  }
}
@media (min-width: 2000px) {
  html, body, #root {
    font-size: 18px;
  }
}
html .ant-btn, body .ant-btn, #root .ant-btn {
  line-height: normal;
}

.selectable {
  -moz-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
  -o-user-select: text;
}

img, a {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

#root .page-frame.error-page {
  background-position: center;
  background-size: cover;
  background-image: url("img/background.png");
  display: flex;
  flex-direction: column;
}
#root .page-frame.error-page .error-header {
  width: 100%;
  height: 70px;
  background: rgba(0, 0, 0, 0.54);
}
#root .page-frame.error-page .error-body {
  margin: 6rem 0;
  display: flex;
  flex-direction: column;
  text-align: center;
}
#root .page-frame.error-page .error-body h1 {
  color: rgb(0, 209, 184);
  font-size: 5rem;
  font-weight: 800;
}
#root .page-frame.error-page .error-body span {
  width: 100%;
  max-width: 37rem;
  padding: 2rem;
  font-size: 1.6rem;
  margin: 0 auto;
  color: white;
  background-color: black;
  opacity: 0.5;
  line-height: 2.5rem;
}

div > .ant-modal {
  padding-bottom: 0;
}

.ant-message {
  position: fixed;
  margin-top: env(safe-area-inset-top);
  top: 0;
}

.guest ::-webkit-scrollbar {
  width: 0px;
  display: none;
}

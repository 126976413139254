#theme-container {
  display: flex;
  flex-direction: column;
}

#theme-container.textures-color .settings .mail .own-email {
  color: rgb(6, 61, 22);
}
#theme-container.textures-color .dashboard {
  background: rgb(54, 68, 79);
  flex-grow: 1;
  flex-shrink: 1;
}
#theme-container.textures-color .dashboard .header {
  background-image: url(img/background/bg_aereal_shore.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
}
#theme-container.textures-color .dashboard .header a {
  color: white;
}
#theme-container.textures-color .dashboard .hidden-menu a {
  color: white;
}
#theme-container.textures-color .dashboard .ant-slider .ant-slider-track {
  background: rgb(69, 64, 64);
}
#theme-container.textures-color .dashboard .ant-slider .ant-slider-rail {
  background-color: white;
}
#theme-container.textures-color .dashboard .ant-slider .ant-slider-handle {
  background: black;
  border-color: rgba(233, 233, 233, 0.61);
  box-shadow: none;
}
#theme-container.textures-color .dashboard .ant-slider:focus .ant-slider-track,
#theme-container.textures-color .dashboard .ant-slider:hover .ant-slider-track {
  background: black;
}
#theme-container.textures-color .dashboard .ant-slider:focus .ant-slider-rail,
#theme-container.textures-color .dashboard .ant-slider:hover .ant-slider-rail {
  background-color: white;
}
#theme-container.textures-color .dashboard .ant-slider:focus .ant-slider-handle,
#theme-container.textures-color .dashboard .ant-slider:hover .ant-slider-handle {
  background: black;
  border-color: white;
  box-shadow: none;
}
#theme-container.textures-color .vehicle {
  -webkit-box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}
#theme-container.textures-color .vehicle.ready .vehicle-info {
  background: rgb(99, 102, 106);
  color: rgb(232, 232, 232);
}
#theme-container.textures-color .vehicle.ready .logo-bar {
  background: rgba(255, 255, 255, 0.85);
}
#theme-container.textures-color .vehicle.requested .vehicle-info {
  background: rgb(153, 153, 153);
  color: rgb(232, 232, 232);
}
#theme-container.textures-color .vehicle.requested .logo-bar {
  background: rgba(255, 255, 255, 0.85);
}
#theme-container.textures-color .vehicle.carwash_requested .vehicle-info {
  background: rgb(153, 153, 153);
  color: rgb(232, 232, 232);
}
#theme-container.textures-color .vehicle.carwash_requested .logo-bar {
  background: rgba(255, 255, 255, 0.85);
}
#theme-container.textures-color .vehicle.parked .vehicle-info {
  background: rgb(203, 203, 203);
  color: rgb(121, 121, 121);
}
#theme-container.textures-color .vehicle.parked .logo-bar {
  background: rgba(255, 255, 255, 0.85);
}
#theme-container.textures-color .vehicle.washing .vehicle-info {
  background: rgba(255, 255, 255, 0.85);
  color: #577c9a;
}
#theme-container.textures-color .vehicle.washing .logo-bar {
  background: rgba(255, 255, 255, 0.85);
}
#theme-container.textures-color .vehicle.topark .vehicle-info {
  background: rgba(255, 255, 255, 0.85);
  color: rgb(187, 120, 96);
}
#theme-container.textures-color .vehicle.topark .logo-bar {
  background: rgba(255, 255, 255, 0.85);
}
#theme-container.textures-color .vehicle.out .vehicle-info {
  background: rgba(255, 255, 255, 0.85);
  color: rgb(132, 132, 132);
}
#theme-container.textures-color .vehicle.out .logo-bar {
  background: rgba(255, 255, 255, 0.85);
}
#theme-container.textures-color .reservables .ant-fullcalendar-fullscreen .ant-fullcalendar-header {
  background: rgba(0, 0, 0, 0.5);
  border: none;
}
#theme-container.textures-color .reservables .ant-fullcalendar-fullscreen .ant-fullcalendar-calendar-body .ant-fullcalendar-column-header {
  border: none;
}
#theme-container.textures-color .reservables .ant-fullcalendar-fullscreen .ant-select-selection--single {
  background: none;
}
#theme-container.textures-color .reservables .ant-fullcalendar-fullscreen thead {
  background: rgba(0, 0, 0, 0.7);
  border: none;
}
#theme-container.textures-color .square.weather.chance_of_rain {
  background-image: url("../../weather/wsymbol/wsymbol_0009_light_rain_showers.png"), url("img/buttons/button_weather.jpg");
}
#theme-container.textures-color .square.weather.chance_of_snow {
  background-image: url("../../weather/wsymbol/wsymbol_0011_light_snow_showers.png"), url("img/buttons/button_weather.jpg");
}
#theme-container.textures-color .square.weather.chance_of_storm {
  background-image: url("../../weather/wsymbol/wsymbol_0010_heavy_rain_showers.png"), url("img/buttons/button_weather.jpg");
}
#theme-container.textures-color .square.weather.chance_of_tstorm {
  background-image: url("../../weather/wsymbol/wsymbol_0016_thundery_showers.png"), url("img/buttons/button_weather.jpg");
}
#theme-container.textures-color .square.weather.chanceflurries {
  background-image: url("../../weather/wsymbol/wsymbol_0011_light_snow_showers.png"), url("img/buttons/button_weather.jpg");
}
#theme-container.textures-color .square.weather.chancerain {
  background-image: url("../../weather/wsymbol/wsymbol_0009_light_rain_showers.png"), url("img/buttons/button_weather.jpg");
}
#theme-container.textures-color .square.weather.chancesleet {
  background-image: url("../../weather/wsymbol/wsymbol_0013_sleet_showers.png"), url("img/buttons/button_weather.jpg");
}
#theme-container.textures-color .square.weather.chancesnow {
  background-image: url("../../weather/wsymbol/wsymbol_0011_light_snow_showers.png"), url("img/buttons/button_weather.jpg");
}
#theme-container.textures-color .square.weather.chancetstorms {
  background-image: url("../../weather/wsymbol/wsymbol_0016_thundery_showers.png"), url("img/buttons/button_weather.jpg");
}
#theme-container.textures-color .square.weather.clear {
  background-image: url("../../weather/wsymbol/wsymbol_0001_sunny.png"), url("img/buttons/button_weather.jpg");
}
#theme-container.textures-color .square.weather.cloudy {
  background-image: url("../../weather/wsymbol/wsymbol_0004_black_low_cloud.png"), url("img/buttons/button_weather.jpg");
}
#theme-container.textures-color .square.weather.dust {
  background-image: url("../../weather/wsymbol/wsymbol_0005_hazy_sun.png"), url("img/buttons/button_weather.jpg");
}
#theme-container.textures-color .square.weather.flurries {
  background-image: url("../../weather/wsymbol/wsymbol_0019_cloudy_with_light_snow.png"), url("img/buttons/button_weather.jpg");
}
#theme-container.textures-color .square.weather.fog {
  background-image: url("../../weather/wsymbol/wsymbol_0007_fog.png"), url("img/buttons/button_weather.jpg");
}
#theme-container.textures-color .square.weather.haze {
  background-image: url("../../weather/wsymbol/wsymbol_0005_hazy_sun.png"), url("img/buttons/button_weather.jpg");
}
#theme-container.textures-color .square.weather.hazy {
  background-image: url("../../weather/wsymbol/wsymbol_0005_hazy_sun.png"), url("img/buttons/button_weather.jpg");
}
#theme-container.textures-color .square.weather.icy {
  background-image: url("../../weather/wsymbol/wsymbol_0020_cloudy_with_heavy_snow.png"), url("img/buttons/button_weather.jpg");
}
#theme-container.textures-color .square.weather.mist {
  background-image: url("../../weather/wsymbol/wsymbol_0017_cloudy_with_light_rain.png"), url("img/buttons/button_weather.jpg");
}
#theme-container.textures-color .square.weather.mostly_cloudy {
  background-image: url("../../weather/wsymbol/wsymbol_0003_white_cloud.png"), url("img/buttons/button_weather.jpg");
}
#theme-container.textures-color .square.weather.mostly_sunny {
  background-image: url("../../weather/wsymbol/wsymbol_0002_sunny_intervals.png"), url("img/buttons/button_weather.jpg");
}
#theme-container.textures-color .square.weather.mostlycloudy {
  background-image: url("../../weather/wsymbol/wsymbol_0003_white_cloud.png"), url("img/buttons/button_weather.jpg");
}
#theme-container.textures-color .square.weather.mostlysunny {
  background-image: url("../../weather/wsymbol/wsymbol_0002_sunny_intervals.png"), url("img/buttons/button_weather.jpg");
}
#theme-container.textures-color .square.weather.partly_cloudy {
  background-image: url("../../weather/wsymbol/wsymbol_0002_sunny_intervals.png"), url("img/buttons/button_weather.jpg");
}
#theme-container.textures-color .square.weather.partlycloudy {
  background-image: url("../../weather/wsymbol/wsymbol_0002_sunny_intervals.png"), url("img/buttons/button_weather.jpg");
}
#theme-container.textures-color .square.weather.partlysunny {
  background-image: url("../../weather/wsymbol/wsymbol_0002_sunny_intervals.png"), url("img/buttons/button_weather.jpg");
}
#theme-container.textures-color .square.weather.rain {
  background-image: url("../../weather/wsymbol/wsymbol_0018_cloudy_with_heavy_rain.png"), url("img/buttons/button_weather.jpg");
}
#theme-container.textures-color .square.weather.sleet {
  background-image: url("../../weather/wsymbol/wsymbol_0021_cloudy_with_sleet.png"), url("img/buttons/button_weather.jpg");
}
#theme-container.textures-color .square.weather.smoke {
  background-image: url("../../weather/wsymbol/wsymbol_0007_fog.png"), url("img/buttons/button_weather.jpg");
}
#theme-container.textures-color .square.weather.snow {
  background-image: url("../../weather/wsymbol/wsymbol_0020_cloudy_with_heavy_snow.png"), url("img/buttons/button_weather.jpg");
}
#theme-container.textures-color .square.weather.storm {
  background-image: url("../../weather/wsymbol/wsymbol_0018_cloudy_with_heavy_rain.png"), url("img/buttons/button_weather.jpg");
}
#theme-container.textures-color .square.weather.sunny {
  background-image: url("../../weather/wsymbol/wsymbol_0001_sunny.png"), url("img/buttons/button_weather.jpg");
}
#theme-container.textures-color .square.weather.thunderstorm {
  background-image: url("../../weather/wsymbol/wsymbol_0024_thunderstorms.png"), url("img/buttons/button_weather.jpg");
}
#theme-container.textures-color .square.weather.tstorms {
  background-image: url("../../weather/wsymbol/wsymbol_0024_thunderstorms.png"), url("img/buttons/button_weather.jpg");
}
#theme-container.textures-color .square.weather.nt_clear {
  background-image: url("../../weather/wsymbol/wsymbol_0008_clear_sky_night.png"), url("img/buttons/button_weather.jpg");
}
#theme-container.textures-color .square.weather.no-weather {
  background-image: url("img/buttons/button_weather.jpg");
  background-size: 100%;
}
#theme-container.textures-color .square.weather {
  background-size: 8rem, 100%;
  background-repeat: no-repeat;
  background-position: center;
}
#theme-container.textures-color .weather .board .square {
  background-size: 6rem, 100%;
  background-repeat: no-repeat;
  background-position: center;
}
#theme-container.textures-color .weather .board .square.chance_of_rain {
  background-image: url("../../weather/wsymbol/wsymbol_0009_light_rain_showers.png"), url("img/buttons/button_generic-blue.jpg");
}
#theme-container.textures-color .weather .board .square.chance_of_snow {
  background-image: url("../../weather/wsymbol/wsymbol_0011_light_snow_showers.png"), url("img/buttons/button_generic-blue.jpg");
}
#theme-container.textures-color .weather .board .square.chance_of_storm {
  background-image: url("../../weather/wsymbol/wsymbol_0010_heavy_rain_showers.png"), url("img/buttons/button_generic-blue.jpg");
}
#theme-container.textures-color .weather .board .square.chance_of_tstorm {
  background-image: url("../../weather/wsymbol/wsymbol_0016_thundery_showers.png"), url("img/buttons/button_generic-blue.jpg");
}
#theme-container.textures-color .weather .board .square.chanceflurries {
  background-image: url("../../weather/wsymbol/wsymbol_0011_light_snow_showers.png"), url("img/buttons/button_generic-blue.jpg");
}
#theme-container.textures-color .weather .board .square.chancerain {
  background-image: url("../../weather/wsymbol/wsymbol_0009_light_rain_showers.png"), url("img/buttons/button_generic-blue.jpg");
}
#theme-container.textures-color .weather .board .square.chancesleet {
  background-image: url("../../weather/wsymbol/wsymbol_0013_sleet_showers.png"), url("img/buttons/button_generic-blue.jpg");
}
#theme-container.textures-color .weather .board .square.chancesnow {
  background-image: url("../../weather/wsymbol/wsymbol_0011_light_snow_showers.png"), url("img/buttons/button_generic-blue.jpg");
}
#theme-container.textures-color .weather .board .square.chancetstorms {
  background-image: url("../../weather/wsymbol/wsymbol_0016_thundery_showers.png"), url("img/buttons/button_generic-blue.jpg");
}
#theme-container.textures-color .weather .board .square.clear {
  background-image: url("../../weather/wsymbol/wsymbol_0001_sunny.png"), url("img/buttons/button_generic-blue.jpg");
}
#theme-container.textures-color .weather .board .square.cloudy {
  background-image: url("../../weather/wsymbol/wsymbol_0004_black_low_cloud.png"), url("img/buttons/button_generic-blue.jpg");
}
#theme-container.textures-color .weather .board .square.dust {
  background-image: url("../../weather/wsymbol/wsymbol_0005_hazy_sun.png"), url("img/buttons/button_generic-blue.jpg");
}
#theme-container.textures-color .weather .board .square.flurries {
  background-image: url("../../weather/wsymbol/wsymbol_0019_cloudy_with_light_snow.png"), url("img/buttons/button_generic-blue.jpg");
}
#theme-container.textures-color .weather .board .square.fog {
  background-image: url("../../weather/wsymbol/wsymbol_0007_fog.png"), url("img/buttons/button_generic-blue.jpg");
}
#theme-container.textures-color .weather .board .square.haze {
  background-image: url("../../weather/wsymbol/wsymbol_0005_hazy_sun.png"), url("img/buttons/button_generic-blue.jpg");
}
#theme-container.textures-color .weather .board .square.hazy {
  background-image: url("../../weather/wsymbol/wsymbol_0005_hazy_sun.png"), url("img/buttons/button_generic-blue.jpg");
}
#theme-container.textures-color .weather .board .square.icy {
  background-image: url("../../weather/wsymbol/wsymbol_0020_cloudy_with_heavy_snow.png"), url("img/buttons/button_generic-blue.jpg");
}
#theme-container.textures-color .weather .board .square.mist {
  background-image: url("../../weather/wsymbol/wsymbol_0017_cloudy_with_light_rain.png"), url("img/buttons/button_generic-blue.jpg");
}
#theme-container.textures-color .weather .board .square.mostly_cloudy {
  background-image: url("../../weather/wsymbol/wsymbol_0003_white_cloud.png"), url("img/buttons/button_generic-blue.jpg");
}
#theme-container.textures-color .weather .board .square.mostly_sunny {
  background-image: url("../../weather/wsymbol/wsymbol_0002_sunny_intervals.png"), url("img/buttons/button_generic-blue.jpg");
}
#theme-container.textures-color .weather .board .square.mostlycloudy {
  background-image: url("../../weather/wsymbol/wsymbol_0003_white_cloud.png"), url("img/buttons/button_generic-blue.jpg");
}
#theme-container.textures-color .weather .board .square.mostlysunny {
  background-image: url("../../weather/wsymbol/wsymbol_0002_sunny_intervals.png"), url("img/buttons/button_generic-blue.jpg");
}
#theme-container.textures-color .weather .board .square.partly_cloudy {
  background-image: url("../../weather/wsymbol/wsymbol_0002_sunny_intervals.png"), url("img/buttons/button_generic-blue.jpg");
}
#theme-container.textures-color .weather .board .square.partlycloudy {
  background-image: url("../../weather/wsymbol/wsymbol_0002_sunny_intervals.png"), url("img/buttons/button_generic-blue.jpg");
}
#theme-container.textures-color .weather .board .square.partlysunny {
  background-image: url("../../weather/wsymbol/wsymbol_0002_sunny_intervals.png"), url("img/buttons/button_generic-blue.jpg");
}
#theme-container.textures-color .weather .board .square.rain {
  background-image: url("../../weather/wsymbol/wsymbol_0018_cloudy_with_heavy_rain.png"), url("img/buttons/button_generic-blue.jpg");
}
#theme-container.textures-color .weather .board .square.sleet {
  background-image: url("../../weather/wsymbol/wsymbol_0021_cloudy_with_sleet.png"), url("img/buttons/button_generic-blue.jpg");
}
#theme-container.textures-color .weather .board .square.smoke {
  background-image: url("../../weather/wsymbol/wsymbol_0007_fog.png"), url("img/buttons/button_generic-blue.jpg");
}
#theme-container.textures-color .weather .board .square.snow {
  background-image: url("../../weather/wsymbol/wsymbol_0020_cloudy_with_heavy_snow.png"), url("img/buttons/button_generic-blue.jpg");
}
#theme-container.textures-color .weather .board .square.storm {
  background-image: url("../../weather/wsymbol/wsymbol_0018_cloudy_with_heavy_rain.png"), url("img/buttons/button_generic-blue.jpg");
}
#theme-container.textures-color .weather .board .square.sunny {
  background-image: url("../../weather/wsymbol/wsymbol_0001_sunny.png"), url("img/buttons/button_generic-blue.jpg");
}
#theme-container.textures-color .weather .board .square.thunderstorm {
  background-image: url("../../weather/wsymbol/wsymbol_0024_thunderstorms.png"), url("img/buttons/button_generic-blue.jpg");
}
#theme-container.textures-color .weather .board .square.tstorms {
  background-image: url("../../weather/wsymbol/wsymbol_0024_thunderstorms.png"), url("img/buttons/button_generic-blue.jpg");
}
#theme-container.textures-color .weather .board .square.nt_clear {
  background-image: url("../../weather/wsymbol/wsymbol_0008_clear_sky_night.png"), url("img/buttons/button_generic-blue.jpg");
}
#theme-container.textures-color .vehicle {
  -webkit-box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}
#theme-container.textures-color .vehicle.ready .vehicle-info {
  background: rgb(99, 102, 106);
  color: rgb(232, 232, 232);
}
#theme-container.textures-color .vehicle.ready .logo-bar {
  background: rgba(255, 255, 255, 0.85);
}
#theme-container.textures-color .vehicle.requested .vehicle-info {
  background: rgb(153, 153, 153);
  color: rgb(232, 232, 232);
}
#theme-container.textures-color .vehicle.requested .logo-bar {
  background: rgba(255, 255, 255, 0.85);
}
#theme-container.textures-color .vehicle.carwash_requested .vehicle-info {
  background: rgb(153, 153, 153);
  color: rgb(232, 232, 232);
}
#theme-container.textures-color .vehicle.carwash_requested .logo-bar {
  background: rgba(255, 255, 255, 0.85);
}
#theme-container.textures-color .vehicle.parked .vehicle-info {
  background: rgb(203, 203, 203);
  color: rgb(121, 121, 121);
}
#theme-container.textures-color .vehicle.parked .logo-bar {
  background: rgba(255, 255, 255, 0.85);
}
#theme-container.textures-color .vehicle.washing .vehicle-info {
  background: rgba(255, 255, 255, 0.85);
  color: #577c9a;
}
#theme-container.textures-color .vehicle.washing .logo-bar {
  background: rgba(255, 255, 255, 0.85);
}
#theme-container.textures-color .vehicle.topark .vehicle-info {
  background: rgba(255, 255, 255, 0.85);
  color: rgb(187, 120, 96);
}
#theme-container.textures-color .vehicle.topark .logo-bar {
  background: rgba(255, 255, 255, 0.85);
}
#theme-container.textures-color .vehicle.out .vehicle-info {
  background: rgba(255, 255, 255, 0.85);
  color: rgb(132, 132, 132);
}
#theme-container.textures-color .vehicle.out .logo-bar {
  background: rgba(255, 255, 255, 0.85);
}
#theme-container.textures-color .automation.box .widgets-box h2 {
  background: rgba(255, 255, 255, 0.4);
  color: black;
}
#theme-container.textures-color .automation.box .widgets-box h3 {
  font-weight: normal;
  border-bottom: 1px solid white;
}
#theme-container.textures-color .automation.box .widgets-box .zones {
  background: rgba(0, 0, 0, 0.54);
}
#theme-container.textures-color .automation.box .widgets-box .zones .ant-btn {
  background: none;
  border: none;
}
#theme-container.textures-color .automation.box .widgets-box .zones .ant-btn:hover {
  color: white;
}
#theme-container.textures-color .automation.box .widgets-box .zones .ant-btn:focus {
  color: white;
  box-shadow: none;
}
@media (max-width: 480px) {
  #theme-container.textures-color .automation.box h3 {
    border: 1px solid white;
  }
}
@media (min-width: 480px) and (max-width: 680px) {
  #theme-container.textures-color .automation.box h3 {
    border: 1px solid white;
  }
}
@media (min-width: 680px) and (max-width: 900px) {
  #theme-container.textures-color .automation.box h3 {
    border: 1px solid white;
  }
}
#theme-container.textures-color .square {
  color: white;
  background: rgb(131, 168, 188);
  background-image: url("img/buttons/button_generic-blue.jpg");
  background-size: cover;
  border: none;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.85), inset 0 -8ex 3ex rgba(0, 0, 0, 0.5);
}
@media (max-width: 480px) {
  #theme-container.textures-color .square {
    margin: 3px;
    width: calc(33.3333333vw - 6px);
    height: calc(33.3333333vw - 6px);
  }
}
@media (min-width: 480px) and (max-width: 680px) {
  #theme-container.textures-color .square {
    margin: 3px;
    width: calc(33.3333333vw - 6px);
    height: calc(33.3333333vw - 6px);
  }
}
@media (min-width: 680px) and (max-width: 900px) {
  #theme-container.textures-color .square {
    margin: 3px;
    width: calc(25vw - 6px);
    height: calc(25vw - 6px);
  }
}
@media (min-width: 900px) and (max-width: 1250px) {
  #theme-container.textures-color .square {
    margin: 5px;
    width: calc(20vw - 10px);
    height: calc(20vw - 10px);
  }
}
@media (min-width: 1250px) and (max-width: 1500px) {
  #theme-container.textures-color .square {
    margin: 8px;
    width: calc(16.666666666vw - 16px);
    height: calc(16.666666666vw - 16px);
  }
}
@media (min-width: 1500px) and (max-width: 2000px) {
  #theme-container.textures-color .square {
    margin: 12px;
    width: calc(14.285vw - 24px);
    height: calc(14.285vw - 24px);
  }
}
@media (min-width: 2000px) {
  #theme-container.textures-color .square {
    margin: 12px;
    width: calc(12.5vw - 24px);
    height: calc(12.5vw - 24px);
  }
}
#theme-container.textures-color .square.amenities {
  background-image: url("img/buttons/button_amenities.jpg");
}
#theme-container.textures-color .square.body_treatments {
  background-image: url("img/buttons/button_body_treatments.jpg");
}
#theme-container.textures-color .square.car_wash {
  background-image: url("img/buttons/button_car_wash.jpg");
}
#theme-container.textures-color .square.concierge {
  background-image: url("img/buttons/button_concierge.jpg");
}
#theme-container.textures-color .square.deliveries {
  background-image: url("img/buttons/button_deliveries.jpg");
}
#theme-container.textures-color .square.directory {
  background-image: url("img/buttons/button_phone-directory.jpg");
}
#theme-container.textures-color .square.dry_cleaning {
  background-image: url("img/buttons/button_dry_cleaning.jpg");
}
#theme-container.textures-color .square.facials {
  background-image: url("img/buttons/button_facials.jpg");
}
#theme-container.textures-color .square.automation {
  background-image: url("img/buttons/button_home_automation.jpg");
}
#theme-container.textures-color .square.maintenance {
  background-image: url("img/buttons/button_maintenance.jpg");
}
#theme-container.textures-color .square.massages {
  background-image: url("img/buttons/button_massages.jpg");
}
#theme-container.textures-color .square.messages {
  background-image: url("img/buttons/button_messages.jpg");
}
#theme-container.textures-color .square.personal_trainer {
  background-image: url("img/buttons/button_personal_trainer.jpg");
}
#theme-container.textures-color .square.restaurant {
  background-image: url("img/buttons/button_restaurant.jpg");
}
#theme-container.textures-color .square.restaurant_reservations {
  background-image: url("img/buttons/button_restaurant_reservations.jpg");
}
#theme-container.textures-color .square.settings {
  background-image: url("img/buttons/button_settings.jpg");
}
#theme-container.textures-color .square.spa {
  background-image: url("img/buttons/button_spa.jpg");
}
#theme-container.textures-color .square.transportation {
  background-image: url("img/buttons/button_transportation.jpg");
}
#theme-container.textures-color .square.valet {
  background-image: url("img/buttons/button_valet-parking.jpg");
}
#theme-container.textures-color .square.weather {
  background-image: url("img/buttons/button_weather.jpg");
}
#theme-container.textures-color .square.emergency {
  background-image: url("img/buttons/button_emergency.jpeg");
}
#theme-container.textures-color .square.guest_auth {
  background-image: url("img/buttons/button_guest.png");
}
#theme-container.textures-color .square.fire {
  background-image: url("img/buttons/button_fire.png");
}
#theme-container.textures-color .square.security {
  background-image: url("img/buttons/button_security.png");
}
#theme-container.textures-color .square.medical {
  background-image: url("img/buttons/button_medical.png");
}
#theme-container.textures-color .square.expenses {
  background-image: url("img/buttons/button_expenses.png");
}
#theme-container.textures-color .square.polls {
  background-image: url("img/buttons/button_poll.png");
}
#theme-container.textures-color .square.others {
  background-image: url("img/buttons/other.jpeg");
}
#theme-container.textures-color .square.questions {
  background-image: url("img/buttons/question.jpeg");
}
#theme-container.textures-color .square.suggestion {
  background-image: url("img/buttons/suggestion.jpeg");
}
#theme-container.textures-color .square.demo_res {
  background-image: url("img/buttons/beach_restaurant.jpg");
}
#theme-container.textures-color .square.demo_res .restaurant-icon {
  stroke: white;
}
#theme-container.textures-color .square.cameras {
  background-image: url("img/buttons/camera.jpg");
}
#theme-container.textures-color .square.beoliving {
  background-image: url("https://www.mirigi.com/img/features/camera-1.jpg");
}
#theme-container.textures-color .square.reservable_33 {
  background-image: url("img/buttons/res_pool.jpg");
}
#theme-container.textures-color .square.reservable_34 {
  background-image: url("img/buttons/res_barbacue.jpg");
}
#theme-container.textures-color .square.reservable_58 {
  background-image: url("img/buttons/res_barbacue.jpg");
}
#theme-container.textures-color .square.reservable_35 {
  background-image: url("img/buttons/res_party_room.jpg");
}
#theme-container.textures-color .square.reservable_36 {
  background-image: url("img/buttons/res_deposit.jpg");
}
#theme-container.textures-color .square.reservable_43 {
  background-image: url("img/buttons/res_gym.jpg");
}
#theme-container.textures-color .square.reservable_45 {
  background-image: url("img/buttons/res_bicycle.jpg");
}
#theme-container.textures-color .square.reservable_49 {
  background-image: url("img/buttons/res_dining_room.jpg");
}
#theme-container.textures-color .square.reservable_52 {
  background-image: url("img/buttons/res_theater.jpg");
}
#theme-container.textures-color .square.reservable_57 {
  background-image: url("img/buttons/res_rooms.jpg");
}
#theme-container.textures-color .square.fire, #theme-container.textures-color .square.security, #theme-container.textures-color .square.medical {
  background-position: center 30%;
  background-repeat: no-repeat;
  background-size: 60%;
  background-color: black;
}
#theme-container.textures-color .transparent-field, #theme-container.textures-color .page-ul {
  padding: 1rem 1rem;
  background: rgba(255, 255, 255, 0.93);
}
#theme-container.textures-color .page-ul:empty {
  display: none;
}
#theme-container.textures-color .page-ul {
  list-style: none;
}
@media (max-width: 480px) {
  #theme-container.textures-color .page-ul {
    margin: 0px;
  }
}
@media (min-width: 480px) and (max-width: 680px) {
  #theme-container.textures-color .page-ul {
    margin: 0px;
  }
}
@media (min-width: 680px) and (max-width: 900px) {
  #theme-container.textures-color .page-ul {
    margin: 0px;
  }
}
@media (min-width: 900px) and (max-width: 1250px) {
  #theme-container.textures-color .page-ul {
    margin: 0px 2rem;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.85);
  }
}
@media (min-width: 1250px) and (max-width: 1500px) {
  #theme-container.textures-color .page-ul {
    margin: 0px 2rem;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.85);
  }
}
@media (min-width: 1500px) and (max-width: 2000px) {
  #theme-container.textures-color .page-ul {
    margin: 0px 2rem;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.85);
  }
}
@media (min-width: 2000px) {
  #theme-container.textures-color .page-ul {
    margin: 0px 2rem;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.85);
  }
}
#theme-container.textures-color .page-ul .li-request {
  position: relative;
  margin: 25px 0px;
  margin-top: 6px;
  background: none;
  border-left: 0.6rem solid rgb(63, 63, 63);
  border-top: 1px dotted;
  padding: 0.5rem 0px 0px 1rem;
}
@media (max-width: 480px) {
  #theme-container.textures-color .page-ul .li-request {
    margin: 20px 0px;
  }
}
@media (min-width: 480px) and (max-width: 680px) {
  #theme-container.textures-color .page-ul .li-request {
    margin: 20px 0px;
  }
}
@media (min-width: 680px) and (max-width: 900px) {
  #theme-container.textures-color .page-ul .li-request {
    margin: 20px 0px;
  }
}
#theme-container.textures-color .page-ul .li-request.readed {
  color: rgb(131, 128, 128);
  border-left: 0.6rem solid rgb(149, 149, 148);
}
#theme-container.textures-color .page-ul .title {
  font-size: 2rem;
  line-height: 2rem;
  margin: 0px 0px 7px 0px;
  font-weight: normal;
}
#theme-container.textures-color .page-ul .subtitle {
  font-size: 1.4rem;
}
#theme-container.textures-color .page-ul p {
  margin-bottom: 0;
}
#theme-container.textures-color .page-ul .body .li-request {
  border: none;
}
#theme-container.textures-color .page-ul .date {
  position: absolute;
  top: 0;
  right: 0;
  margin: 1rem 11px 1rem 0px;
  font-weight: lighter;
}
#theme-container.textures-color .page-ul-enter {
  opacity: 0;
  max-height: 0;
  transition: max-height 0.5s ease-out, opacity 0.3s ease-out;
}
#theme-container.textures-color .page-ul-enter.page-ul-enter-active {
  max-height: 500px;
  opacity: 1;
  transition: max-height 0.5s ease-in, opacity 0.3s ease-in;
}
#theme-container.textures-color .page-frame {
  background: rgb(132, 161, 203);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("img/background/bg_abstract.jpg");
}
#theme-container.textures-color .page-frame .page-header h1 {
  font-size: 2.5rem;
  letter-spacing: 2px;
  color: rgb(99, 102, 106);
  font-weight: normal;
}
#theme-container.textures-color .page-frame .page-header .top {
  color: white;
}
#theme-container.textures-color .page-frame .page-header .top .back-link .anticon-arrow-left:before {
  content: "\e696";
}
#theme-container.textures-color .page-frame .page-header .top .back-link .icon {
  color: white;
  font-size: 2rem;
}
#theme-container.textures-color .page-frame .page-header h1.title {
  font-size: 4rem;
  letter-spacing: 1px;
  color: white;
  text-transform: capitalize;
  margin-left: 2rem;
  margin-bottom: 1rem;
  text-align: left;
  font-weight: normal;
  text-align: left;
}
#theme-container.textures-color .page-frame.amenities {
  background-image: url("img/background/bg_amenities.jpg");
  background-color: rgb(160, 103, 110);
}
#theme-container.textures-color .page-frame.body_treatments {
  background-image: url("img/background/bg_body_treatments.jpg");
  background-color: rgb(255, 255, 255);
}
#theme-container.textures-color .page-frame.car_wash {
  background-image: url("img/background/bg_car_wash.jpg");
  background-color: rgb(14, 13, 18);
}
#theme-container.textures-color .page-frame.concierge {
  background-image: url("img/background/bg_concierge.jpg");
  background-color: rgb(0, 0, 0);
}
#theme-container.textures-color .page-frame.deliveries {
  background-image: url("img/background/bg_deliveries.jpg");
  background-color: rgb(70, 58, 36);
}
#theme-container.textures-color .page-frame.directory {
  background-image: url("img/background/bg_phone-directory.jpg");
  background-color: rgb(35, 50, 90);
}
#theme-container.textures-color .page-frame.dry_cleaning {
  background-image: url("img/background/bg_dry_cleaning.jpg");
  background-color: rgb(200, 200, 200);
}
#theme-container.textures-color .page-frame.facials {
  background-image: url("img/background/bg_facials.jpg");
  background-color: rgb(255, 255, 255);
}
#theme-container.textures-color .page-frame.maintenance {
  background-image: url("img/background/bg_maintenance.jpg");
  background-color: rgb(160, 160, 160);
}
#theme-container.textures-color .page-frame.massages {
  background-image: url("img/background/bg_massages.jpg");
  background-color: rgb(0, 0, 0);
}
#theme-container.textures-color .page-frame.messages {
  background-image: url("img/background/bg_messages.jpg");
  background-color: rgb(26, 27, 21);
}
#theme-container.textures-color .page-frame.personal_trainer {
  background-image: url("img/background/bg_personal_trainer.jpg");
  background-color: rgb(0, 0, 0);
}
#theme-container.textures-color .page-frame.restaurant {
  background-image: url("img/background/bg_restaurant.jpg");
  background-color: rgb(80, 65, 50);
}
#theme-container.textures-color .page-frame.restaurant_reservations {
  background-image: url("img/background/bg_restaurant_reservations.jpg");
  background-color: rgb(24, 19, 15);
}
#theme-container.textures-color .page-frame.settings {
  background-image: url("img/background/bg_settings.jpg");
  background-color: rgb(0, 0, 0);
}
#theme-container.textures-color .page-frame.spa {
  background-image: url("img/background/bg_spa.jpg");
  background-color: rgb(140, 60, 30);
}
#theme-container.textures-color .page-frame.transportation {
  background-image: url("img/background/bg_transportation.jpg");
  background-color: rgb(0, 0, 0);
}
#theme-container.textures-color .page-frame.valet {
  background-image: url("img/background/bg_valet-parking.jpg");
  background-color: rgb(0, 0, 0);
}
#theme-container.textures-color .page-frame.weather {
  background-image: url("img/background/bg_weather.jpg");
  background-color: rgb(40, 100, 55);
}
#theme-container.textures-color .ant-btn-primary {
  background-color: black;
  border-color: black;
}
#theme-container.textures-color .ant-switch-checked {
  background-color: black;
}
#theme-container.textures-color .ant-form-item-control {
  border-color: black;
}
#theme-container.textures-color a {
  text-decoration: none;
  color: black;
}
#theme-container.textures-color a:hover {
  color: gray;
}
#theme-container.textures-color .ant-time-picker-input,
#theme-container.textures-color .ant-select-selection--single,
#theme-container.textures-color .ant-input {
  background: rgba(255, 255, 255, 0.51);
}
#theme-container.textures-color .ant-btn:focus,
#theme-container.textures-color .ant-btn:hover,
#theme-container.textures-color .ant-select-selection--single:focus,
#theme-container.textures-color .ant-select-selection:focus,
#theme-container.textures-color .ant-time-picker-input:focus,
#theme-container.textures-color .ant-input:focus {
  color: black;
  border-color: grey;
  box-shadow: 0px 0px 10px 3px white;
}
#theme-container.textures-color .ant-btn-primary:focus,
#theme-container.textures-color .ant-btn-primary:hover {
  color: gray;
}
#theme-container.textures-color .ant-select-selection--single:hover,
#theme-container.textures-color .ant-select-selection:hover,
#theme-container.textures-color .ant-time-picker-input:hover,
#theme-container.textures-color .ant-input:hover {
  border-color: black;
}

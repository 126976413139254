#theme-container.montalban .settings .mail .own-email {
  color: rgb(6, 61, 22);
}
#theme-container.montalban .dashboard {
  background: url("img/background/background.png");
  background-color: rgb(156, 200, 250);
}
#theme-container.montalban .dashboard .header {
  background-color: unset;
  color: white;
}
#theme-container.montalban .dashboard .header a {
  color: white;
}
#theme-container.montalban .dashboard .hidden-menu a {
  color: white;
}
#theme-container.montalban .dashboard .board {
  border-style: none;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.8);
  background-color: rgba(255, 255, 255, 0.8);
  margin: 30px;
  padding: 15px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  min-width: 87%;
}
#theme-container.montalban .dashboard .board .board-item {
  margin: 30px;
}
#theme-container.montalban .dashboard .ant-slider .ant-slider-track {
  background: rgb(69, 64, 64);
}
#theme-container.montalban .dashboard .ant-slider .ant-slider-rail {
  background-color: white;
}
#theme-container.montalban .dashboard .ant-slider .ant-slider-handle {
  background: black;
  border-color: rgba(233, 233, 233, 0.61);
  box-shadow: none;
}
#theme-container.montalban .dashboard .ant-slider:focus .ant-slider-track,
#theme-container.montalban .dashboard .ant-slider:hover .ant-slider-track {
  background: black;
}
#theme-container.montalban .dashboard .ant-slider:focus .ant-slider-rail,
#theme-container.montalban .dashboard .ant-slider:hover .ant-slider-rail {
  background-color: white;
}
#theme-container.montalban .dashboard .ant-slider:focus .ant-slider-handle,
#theme-container.montalban .dashboard .ant-slider:hover .ant-slider-handle {
  background: black;
  border-color: white;
  box-shadow: none;
}
#theme-container.montalban .vehicle {
  -webkit-box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}
#theme-container.montalban .vehicle.ready .vehicle-info {
  background: rgb(99, 102, 106);
  color: rgb(232, 232, 232);
}
#theme-container.montalban .vehicle.ready .logo-bar {
  background: rgba(255, 255, 255, 0.85);
}
#theme-container.montalban .vehicle.requested .vehicle-info {
  background: rgb(153, 153, 153);
  color: rgb(232, 232, 232);
}
#theme-container.montalban .vehicle.requested .logo-bar {
  background: rgba(255, 255, 255, 0.85);
}
#theme-container.montalban .vehicle.carwash_requested .vehicle-info {
  background: rgb(153, 153, 153);
  color: rgb(232, 232, 232);
}
#theme-container.montalban .vehicle.carwash_requested .logo-bar {
  background: rgba(255, 255, 255, 0.85);
}
#theme-container.montalban .vehicle.parked .vehicle-info {
  background: rgb(203, 203, 203);
  color: rgb(121, 121, 121);
}
#theme-container.montalban .vehicle.parked .logo-bar {
  background: rgba(255, 255, 255, 0.85);
}
#theme-container.montalban .vehicle.washing .vehicle-info {
  background: rgba(255, 255, 255, 0.85);
  color: #577c9a;
}
#theme-container.montalban .vehicle.washing .logo-bar {
  background: rgba(255, 255, 255, 0.85);
}
#theme-container.montalban .vehicle.topark .vehicle-info {
  background: rgba(255, 255, 255, 0.85);
  color: rgb(187, 120, 96);
}
#theme-container.montalban .vehicle.topark .logo-bar {
  background: rgba(255, 255, 255, 0.85);
}
#theme-container.montalban .vehicle.out .vehicle-info {
  background: rgba(255, 255, 255, 0.85);
  color: rgb(132, 132, 132);
}
#theme-container.montalban .vehicle.out .logo-bar {
  background: rgba(255, 255, 255, 0.85);
}
#theme-container.montalban .reservables .ant-fullcalendar-fullscreen .ant-fullcalendar-header {
  background: rgba(0, 0, 0, 0.5);
  border: none;
}
#theme-container.montalban .reservables .ant-fullcalendar-fullscreen .ant-fullcalendar-calendar-body .ant-fullcalendar-column-header {
  background: black;
  border: none;
}
#theme-container.montalban .reservables .ant-fullcalendar-fullscreen .ant-select-selection--single {
  background: none;
}
#theme-container.montalban .reservables .ant-fullcalendar-fullscreen thead {
  background: rgba(0, 0, 0, 0.7);
  border: none;
}
#theme-container.montalban .square.weather.no-weather {
  background-size: 100%;
}
#theme-container.montalban .square.weather {
  background-size: 8rem, 100%;
  background-repeat: no-repeat;
  background-position: center;
}
#theme-container.montalban .weather .board .square {
  background: none;
  background-size: 6rem;
  background-repeat: no-repeat;
  background-position: center;
  border: 2px solid white;
  box-shadow: 0px 0px 4px 2px rgba(255, 255, 255, 0.85);
}
#theme-container.montalban .weather .board .square.chance_of_rain {
  background-image: url("../../weather/wsymbol/wsymbol_0009_light_rain_showers.png");
}
#theme-container.montalban .weather .board .square.chance_of_snow {
  background-image: url("../../weather/wsymbol/wsymbol_0011_light_snow_showers.png");
}
#theme-container.montalban .weather .board .square.chance_of_storm {
  background-image: url("../../weather/wsymbol/wsymbol_0010_heavy_rain_showers.png");
}
#theme-container.montalban .weather .board .square.chance_of_tstorm {
  background-image: url("../../weather/wsymbol/wsymbol_0016_thundery_showers.png");
}
#theme-container.montalban .weather .board .square.chanceflurries {
  background-image: url("../../weather/wsymbol/wsymbol_0011_light_snow_showers.png");
}
#theme-container.montalban .weather .board .square.chancerain {
  background-image: url("../../weather/wsymbol/wsymbol_0009_light_rain_showers.png");
}
#theme-container.montalban .weather .board .square.chancesleet {
  background-image: url("../../weather/wsymbol/wsymbol_0013_sleet_showers.png");
}
#theme-container.montalban .weather .board .square.chancesnow {
  background-image: url("../../weather/wsymbol/wsymbol_0011_light_snow_showers.png");
}
#theme-container.montalban .weather .board .square.chancetstorms {
  background-image: url("../../weather/wsymbol/wsymbol_0016_thundery_showers.png");
}
#theme-container.montalban .weather .board .square.clear {
  background-image: url("../../weather/wsymbol/wsymbol_0001_sunny.png");
}
#theme-container.montalban .weather .board .square.cloudy {
  background-image: url("../../weather/wsymbol/wsymbol_0004_black_low_cloud.png");
}
#theme-container.montalban .weather .board .square.dust {
  background-image: url("../../weather/wsymbol/wsymbol_0005_hazy_sun.png");
}
#theme-container.montalban .weather .board .square.flurries {
  background-image: url("../../weather/wsymbol/wsymbol_0019_cloudy_with_light_snow.png");
}
#theme-container.montalban .weather .board .square.fog {
  background-image: url("../../weather/wsymbol/wsymbol_0007_fog.png");
}
#theme-container.montalban .weather .board .square.haze {
  background-image: url("../../weather/wsymbol/wsymbol_0005_hazy_sun.png");
}
#theme-container.montalban .weather .board .square.hazy {
  background-image: url("../../weather/wsymbol/wsymbol_0005_hazy_sun.png");
}
#theme-container.montalban .weather .board .square.icy {
  background-image: url("../../weather/wsymbol/wsymbol_0020_cloudy_with_heavy_snow.png");
}
#theme-container.montalban .weather .board .square.mist {
  background-image: url("../../weather/wsymbol/wsymbol_0017_cloudy_with_light_rain.png");
}
#theme-container.montalban .weather .board .square.mostly_cloudy {
  background-image: url("../../weather/wsymbol/wsymbol_0003_white_cloud.png");
}
#theme-container.montalban .weather .board .square.mostly_sunny {
  background-image: url("../../weather/wsymbol/wsymbol_0002_sunny_intervals.png");
}
#theme-container.montalban .weather .board .square.mostlycloudy {
  background-image: url("../../weather/wsymbol/wsymbol_0003_white_cloud.png");
}
#theme-container.montalban .weather .board .square.mostlysunny {
  background-image: url("../../weather/wsymbol/wsymbol_0002_sunny_intervals.png");
}
#theme-container.montalban .weather .board .square.partly_cloudy {
  background-image: url("../../weather/wsymbol/wsymbol_0002_sunny_intervals.png");
}
#theme-container.montalban .weather .board .square.partlycloudy {
  background-image: url("../../weather/wsymbol/wsymbol_0002_sunny_intervals.png");
}
#theme-container.montalban .weather .board .square.partlysunny {
  background-image: url("../../weather/wsymbol/wsymbol_0002_sunny_intervals.png");
}
#theme-container.montalban .weather .board .square.rain {
  background-image: url("../../weather/wsymbol/wsymbol_0018_cloudy_with_heavy_rain.png");
}
#theme-container.montalban .weather .board .square.sleet {
  background-image: url("../../weather/wsymbol/wsymbol_0021_cloudy_with_sleet.png");
}
#theme-container.montalban .weather .board .square.smoke {
  background-image: url("../../weather/wsymbol/wsymbol_0007_fog.png");
}
#theme-container.montalban .weather .board .square.snow {
  background-image: url("../../weather/wsymbol/wsymbol_0020_cloudy_with_heavy_snow.png");
}
#theme-container.montalban .weather .board .square.storm {
  background-image: url("../../weather/wsymbol/wsymbol_0018_cloudy_with_heavy_rain.png");
}
#theme-container.montalban .weather .board .square.sunny {
  background-image: url("../../weather/wsymbol/wsymbol_0001_sunny.png");
}
#theme-container.montalban .weather .board .square.thunderstorm {
  background-image: url("../../weather/wsymbol/wsymbol_0024_thunderstorms.png");
}
#theme-container.montalban .weather .board .square.tstorms {
  background-image: url("../../weather/wsymbol/wsymbol_0024_thunderstorms.png");
}
#theme-container.montalban .weather .board .square.nt_clear {
  background-image: url("../../weather/wsymbol/wsymbol_0008_clear_sky_night.png");
}
#theme-container.montalban .vehicle {
  -webkit-box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}
#theme-container.montalban .vehicle.ready .vehicle-info {
  background: rgb(99, 102, 106);
  color: rgb(232, 232, 232);
}
#theme-container.montalban .vehicle.ready .logo-bar {
  background: rgba(255, 255, 255, 0.85);
}
#theme-container.montalban .vehicle.requested .vehicle-info {
  background: rgb(153, 153, 153);
  color: rgb(232, 232, 232);
}
#theme-container.montalban .vehicle.requested .logo-bar {
  background: rgba(255, 255, 255, 0.85);
}
#theme-container.montalban .vehicle.carwash_requested .vehicle-info {
  background: rgb(153, 153, 153);
  color: rgb(232, 232, 232);
}
#theme-container.montalban .vehicle.carwash_requested .logo-bar {
  background: rgba(255, 255, 255, 0.85);
}
#theme-container.montalban .vehicle.parked .vehicle-info {
  background: rgb(203, 203, 203);
  color: rgb(121, 121, 121);
}
#theme-container.montalban .vehicle.parked .logo-bar {
  background: rgba(255, 255, 255, 0.85);
}
#theme-container.montalban .vehicle.washing .vehicle-info {
  background: rgba(255, 255, 255, 0.85);
  color: #577c9a;
}
#theme-container.montalban .vehicle.washing .logo-bar {
  background: rgba(255, 255, 255, 0.85);
}
#theme-container.montalban .vehicle.topark .vehicle-info {
  background: rgba(255, 255, 255, 0.85);
  color: rgb(187, 120, 96);
}
#theme-container.montalban .vehicle.topark .logo-bar {
  background: rgba(255, 255, 255, 0.85);
}
#theme-container.montalban .vehicle.out .vehicle-info {
  background: rgba(255, 255, 255, 0.85);
  color: rgb(132, 132, 132);
}
#theme-container.montalban .vehicle.out .logo-bar {
  background: rgba(255, 255, 255, 0.85);
}
#theme-container.montalban .automation.box .widgets-box h2 {
  background: rgba(255, 255, 255, 0.4);
  color: black;
}
#theme-container.montalban .automation.box .widgets-box h3 {
  font-weight: normal;
  border-bottom: 1px solid white;
}
#theme-container.montalban .automation.box .widgets-box .zones {
  background: rgba(0, 0, 0, 0.54);
}
#theme-container.montalban .automation.box .widgets-box .zones .ant-btn {
  background: none;
  border: none;
}
#theme-container.montalban .automation.box .widgets-box .zones .ant-btn:hover {
  color: white;
}
#theme-container.montalban .automation.box .widgets-box .zones .ant-btn:focus {
  color: white;
  box-shadow: none;
}
@media (max-width: 480px) {
  #theme-container.montalban .automation.box h3 {
    border: 1px solid white;
  }
}
@media (min-width: 480px) and (max-width: 680px) {
  #theme-container.montalban .automation.box h3 {
    border: 1px solid white;
  }
}
@media (min-width: 680px) and (max-width: 900px) {
  #theme-container.montalban .automation.box h3 {
    border: 1px solid white;
  }
}
#theme-container.montalban .square {
  position: relative;
  background-color: rgba(68, 68, 68, 0.9);
  background-image: url("img/buttons/button_generic-icon.png");
  background-size: 100%;
  background-position: center;
  border: 1px solid darkgrey;
  border-radius: 0%;
  box-shadow: none;
  color: white;
  text-shadow: none;
  padding: 20px;
  float: none;
}
#theme-container.montalban .square .title {
  position: absolute;
  width: 100%;
  bottom: 10px;
  text-align: center;
  text-transform: uppercase;
  font-size: 1rem;
}
@media (max-width: 480px) {
  #theme-container.montalban .square {
    margin: 3px;
    width: calc(33.3333333vw - 6px);
    height: calc(33.3333333vw - 6px);
  }
}
@media (min-width: 480px) and (max-width: 680px) {
  #theme-container.montalban .square {
    margin: 3px;
    width: calc(33.3333333vw - 6px);
    height: calc(33.3333333vw - 6px);
  }
}
@media (min-width: 680px) and (max-width: 900px) {
  #theme-container.montalban .square {
    margin: 3px;
    width: calc(25vw - 6px);
    height: calc(25vw - 6px);
  }
}
@media (min-width: 900px) and (max-width: 1250px) {
  #theme-container.montalban .square {
    margin: 5px;
    width: calc(20vw - 10px);
    height: calc(20vw - 10px);
  }
}
@media (min-width: 1250px) and (max-width: 1500px) {
  #theme-container.montalban .square {
    margin: 8px;
    width: calc(16.666666666vw - 16px);
    height: calc(16.666666666vw - 16px);
  }
}
@media (min-width: 1500px) and (max-width: 2000px) {
  #theme-container.montalban .square {
    margin: 12px;
    width: calc(14.285vw - 24px);
    height: calc(14.285vw - 24px);
  }
}
@media (min-width: 2000px) {
  #theme-container.montalban .square {
    margin: 12px;
    width: calc(12.5vw - 24px);
    height: calc(12.5vw - 24px);
  }
}
#theme-container.montalban .square.messages {
  background-image: url("img/buttons/button_messages.png");
}
#theme-container.montalban .square.deliveries {
  background-image: url("img/buttons/button_deliveries.png");
}
#theme-container.montalban .square.valet {
  background-image: url("img/buttons/button_valet-parking.png");
}
#theme-container.montalban .square.directory {
  background-image: url("img/buttons/button_phone-directory.png");
}
#theme-container.montalban .square.automation {
  background-image: url("img/buttons/button_home_automation.png");
}
#theme-container.montalban .square.settings {
  background-image: url("img/buttons/button_settings.png");
}
#theme-container.montalban .page-ul:empty {
  display: none;
}
#theme-container.montalban .page-ul {
  list-style: none;
  padding: 1rem 1rem;
  background: rgba(255, 255, 255, 0.71);
}
@media (max-width: 480px) {
  #theme-container.montalban .page-ul {
    margin: 0px;
  }
}
@media (min-width: 480px) and (max-width: 680px) {
  #theme-container.montalban .page-ul {
    margin: 0px;
  }
}
@media (min-width: 680px) and (max-width: 900px) {
  #theme-container.montalban .page-ul {
    margin: 0px;
  }
}
@media (min-width: 900px) and (max-width: 1250px) {
  #theme-container.montalban .page-ul {
    margin: 0px 2rem;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.85);
  }
}
@media (min-width: 1250px) and (max-width: 1500px) {
  #theme-container.montalban .page-ul {
    margin: 0px 2rem;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.85);
  }
}
@media (min-width: 1500px) and (max-width: 2000px) {
  #theme-container.montalban .page-ul {
    margin: 0px 2rem;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.85);
  }
}
@media (min-width: 2000px) {
  #theme-container.montalban .page-ul {
    margin: 0px 2rem;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.85);
  }
}
#theme-container.montalban .page-ul .li-request {
  position: relative;
  margin: 25px 0px;
  margin-top: 6px;
  background: none;
  border-left: 0.6rem solid rgb(63, 63, 63);
  border-top: 1px dotted;
  padding: 0.5rem 0px 0px 1rem;
}
@media (max-width: 480px) {
  #theme-container.montalban .page-ul .li-request {
    margin: 20px 0px;
  }
}
@media (min-width: 480px) and (max-width: 680px) {
  #theme-container.montalban .page-ul .li-request {
    margin: 20px 0px;
  }
}
@media (min-width: 680px) and (max-width: 900px) {
  #theme-container.montalban .page-ul .li-request {
    margin: 20px 0px;
  }
}
#theme-container.montalban .page-ul .li-request.readed {
  color: rgb(131, 128, 128);
  border-left: 0.6rem solid rgb(149, 149, 148);
}
#theme-container.montalban .page-ul .title {
  font-size: 2rem;
  line-height: 2rem;
  margin: 0px 0px 7px 0px;
  font-weight: normal;
}
#theme-container.montalban .page-ul .subtitle {
  font-size: 1.4rem;
}
#theme-container.montalban .page-ul p {
  margin-bottom: 0;
}
#theme-container.montalban .page-ul .body .li-request {
  border: none;
}
#theme-container.montalban .page-ul .date {
  position: absolute;
  top: 0;
  right: 0;
  margin: 1rem 11px 1rem 0px;
  font-weight: lighter;
}
#theme-container.montalban .page-ul-enter {
  opacity: 0;
  max-height: 0;
  transition: max-height 0.5s ease-out, opacity 0.3s ease-out;
}
#theme-container.montalban .page-ul-enter.page-ul-enter-active {
  max-height: 500px;
  opacity: 1;
  transition: max-height 0.5s ease-in, opacity 0.3s ease-in;
}
#theme-container.montalban .page-frame {
  background: rgb(132, 161, 203);
  background-color: rgb(209, 211, 212);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("img/background/background.png");
}
#theme-container.montalban .page-frame .page-header h1 {
  font-size: 2.5rem;
  letter-spacing: 2px;
  color: rgb(99, 102, 106);
  font-weight: normal;
}
#theme-container.montalban .page-frame .page-header .top {
  color: white;
}
#theme-container.montalban .page-frame .page-header .top .back-link .anticon-arrow-left:before {
  content: "\e696";
}
#theme-container.montalban .page-frame .page-header .top .back-link .icon {
  color: white;
  font-size: 2rem;
}
#theme-container.montalban .page-frame .page-header h1.title {
  font-size: 4rem;
  letter-spacing: 1px;
  color: white;
  text-transform: capitalize;
  margin-left: 2rem;
  margin-bottom: 1rem;
  text-align: left;
  font-weight: normal;
  text-align: left;
}
#theme-container.montalban .ant-btn-primary {
  background-color: black;
  border-color: black;
}
#theme-container.montalban .ant-switch-checked {
  background-color: black;
}
#theme-container.montalban .ant-form-item-control {
  border-color: black;
}
#theme-container.montalban a {
  text-decoration: none;
  color: black;
}
#theme-container.montalban a:hover {
  color: white;
}
#theme-container.montalban .ant-time-picker-input,
#theme-container.montalban .ant-select-selection--single,
#theme-container.montalban .ant-input {
  background: rgba(255, 255, 255, 0.51);
}
#theme-container.montalban .ant-btn:focus,
#theme-container.montalban .ant-btn:hover,
#theme-container.montalban .ant-select-selection--single:focus,
#theme-container.montalban .ant-select-selection:focus,
#theme-container.montalban .ant-time-picker-input:focus,
#theme-container.montalban .ant-input:focus {
  color: black;
  border-color: grey;
  box-shadow: 0px 0px 10px 3px white;
}
#theme-container.montalban .ant-btn-primary:focus,
#theme-container.montalban .ant-btn-primary:hover {
  color: white;
}
#theme-container.montalban .ant-select-selection--single:hover,
#theme-container.montalban .ant-select-selection:hover,
#theme-container.montalban .ant-time-picker-input:hover,
#theme-container.montalban .ant-input:hover {
  border-color: black;
}

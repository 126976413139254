@font-face {
  font-family: "Gotham Book";
  src: url("./fonts/GothamBook.ttf");
}
@font-face {
  font-family: "Gotham Medium";
  src: url("./fonts/GothamMedium.ttf");
}
@font-face {
  font-family: "Gotham Bold";
  src: url("./fonts/GothamBold.ttf");
}
@font-face {
  font-family: "Gotham Light";
  src: url("./fonts/GothamLight.ttf");
}
@font-face {
  font-family: "Gotham Light Italic";
  src: url("./fonts/GothamLightItalic.ttf");
}
#theme-container.fifteenfifteen {
  color: white;
  font-weight: lighter;
}
#theme-container.fifteenfifteen .settings .mail .own-email {
  color: rgb(6, 61, 22);
}
#theme-container.fifteenfifteen .dashboard {
  background: linear-gradient(to bottom left, rgb(13.3636363636, 11.8363636364, 12.2181818182) 0%, rgb(88, 89, 91) 100%);
}
#theme-container.fifteenfifteen .dashboard .header {
  color: rgb(99, 102, 106);
  height: 17rem;
  background: black;
}
#theme-container.fifteenfifteen .dashboard .header a {
  color: rgb(99, 102, 106);
}
#theme-container.fifteenfifteen .dashboard .header img {
  height: 8rem;
}
#theme-container.fifteenfifteen .dashboard .hidden-menu a {
  color: white;
}
#theme-container.fifteenfifteen .dashboard .ant-slider .ant-slider-track {
  background: rgb(69, 64, 64);
}
#theme-container.fifteenfifteen .dashboard .ant-slider .ant-slider-rail {
  background-color: rgb(201, 201, 201);
}
#theme-container.fifteenfifteen .dashboard .ant-slider .ant-slider-handle {
  background: rgb(69, 64, 64);
  border-color: rgba(233, 233, 233, 0.61);
  box-shadow: none;
}
#theme-container.fifteenfifteen .dashboard .ant-slider:focus .ant-slider-track,
#theme-container.fifteenfifteen .dashboard .ant-slider:hover .ant-slider-track {
  background: black;
}
#theme-container.fifteenfifteen .dashboard .ant-slider:focus .ant-slider-rail,
#theme-container.fifteenfifteen .dashboard .ant-slider:hover .ant-slider-rail {
  background-color: rgb(201, 201, 201);
}
#theme-container.fifteenfifteen .dashboard .ant-slider:focus .ant-slider-handle,
#theme-container.fifteenfifteen .dashboard .ant-slider:hover .ant-slider-handle {
  background: black;
  border-color: white;
  box-shadow: none;
}
#theme-container.fifteenfifteen .page-header h1 {
  text-transform: uppercase;
  font-size: 2.5rem;
  letter-spacing: 2px;
  color: rgb(99, 102, 106);
  font-weight: normal;
  margin-top: 7rem;
}
#theme-container.fifteenfifteen .page-header .top {
  color: white;
  height: 7rem;
}
#theme-container.fifteenfifteen .page-header .top .back-link .icon {
  color: white;
  font-size: 2rem;
}
#theme-container.fifteenfifteen .page-header h1.title {
  font-size: 2rem;
  letter-spacing: 1px;
  color: #fefefe;
  text-transform: uppercase;
  padding: 4rem;
  text-align: left;
  font-weight: 600;
}
#theme-container.fifteenfifteen .page-description a {
  text-decoration: underline;
}
#theme-container.fifteenfifteen .reservables .ant-fullcalendar-fullscreen .ant-fullcalendar-header {
  background: rgb(43, 44, 45);
  border: none;
}
#theme-container.fifteenfifteen .reservables .ant-fullcalendar-fullscreen .ant-fullcalendar-calendar-body .ant-fullcalendar-column-header {
  background: rgb(43, 44, 45);
  border: none;
}
#theme-container.fifteenfifteen .reservables .ant-fullcalendar-fullscreen .ant-select-selection--single {
  background: none;
}
#theme-container.fifteenfifteen .square.chance_of_rain {
  background-image: url("img/Rain.png");
}
#theme-container.fifteenfifteen .square.chance_of_snow {
  background-image: url("img/Snow.png");
}
#theme-container.fifteenfifteen .square.chance_of_storm {
  background-image: url("img/Rain.png");
}
#theme-container.fifteenfifteen .square.chance_of_tstorm {
  background-image: url("img/Rain.png");
}
#theme-container.fifteenfifteen .square.chanceflurries {
  background-image: url("img/Rain.png");
}
#theme-container.fifteenfifteen .square.chancerain {
  background-image: url("img/Rain.png");
}
#theme-container.fifteenfifteen .square.chancesleet {
  background-image: url("img/Rain.png");
}
#theme-container.fifteenfifteen .square.chancesnow {
  background-image: url("img/Snow.png");
}
#theme-container.fifteenfifteen .square.chancetstorms {
  background-image: url("img/Rain.png");
}
#theme-container.fifteenfifteen .square.clear {
  background-image: url("img/Sunny.png");
}
#theme-container.fifteenfifteen .square.cloudy {
  background-image: url("img/Cloudy.png");
}
#theme-container.fifteenfifteen .square.dust {
  background-image: url("img/Sunny.png");
}
#theme-container.fifteenfifteen .square.flurries {
  background-image: url("img/Rain.png");
}
#theme-container.fifteenfifteen .square.fog {
  background-image: url("img/Cloudy.png");
}
#theme-container.fifteenfifteen .square.haze {
  background-image: url("img/Cloudy.png");
}
#theme-container.fifteenfifteen .square.hazy {
  background-image: url("img/Cloudy.png");
}
#theme-container.fifteenfifteen .square.icy {
  background-image: url("img/Cloudy.png");
}
#theme-container.fifteenfifteen .square.mist {
  background-image: url("img/Cloudy.png");
}
#theme-container.fifteenfifteen .square.mostly_cloudy {
  background-image: url("img/Cloudy.png");
}
#theme-container.fifteenfifteen .square.mostly_sunny {
  background-image: url("img/Sunny.png");
}
#theme-container.fifteenfifteen .square.mostlycloudy {
  background-image: url("img/Cloudy.png");
}
#theme-container.fifteenfifteen .square.mostlysunny {
  background-image: url("img/Sunny.png");
}
#theme-container.fifteenfifteen .square.partly_cloudy {
  background-image: url("img/Sunny.png");
}
#theme-container.fifteenfifteen .square.partlycloudy {
  background-image: url("img/Sunny.png");
}
#theme-container.fifteenfifteen .square.partlysunny {
  background-image: url("img/Sunny.png");
}
#theme-container.fifteenfifteen .square.rain {
  background-image: url("img/Rain.png");
}
#theme-container.fifteenfifteen .square.sleet {
  background-image: url("img/Cloudy.png");
}
#theme-container.fifteenfifteen .square.smoke {
  background-image: url("img/Cloudy.png");
}
#theme-container.fifteenfifteen .square.snow {
  background-image: url("img/Snow.png");
}
#theme-container.fifteenfifteen .square.storm {
  background-image: url("img/Rain.png");
}
#theme-container.fifteenfifteen .square.sunny {
  background-image: url("img/Sunny.png");
}
#theme-container.fifteenfifteen .square.thunderstorm {
  background-image: url("img/Rain.png");
}
#theme-container.fifteenfifteen .square.tstorms {
  background-image: url("img/Rain.png");
}
#theme-container.fifteenfifteen .square.nt_clear {
  background-image: url("img/Sunny.png");
}
#theme-container.fifteenfifteen .weather .board .square {
  background-repeat: no-repeat;
  background-position: center;
}
#theme-container.fifteenfifteen .automation.box .widgets-box h2 {
  background: rgba(255, 255, 255, 0.4);
  color: rgb(86, 86, 86);
}
#theme-container.fifteenfifteen .automation.box .widgets-box .zones {
  background: rgba(0, 0, 0, 0.54);
}
#theme-container.fifteenfifteen .automation.box .widgets-box .zones .ant-btn {
  background: rgb(125, 125, 125);
  border: none;
}
#theme-container.fifteenfifteen .automation.box .widgets-box .zones .ant-btn:hover {
  color: white;
}
#theme-container.fifteenfifteen .automation.box .widgets-box .zones .ant-btn:focus {
  color: white;
  box-shadow: none;
}
#theme-container.fifteenfifteen .automation.box .widgets-box .zones .widget.active:hover,
#theme-container.fifteenfifteen .automation.box .widgets-box .zones .widget.active {
  background: white;
  color: #fefefe;
}
#theme-container.fifteenfifteen .page-frame .text {
  font-size: 14px;
  font-weight: lighter;
}
#theme-container.fifteenfifteen .page-frame .frame {
  background: rgba(230, 231, 232, 0.1);
}
#theme-container.fifteenfifteen .page-frame .custom-form.frame {
  margin: unset;
  background: unset;
}
#theme-container.fifteenfifteen .page-frame .custom-form label {
  margin: 15px 5px;
  font-weight: normal;
}
#theme-container.fifteenfifteen .page-frame .custom-form label p {
  margin-right: 1rem;
}
#theme-container.fifteenfifteen .page-frame.automatedlockers .frame {
  padding: 0;
  background: transparent;
}
#theme-container.fifteenfifteen .page-frame.automatedlockers div.automatedlockers_form {
  margin: 1rem 0;
  background: rgba(230, 231, 232, 0.1);
  padding: 1rem;
  text-align: left;
}
#theme-container.fifteenfifteen .page-frame.automatedlockers div.automatedlockers_form input {
  width: calc(100% - 6em);
  margin: 1ex 1ex;
  background: rgba(255, 255, 255, 0.2);
  border: 0;
}
#theme-container.fifteenfifteen .page-frame.automatedlockers div.automatedlockers_form p {
  margin: 0.2rem 0;
}
#theme-container.fifteenfifteen .page-frame.automatedlockers .frame > .md-renderer {
  margin-top: 1rem;
}
#theme-container.fifteenfifteen .page-frame.buildingcontacts .frame {
  background: none;
  box-shadow: none;
  padding-left: 0;
}
#theme-container.fifteenfifteen .page-frame.buildingcontacts .frame .contact-item {
  background: black;
  width: 30rem;
  max-width: 90vw;
  padding: 1.8rem 2rem;
  margin-bottom: 2rem;
}
#theme-container.fifteenfifteen .page-frame.buildingcontacts .frame .contact-item h1 {
  text-align: unset;
  font-weight: 100;
  font-size: 1.6rem;
  margin: 0;
}
#theme-container.fifteenfifteen .page-frame.buildingcontacts .frame .contact-item p, #theme-container.fifteenfifteen .page-frame.buildingcontacts .frame .contact-item a {
  font-size: small;
}
#theme-container.fifteenfifteen .page-frame.buildingcontacts .frame .contact-item .position {
  height: 1em;
  min-height: 1em;
  margin-bottom: 2em;
  color: rgb(177.5, 177.5, 177.5);
}
#theme-container.fifteenfifteen .page-frame.fooddrink .item-decription {
  display: block;
  font-size: 85%;
}
#theme-container.fifteenfifteen .carvalet .board {
  background: none;
  max-width: 80vw;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  box-shadow: none;
}
#theme-container.fifteenfifteen .carvalet .board .board-item {
  display: flex;
  justify-content: center;
}
#theme-container.fifteenfifteen .carvalet .vehicle {
  justify-content: center;
  width: 12rem;
  height: 13.4rem;
  margin: 0;
  margin-right: 2rem;
}
#theme-container.fifteenfifteen .carvalet .vehicle .logo-bar {
  background: white;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
#theme-container.fifteenfifteen .carvalet .vehicle .logo-bar img {
  max-height: 90%;
  vertical-align: none;
}
#theme-container.fifteenfifteen .carvalet .vehicle .vehicle-info {
  display: flex;
  flex-flow: column;
  background: rgb(37, 37, 37);
  color: rgb(232, 232, 232);
  align-items: center;
  height: 8rem;
  padding-top: 1.2rem;
  padding-bottom: 0.5rem;
}
#theme-container.fifteenfifteen .carvalet .vehicle .vehicle-info .vehicle-tag, #theme-container.fifteenfifteen .carvalet .vehicle .vehicle-info .vehicle-make, #theme-container.fifteenfifteen .carvalet .vehicle .vehicle-info .vehicle-state {
  display: flex;
  justify-content: center;
  font-size: large;
  width: 12rem;
}
#theme-container.fifteenfifteen .carvalet .vehicle .vehicle-info .vehicle-state {
  text-transform: capitalize;
}
#theme-container.fifteenfifteen .carvalet .vehicle .vehicle-info .button-holder {
  width: calc(100% - 4rem);
}
#theme-container.fifteenfifteen .carvalet .vehicle .vehicle-info .button-holder button {
  background: #d0d2d3;
  color: #524f50;
  height: 2.5rem;
  line-height: 2.5rem;
  background: #fefefe;
  color: rgb(97, 97, 99);
  text-transform: uppercase;
}
#theme-container.fifteenfifteen .carvalet .vehicle .hidden-message {
  padding: 1ex;
  background: red;
}
#theme-container.fifteenfifteen .messages ul.page-ul {
  background: none;
  box-shadow: none;
}
#theme-container.fifteenfifteen .messages ul.page-ul li.readed {
  opacity: 0.8;
  background: rgba(230, 231, 232, 0.03);
}
#theme-container.fifteenfifteen .messages ul.page-ul li {
  padding: 1em;
  background: rgba(230, 231, 232, 0.1);
}
#theme-container.fifteenfifteen .messages ul.page-ul li p.body {
  text-align: justify;
}
#theme-container.fifteenfifteen .square {
  color: rgb(214, 214, 214);
  background: none;
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  width: calc((100vw - 10rem) / 3 - 2px);
  height: calc((100vw - 10rem) / 3 - 2px);
  min-width: 100px;
  min-height: 100px;
}
#theme-container.fifteenfifteen .square:hover {
  background-color: black;
}
#theme-container.fifteenfifteen .square .widget {
  font-size: 1.2rem;
  margin: 30% 30% 0 0;
  line-height: 2rem;
  text-align: center;
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  background: rgba(154, 154, 154, 0.9);
}
#theme-container.fifteenfifteen .square .title {
  text-transform: uppercase;
  font-size: x-small;
  font-weight: 100;
  grid-row-end: 3;
  grid-row-start: 5;
}
@media (max-width: 480px) {
  #theme-container.fifteenfifteen .square {
    margin: 2.35vw;
    width: 42.3vw;
    height: 42.3vw;
  }
}
@media (min-width: 480px) and (max-width: 680px) {
  #theme-container.fifteenfifteen .square {
    margin: 1.5666666667vw;
    width: 28.2vw;
    height: 28.2vw;
  }
}
@media (min-width: 680px) and (max-width: 900px) {
  #theme-container.fifteenfifteen .square {
    margin: 1.175vw;
    width: 21.15vw;
    height: 21.15vw;
  }
}
@media (min-width: 900px) and (max-width: 1250px) {
  #theme-container.fifteenfifteen .square {
    margin: 0.8vw;
    width: 14.4vw;
    height: 14.4vw;
  }
}
@media (min-width: 1250px) and (max-width: 1500px) {
  #theme-container.fifteenfifteen .square {
    margin: 0.8vw;
    width: 14.4vw;
    height: 14.4vw;
  }
}
@media (min-width: 1500px) and (max-width: 2000px) {
  #theme-container.fifteenfifteen .square {
    margin: 0.6666666667vw;
    width: 12vw;
    height: 12vw;
  }
}
@media (min-width: 2000px) {
  #theme-container.fifteenfifteen .square {
    margin: 0.6666666667vw;
    width: 12vw;
    height: 12vw;
  }
}
#theme-container.fifteenfifteen .square.concierge {
  background-image: url("img/buttons/Concierge.png");
}
#theme-container.fifteenfifteen .square.concierge247 {
  background-image: url("img/buttons/24-7-Concierge.png");
}
#theme-container.fifteenfifteen .square.porterservice {
  background-image: url("img/buttons/Porter-Service.png");
}
#theme-container.fifteenfifteen .square.maintenance {
  background-image: url("img/buttons/Maintenance.png");
}
#theme-container.fifteenfifteen .square.deliveries {
  background-image: url("img/buttons/Parcel-Receipt.png");
}
#theme-container.fifteenfifteen .square.automatedlockers {
  background-image: url("img/buttons/Automated-Lockers.png");
}
#theme-container.fifteenfifteen .square.parcelderiverylocker {
  background-image: url("img/buttons/Parcel-Receipt.png");
}
#theme-container.fifteenfifteen .square.valet {
  background-image: url("img/buttons/Valet.png");
}
#theme-container.fifteenfifteen .square.carvalet {
  background-image: url("img/buttons/Car-Valet.png");
}
#theme-container.fifteenfifteen .square.bikevalet {
  background-image: url("img/buttons/Bike-Valet.png");
}
#theme-container.fifteenfifteen .square.amenities {
  background-image: url("img/buttons/Amenities.png");
}
#theme-container.fifteenfifteen .square.observatorylounge {
  background-image: url("img/buttons/Observatory-Lounge.png");
}
#theme-container.fifteenfifteen .square.fitness {
  background-image: url("img/buttons/Fitness-Centre.png");
}
#theme-container.fifteenfifteen .square.yogaroom {
  background-image: url("img/buttons/Yoga-Room.png");
}
#theme-container.fifteenfifteen .square.buildingcontacts {
  background-image: url("img/buttons/Building-Contacts.png");
}
#theme-container.fifteenfifteen .square.messages {
  background-image: url("img/buttons/Messages.png");
}
#theme-container.fifteenfifteen .square.fooddrink {
  background-image: url("img/buttons/Food-And-Beverage.png");
}
#theme-container.fifteenfifteen .square.weather {
  background-image: url("img/buttons/Weather.png");
}
#theme-container.fifteenfifteen .square.otherservices {
  background-image: url("img/buttons/Other-Services.png");
}
#theme-container.fifteenfifteen .square.housecleaning {
  background-image: url("img/buttons/House-Cleaning.png");
}
#theme-container.fifteenfifteen .square.drycleaning {
  background-image: url("img/buttons/Dry-Cleaning.png");
}
#theme-container.fifteenfifteen .square.cardetailing {
  background-image: url("img/buttons/Car-Detailing.png");
}
#theme-container.fifteenfifteen .square.petcare {
  background-image: url("img/buttons/Pet-Care.png");
}
#theme-container.fifteenfifteen .square.personaltraining {
  background-image: url("img/buttons/Personal-Training.png");
}
#theme-container.fifteenfifteen .square.groceryshopping {
  background-image: url("img/buttons/Grocery-Shopping.png");
}
#theme-container.fifteenfifteen .square.catering {
  background-image: url("img/buttons/Catering-And-Event-Planning.png");
}
#theme-container.fifteenfifteen .square.yogaroom {
  background-image: url("img/buttons/Yoga-Room.png");
}
#theme-container.fifteenfifteen .page-ul .li-request {
  margin: 0.7rem 0;
  background: rgba(230, 231, 232, 0.1);
}
@media (max-width: 480px) {
  #theme-container.fifteenfifteen .page-ul .li-request {
    margin: 0.7rem 0;
  }
}
@media (min-width: 480px) and (max-width: 680px) {
  #theme-container.fifteenfifteen .page-ul .li-request {
    margin: 0.7rem 0;
  }
}
@media (min-width: 680px) and (max-width: 900px) {
  #theme-container.fifteenfifteen .page-ul .li-request {
    margin: 0.7rem 0;
  }
}
#theme-container.fifteenfifteen .page-ul .li-request.readed {
  color: rgb(97, 97, 99);
}
#theme-container.fifteenfifteen .page-frame {
  background: linear-gradient(to bottom left, rgb(13.3636363636, 11.8363636364, 12.2181818182) 0%, rgb(88, 89, 91) 100%);
}
#theme-container.fifteenfifteen .page-frame .back-link {
  padding-right: 2rem;
  padding-left: 2rem;
  margin-left: calc(10vw - 2rem);
  justify-content: unset;
}
#theme-container.fifteenfifteen .page-frame h1.title {
  padding: 0;
  padding-top: 2rem;
  margin-bottom: 0;
  margin-left: 10vw;
}
@media (max-width: 480px) {
  #theme-container.fifteenfifteen .page-frame h1.title {
    margin-left: 2.5vw;
  }
}
@media (min-width: 480px) and (max-width: 680px) {
  #theme-container.fifteenfifteen .page-frame h1.title {
    margin-left: 2.5vw;
  }
}
@media (min-width: 680px) and (max-width: 900px) {
  #theme-container.fifteenfifteen .page-frame h1.title {
    margin-left: 2.5vw;
  }
}
#theme-container.fifteenfifteen .page-frame .submit-button-wrapper {
  background-color: black;
  height: 7rem;
  display: flex;
  align-items: center;
  padding-left: 10vw;
}
#theme-container.fifteenfifteen .page-frame > div > div > .frame, #theme-container.fifteenfifteen .page-frame .page-ul {
  margin: 1rem 10vw;
}
@media (max-width: 480px) {
  #theme-container.fifteenfifteen .page-frame > div > div > .frame, #theme-container.fifteenfifteen .page-frame .page-ul {
    margin: 0.7rem 2.5vw;
  }
}
@media (min-width: 480px) and (max-width: 680px) {
  #theme-container.fifteenfifteen .page-frame > div > div > .frame, #theme-container.fifteenfifteen .page-frame .page-ul {
    margin: 0.7rem 2.5vw;
  }
}
@media (min-width: 680px) and (max-width: 900px) {
  #theme-container.fifteenfifteen .page-frame > div > div > .frame, #theme-container.fifteenfifteen .page-frame .page-ul {
    margin: 0.7rem 2.5vw;
  }
}
#theme-container.fifteenfifteen .page-frame .ant-row { /*FIXME: Quick hack to make form responsive, we should change this to some native ant solution */ }
@media (max-width: 480px) {
  #theme-container.fifteenfifteen .page-frame .ant-row {
    display: block;
  }
}
@media (min-width: 480px) and (max-width: 680px) {
  #theme-container.fifteenfifteen .page-frame .ant-row {
    display: block;
  }
}
@media (min-width: 680px) and (max-width: 900px) {
  #theme-container.fifteenfifteen .page-frame .ant-row {
    display: block;
  }
}
#theme-container.fifteenfifteen .page-frame.fooddrink .item-decription {
  display: block;
  font-size: 85%;
}
#theme-container.fifteenfifteen .board {
  margin: 3rem 10vw;
  border: none;
  background: rgba(230, 231, 232, 0.1);
  max-width: 80vw;
  box-shadow: none;
}
@media (max-width: 480px) {
  #theme-container.fifteenfifteen .board {
    margin: 0.7rem 3vw;
    max-width: 94vw;
  }
}
@media (min-width: 480px) and (max-width: 680px) {
  #theme-container.fifteenfifteen .board {
    margin: 0.7rem 3vw;
    max-width: 94vw;
  }
}
@media (min-width: 680px) and (max-width: 900px) {
  #theme-container.fifteenfifteen .board {
    margin: 0.7rem 3vw;
    max-width: 94vw;
  }
}
#theme-container.fifteenfifteen .board .board-item {
  align-items: center;
}
#theme-container.fifteenfifteen .weather .board .board-item:nth-of-type(6) {
  display: none;
}
#theme-container.fifteenfifteen button.ant-btn {
  border-radius: 0;
  background-color: rgb(97, 97, 99);
  padding: 0.5rem;
  height: 3rem;
  width: 7rem;
}
#theme-container.fifteenfifteen .ant-btn-primary {
  background-color: black;
  border-color: black;
}
#theme-container.fifteenfifteen .ant-switch-checked {
  background-color: black;
}
#theme-container.fifteenfifteen .ant-form-item-control {
  border-color: black;
}
#theme-container.fifteenfifteen a.page-description, #theme-container.fifteenfifteen a {
  text-decoration: none !important;
  color: #fefefe;
}
#theme-container.fifteenfifteen .ant-radio-button-wrapper,
#theme-container.fifteenfifteen .ant-time-picker-input,
#theme-container.fifteenfifteen .ant-select-selection--single,
#theme-container.fifteenfifteen .ant-input {
  background: rgba(230, 231, 232, 0.1);
  padding: 0.2rem 1rem;
  border: unset;
  border-radius: 0;
  height: unset;
}
#theme-container.fifteenfifteen .ant-row {
  display: flex;
  flex-flow: row;
  height: unset;
  margin: 1rem 0;
}
#theme-container.fifteenfifteen .ant-row .ant-form-item-label {
  flex-grow: 1;
  line-height: unset;
}
#theme-container.fifteenfifteen .ant-row .ant-form-item-label label {
  color: white;
}
#theme-container.fifteenfifteen .ant-row .ant-form-item-label label p {
  margin: 0;
}
#theme-container.fifteenfifteen .ant-row .ant-form-item-label label::after {
  content: none;
}
#theme-container.fifteenfifteen .ant-row .ant-form-item-control-wrapper {
  flex-grow: 3;
}
#theme-container.fifteenfifteen .ant-row .ant-form-item-control-wrapper .ant-form-item-control {
  line-height: 1rem;
}
#theme-container.fifteenfifteen .ant-form {
  color: unset;
}
#theme-container.fifteenfifteen .ant-radio-button-wrapper-checked,
#theme-container.fifteenfifteen .ant-radio-button-wrapper:focus,
#theme-container.fifteenfifteen .ant-radio-button-wrapper:hover {
  color: black;
  border-color: grey;
}
#theme-container.fifteenfifteen .ant-btn-primary:focus,
#theme-container.fifteenfifteen .ant-btn-primary:hover {
  color: white;
}
#theme-container.fifteenfifteen .ant-select-selection--single:hover,
#theme-container.fifteenfifteen .ant-select-selection:hover,
#theme-container.fifteenfifteen .ant-time-picker-input:hover,
#theme-container.fifteenfifteen .ant-input:hover {
  border-color: black;
}

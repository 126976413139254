#theme-container.red .dashboard {
  background: white;
}
#theme-container.red .dashboard .header {
  color: rgb(182, 127, 87);
}
#theme-container.red .dashboard .header a {
  color: rgb(182, 127, 87);
}
#theme-container.red .dashboard .board {
  padding-top: 0.7rem;
  border-color: rgb(182, 127, 87);
  border-top-width: 1px;
}
#theme-container.red .dashboard .hidden-menu {
  background: rgb(63, 42, 25);
}
#theme-container.red .dashboard .hidden-menu a {
  color: white;
}
#theme-container.red .dashboard .ant-slider .ant-slider-track {
  background: rgb(182, 127, 87);
}
#theme-container.red .dashboard .ant-slider .ant-slider-rail {
  background-color: rgb(224, 224, 224);
}
#theme-container.red .dashboard .ant-slider .ant-slider-handle {
  background: rgb(182, 127, 87);
  border-color: rgba(233, 233, 233, 0.61);
  box-shadow: none;
}
#theme-container.red .dashboard .ant-slider:focus .ant-slider-track,
#theme-container.red .dashboard .ant-slider:hover .ant-slider-track {
  background: rgb(182, 127, 87);
}
#theme-container.red .dashboard .ant-slider:focus .ant-slider-rail,
#theme-container.red .dashboard .ant-slider:hover .ant-slider-rail {
  background-color: rgb(224, 224, 224);
}
#theme-container.red .dashboard .ant-slider:focus .ant-slider-handle,
#theme-container.red .dashboard .ant-slider:hover .ant-slider-handle {
  background: rgb(182, 127, 87);
  border-color: white;
  box-shadow: none;
}
#theme-container.red .page-header h1 {
  font-size: 2.5rem;
  letter-spacing: 2px;
  color: rgb(99, 102, 106);
  font-weight: normal;
}
#theme-container.red .page-header .top {
  background: rgba(255, 255, 255, 0.57);
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}
#theme-container.red .page-header .top::before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  height: constant(safe-area-inset-top);
  height: env(safe-area-inset-top);
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2509803922);
}
#theme-container.red .page-header .top .back-link .icon, #theme-container.red .page-header .top .info-button .icon {
  color: rgb(182, 127, 87);
  font-size: 2rem;
}
#theme-container.red .page-header .top .info-button {
  padding-right: 1rem;
}
#theme-container.red .page-header h1.title {
  font-size: 4rem;
  letter-spacing: 1px;
  color: rgb(182, 127, 87);
  text-transform: capitalize;
  margin-left: 2rem;
  margin-bottom: 1rem;
  text-align: left;
  font-weight: normal;
  text-align: left;
}
#theme-container.red .vehicle {
  -webkit-box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}
#theme-container.red .vehicle.ready .vehicle-info {
  background: rgb(99, 102, 106);
  color: rgb(232, 232, 232);
}
#theme-container.red .vehicle.ready .logo-bar {
  background: rgb(241, 241, 241);
}
#theme-container.red .vehicle.requested .vehicle-info {
  background: rgb(153, 153, 153);
  color: rgb(232, 232, 232);
}
#theme-container.red .vehicle.requested .logo-bar {
  background: rgb(241, 241, 241);
}
#theme-container.red .vehicle.carwash_requested .vehicle-info {
  background: rgb(153, 153, 153);
  color: rgb(232, 232, 232);
}
#theme-container.red .vehicle.carwash_requested .logo-bar {
  background: rgb(241, 241, 241);
}
#theme-container.red .vehicle.parked .vehicle-info {
  background: rgba(203, 203, 203, 0.78);
  color: rgb(121, 121, 121);
}
#theme-container.red .vehicle.parked .logo-bar {
  background: rgb(241, 241, 241);
}
#theme-container.red .vehicle.washing .vehicle-info {
  background: rgb(221, 221, 221);
  color: #577c9a;
}
#theme-container.red .vehicle.washing .logo-bar {
  background: rgb(241, 241, 241);
}
#theme-container.red .vehicle.topark .vehicle-info {
  background: rgb(221, 221, 221);
  color: rgb(187, 120, 96);
}
#theme-container.red .vehicle.topark .logo-bar {
  background: rgb(241, 241, 241);
}
#theme-container.red .vehicle.out .vehicle-info {
  background: rgb(221, 221, 221);
  color: rgb(132, 132, 132);
}
#theme-container.red .vehicle.out .logo-bar {
  background: rgb(241, 241, 241);
}
#theme-container.red .calendar h1.item-list-title {
  color: rgb(182, 127, 87);
  border-color: rgb(182, 127, 87);
}
#theme-container.red .reservables .ant-fullcalendar-fullscreen .ant-fullcalendar-header {
  background: rgb(182, 127, 87);
  border: none;
}
#theme-container.red .reservables .ant-fullcalendar-fullscreen .ant-fullcalendar-calendar-body .ant-fullcalendar-column-header {
  background: rgb(182, 127, 87);
  border: none;
}
#theme-container.red .reservables .ant-fullcalendar-fullscreen .ant-select-selection--single {
  background: none;
}
#theme-container.red .reservables .ant-fullcalendar-fullscreen thead {
  border: none;
}
#theme-container.red .square.chance_of_rain {
  background-image: url("../../weather/wsymbol/wsymbol_0009_light_rain_showers.png");
}
#theme-container.red .square.chance_of_snow {
  background-image: url("../../weather/wsymbol/wsymbol_0011_light_snow_showers.png");
}
#theme-container.red .square.chance_of_storm {
  background-image: url("../../weather/wsymbol/wsymbol_0010_heavy_rain_showers.png");
}
#theme-container.red .square.chance_of_tstorm {
  background-image: url("../../weather/wsymbol/wsymbol_0016_thundery_showers.png");
}
#theme-container.red .square.chanceflurries {
  background-image: url("../../weather/wsymbol/wsymbol_0011_light_snow_showers.png");
}
#theme-container.red .square.chancerain {
  background-image: url("../../weather/wsymbol/wsymbol_0009_light_rain_showers.png");
}
#theme-container.red .square.chancesleet {
  background-image: url("../../weather/wsymbol/wsymbol_0013_sleet_showers.png");
}
#theme-container.red .square.chancesnow {
  background-image: url("../../weather/wsymbol/wsymbol_0011_light_snow_showers.png");
}
#theme-container.red .square.chancetstorms {
  background-image: url("../../weather/wsymbol/wsymbol_0016_thundery_showers.png");
}
#theme-container.red .square.clear {
  background-image: url("../../weather/wsymbol/wsymbol_0001_sunny.png");
}
#theme-container.red .square.cloudy {
  background-image: url("../../weather/wsymbol/wsymbol_0004_black_low_cloud.png");
}
#theme-container.red .square.dust {
  background-image: url("../../weather/wsymbol/wsymbol_0005_hazy_sun.png");
}
#theme-container.red .square.flurries {
  background-image: url("../../weather/wsymbol/wsymbol_0019_cloudy_with_light_snow.png");
}
#theme-container.red .square.fog {
  background-image: url("../../weather/wsymbol/wsymbol_0007_fog.png");
}
#theme-container.red .square.haze {
  background-image: url("../../weather/wsymbol/wsymbol_0005_hazy_sun.png");
}
#theme-container.red .square.hazy {
  background-image: url("../../weather/wsymbol/wsymbol_0005_hazy_sun.png");
}
#theme-container.red .square.icy {
  background-image: url("../../weather/wsymbol/wsymbol_0020_cloudy_with_heavy_snow.png");
}
#theme-container.red .square.mist {
  background-image: url("../../weather/wsymbol/wsymbol_0017_cloudy_with_light_rain.png");
}
#theme-container.red .square.mostly_cloudy {
  background-image: url("../../weather/wsymbol/wsymbol_0003_white_cloud.png");
}
#theme-container.red .square.mostly_sunny {
  background-image: url("../../weather/wsymbol/wsymbol_0002_sunny_intervals.png");
}
#theme-container.red .square.mostlycloudy {
  background-image: url("../../weather/wsymbol/wsymbol_0003_white_cloud.png");
}
#theme-container.red .square.mostlysunny {
  background-image: url("../../weather/wsymbol/wsymbol_0002_sunny_intervals.png");
}
#theme-container.red .square.partly_cloudy {
  background-image: url("../../weather/wsymbol/wsymbol_0002_sunny_intervals.png");
}
#theme-container.red .square.partlycloudy {
  background-image: url("../../weather/wsymbol/wsymbol_0002_sunny_intervals.png");
}
#theme-container.red .square.partlysunny {
  background-image: url("../../weather/wsymbol/wsymbol_0002_sunny_intervals.png");
}
#theme-container.red .square.rain {
  background-image: url("../../weather/wsymbol/wsymbol_0018_cloudy_with_heavy_rain.png");
}
#theme-container.red .square.sleet {
  background-image: url("../../weather/wsymbol/wsymbol_0021_cloudy_with_sleet.png");
}
#theme-container.red .square.smoke {
  background-image: url("../../weather/wsymbol/wsymbol_0007_fog.png");
}
#theme-container.red .square.snow {
  background-image: url("../../weather/wsymbol/wsymbol_0020_cloudy_with_heavy_snow.png");
}
#theme-container.red .square.storm {
  background-image: url("../../weather/wsymbol/wsymbol_0018_cloudy_with_heavy_rain.png");
}
#theme-container.red .square.sunny {
  background-image: url("../../weather/wsymbol/wsymbol_0001_sunny.png");
}
#theme-container.red .square.thunderstorm {
  background-image: url("../../weather/wsymbol/wsymbol_0024_thunderstorms.png");
}
#theme-container.red .square.tstorms {
  background-image: url("../../weather/wsymbol/wsymbol_0024_thunderstorms.png");
}
#theme-container.red .square.nt_clear {
  background-image: url("../../weather/wsymbol/wsymbol_0008_clear_sky_night.png");
}
#theme-container.red .square.weather {
  background-size: 6rem;
  background-repeat: no-repeat;
  background-position: center;
}
#theme-container.red .weather .board .square {
  background-size: 6rem;
  background-repeat: no-repeat;
  background-position: center;
}
#theme-container.red .automation.box .widgets-box h2 {
  background: none;
  color: rgb(182, 127, 87);
}
#theme-container.red .automation.box .widgets-box .zones {
  background: rgb(182, 127, 87);
}
#theme-container.red .automation.box .widgets-box .zones .ant-btn:hover {
  color: white;
  box-shadow: 0px 0px 12px white;
}
#theme-container.red .automation.box .widgets-box .zones .ant-btn:focus {
  box-shadow: none;
}
#theme-container.red .automation.box .widgets-box .widget.active:hover,
#theme-container.red .automation.box .widgets-box .widget.active {
  color: rgb(182, 127, 87);
}
#theme-container.red .square {
  color: white;
  background: rgb(182, 127, 87);
  border: none;
  box-shadow: 5px 5px 10px rgb(68, 68, 68);
}
#theme-container.red .square .title {
  justify-self: left;
  margin-left: 1rem;
}
#theme-container.red .square .widget {
  font-size: 3.7rem;
  font-weight: bold;
  font-style: italic;
}
@media (max-width: 480px) {
  #theme-container.red .square {
    margin: 3px;
    width: calc(33.3333333vw - 6px);
    height: calc(33.3333333vw - 6px);
  }
}
@media (min-width: 480px) and (max-width: 680px) {
  #theme-container.red .square {
    margin: 3px;
    width: calc(33.3333333vw - 6px);
    height: calc(33.3333333vw - 6px);
  }
}
@media (min-width: 680px) and (max-width: 900px) {
  #theme-container.red .square {
    margin: 3px;
    width: calc(25vw - 6px);
    height: calc(25vw - 6px);
  }
}
@media (min-width: 900px) and (max-width: 1250px) {
  #theme-container.red .square {
    margin: 5px;
    width: calc(20vw - 10px);
    height: calc(20vw - 10px);
  }
}
@media (min-width: 1250px) and (max-width: 1500px) {
  #theme-container.red .square {
    margin: 8px;
    width: calc(16.666666666vw - 16px);
    height: calc(16.666666666vw - 16px);
  }
}
@media (min-width: 1500px) and (max-width: 2000px) {
  #theme-container.red .square {
    margin: 12px;
    width: calc(14.285vw - 24px);
    height: calc(14.285vw - 24px);
  }
}
@media (min-width: 2000px) {
  #theme-container.red .square {
    margin: 12px;
    width: calc(12.5vw - 24px);
    height: calc(12.5vw - 24px);
  }
}
#theme-container.red .page-ul {
  list-style: none;
  padding: 0;
}
#theme-container.red .page-ul .li-request {
  position: relative;
  margin: 25px 0px;
  margin-top: 6px;
  background: none;
  border-left: 0.6rem solid rgb(182, 127, 87);
  padding: 0px 0px 0px 1rem;
}
@media (max-width: 480px) {
  #theme-container.red .page-ul .li-request {
    margin: 20px 0px;
  }
}
@media (min-width: 480px) and (max-width: 680px) {
  #theme-container.red .page-ul .li-request {
    margin: 20px 0px;
  }
}
@media (min-width: 680px) and (max-width: 900px) {
  #theme-container.red .page-ul .li-request {
    margin: 20px 0px;
  }
}
#theme-container.red .page-ul .li-request.readed {
  color: rgb(186, 186, 186);
  border-left: 0.6rem solid rgb(221, 209, 197);
}
#theme-container.red .page-ul .title {
  font-size: 2rem;
  line-height: 2rem;
  margin: 0px 0px 7px 0px;
  font-weight: normal;
}
#theme-container.red .page-ul .subtitle {
  font-size: 1.4rem;
}
#theme-container.red .page-ul p {
  margin-bottom: 0;
}
#theme-container.red .page-ul .body .li-request {
  border: none;
}
#theme-container.red .page-ul .date {
  position: absolute;
  top: 0;
  right: 0;
  margin: 1rem 11px 1rem 0px;
  font-size: 1.5rem;
  color: rgb(197, 197, 197);
  font-weight: lighter;
}
#theme-container.red .page-ul-enter {
  opacity: 0;
  max-height: 0;
  transition: max-height 0.5s ease-out, opacity 0.3s ease-out;
}
#theme-container.red .page-ul-enter.page-ul-enter-active {
  max-height: 500px;
  opacity: 1;
  transition: max-height 0.5s ease-in, opacity 0.3s ease-in;
}
#theme-container.red .page-frame {
  background: white;
}
#theme-container.red .ant-btn-primary {
  background-color: rgb(182, 127, 87);
  border-color: rgb(182, 127, 87);
}
#theme-container.red .ant-switch-checked {
  background-color: rgb(182, 127, 87);
}
#theme-container.red .ant-form-item-control {
  border-color: rgb(182, 127, 87);
}
#theme-container.red a {
  text-decoration: none;
  color: rgb(182, 127, 87);
}
#theme-container.red a:hover {
  color: rgb(255, 201, 161);
}
#theme-container.red .ant-btn:focus,
#theme-container.red .ant-btn:hover {
  color: rgb(182, 127, 87);
  border-color: rgb(182, 127, 87);
}
#theme-container.red .ant-btn-primary:focus,
#theme-container.red .ant-btn-primary:hover {
  color: rgb(255, 201, 161);
}
#theme-container.red .ant-select-selection--single:focus,
#theme-container.red .ant-select-selection:focus,
#theme-container.red .ant-time-picker-input:focus,
#theme-container.red .ant-input:focus {
  border-color: rgb(182, 127, 87);
  box-shadow: 0 0 0 2px rgba(184, 125, 88, 0.24);
}
#theme-container.red .ant-select-selection--single:hover,
#theme-container.red .ant-select-selection:hover,
#theme-container.red .ant-time-picker-input:hover,
#theme-container.red .ant-input:hover {
  border-color: rgb(182, 127, 87);
}

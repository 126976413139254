#theme-container.jadesignature {
  color: rgba(0, 0, 0, 0.7);
}
#theme-container.jadesignature .settings .mail .own-email {
  color: rgb(6, 61, 22);
}
#theme-container.jadesignature .dashboard {
  background: #fcf7e2 url("./img/day_hc.jpg");
  background-size: cover;
  background-position: top left;
}
#theme-container.jadesignature .dashboard .header {
  color: rgb(99, 102, 106);
  height: 12rem;
  background: unset;
}
#theme-container.jadesignature .dashboard .header a {
  color: rgb(99, 102, 106);
}
#theme-container.jadesignature .dashboard .header img {
  height: 5.8rem;
  width: unset;
  margin: 2ex 2ex;
}
#theme-container.jadesignature .dashboard .header .toggle-menu {
  padding: 1ex;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.2);
  color: #fafafa;
  margin: 0px;
  backdrop-filter: blur(1px) contrast(0.8);
  align-items: center;
  justify-content: center;
}
#theme-container.jadesignature .dashboard .header .toggle-menu .toggle-menu-button {
  margin-right: unset;
  align-items: center;
}
#theme-container.jadesignature .dashboard .board {
  border-color: rgb(0, 0, 0);
}
#theme-container.jadesignature .dashboard .board .logo-bar {
  background: rgba(255, 255, 255, 0.65);
}
#theme-container.jadesignature .dashboard .board .vehicle-info {
  color: #1e1414;
}
#theme-container.jadesignature .dashboard > div > .board {
  backdrop-filter: blur(10px) contrast(0.8);
  background: rgba(0, 0, 0, 0.2);
  padding: 1ex 0;
  border: unset;
}
#theme-container.jadesignature .dashboard .hidden-menu {
  background: rgba(0, 0, 0, 0.9294117647);
}
#theme-container.jadesignature .dashboard .hidden-menu a {
  color: white;
}
#theme-container.jadesignature .dashboard .ant-slider .ant-slider-track {
  background: rgb(69, 64, 64);
}
#theme-container.jadesignature .dashboard .ant-slider .ant-slider-rail {
  background-color: rgb(201, 201, 201);
}
#theme-container.jadesignature .dashboard .ant-slider .ant-slider-handle {
  background: rgb(69, 64, 64);
  border-color: rgba(233, 233, 233, 0.61);
  box-shadow: none;
}
#theme-container.jadesignature .dashboard .ant-slider:focus .ant-slider-track,
#theme-container.jadesignature .dashboard .ant-slider:hover .ant-slider-track {
  background: black;
}
#theme-container.jadesignature .dashboard .ant-slider:focus .ant-slider-rail,
#theme-container.jadesignature .dashboard .ant-slider:hover .ant-slider-rail {
  background-color: rgb(201, 201, 201);
}
#theme-container.jadesignature .dashboard .ant-slider:focus .ant-slider-handle,
#theme-container.jadesignature .dashboard .ant-slider:hover .ant-slider-handle {
  background: black;
  border-color: white;
  box-shadow: none;
}
#theme-container.jadesignature .page-header h1 {
  font-size: 2.5rem;
  letter-spacing: 2px;
  color: rgb(99, 102, 106);
  font-weight: normal;
}
#theme-container.jadesignature .page-header .top {
  color: white;
  background: rgba(0, 0, 0, 0.84);
}
#theme-container.jadesignature .page-header .top .back-link .icon {
  color: white;
  font-size: 2rem;
}
#theme-container.jadesignature .page-header h1.title {
  font-size: 4rem;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, 0.7);
  text-transform: capitalize;
  margin-left: 2rem;
  margin-bottom: 1rem;
  text-align: left;
  font-weight: normal;
  text-align: left;
}
#theme-container.jadesignature .reservables .ant-fullcalendar-fullscreen .ant-fullcalendar-header {
  background: rgb(43, 44, 45);
  border: none;
}
#theme-container.jadesignature .reservables .ant-fullcalendar-fullscreen .ant-fullcalendar-calendar-body .ant-fullcalendar-column-header {
  background: rgb(43, 44, 45);
  border: none;
}
#theme-container.jadesignature .reservables .ant-fullcalendar-fullscreen .ant-select-selection--single {
  background: none;
}
#theme-container.jadesignature .square.chance_of_rain {
  background-image: url("../../weather/minimal/sunny_rain.png");
}
#theme-container.jadesignature .square.chance_of_snow {
  background-image: url("../../weather/minimal/sunny_rain.png");
}
#theme-container.jadesignature .square.chance_of_storm {
  background-image: url("../../weather/minimal/sunny_rain.png");
}
#theme-container.jadesignature .square.chance_of_tstorm {
  background-image: url("../../weather/minimal/sunny_storm.png");
}
#theme-container.jadesignature .square.chanceflurries {
  background-image: url("../../weather/minimal/sunny_storm.png");
}
#theme-container.jadesignature .square.chancerain {
  background-image: url("../../weather/minimal/sunny_rain.png");
}
#theme-container.jadesignature .square.chancesleet {
  background-image: url("../../weather/minimal/sunny_rain.png");
}
#theme-container.jadesignature .square.chancesnow {
  background-image: url("../../weather/minimal/sunny_rain.png");
}
#theme-container.jadesignature .square.chancetstorms {
  background-image: url("../../weather/minimal/sunny_storm.png");
}
#theme-container.jadesignature .square.clear {
  background-image: url("../../weather/minimal/sunny.png");
}
#theme-container.jadesignature .square.cloudy {
  background-image: url("../../weather/minimal/cloudy.png");
}
#theme-container.jadesignature .square.dust {
  background-image: url("../../weather/minimal/sunny_cloudy.png");
}
#theme-container.jadesignature .square.flurries {
  background-image: url("../../weather/minimal/sunny_rain.png");
}
#theme-container.jadesignature .square.fog {
  background-image: url("../../weather/minimal/cloudy.png");
}
#theme-container.jadesignature .square.haze {
  background-image: url("../../weather/minimal/cloudy.png");
}
#theme-container.jadesignature .square.hazy {
  background-image: url("../../weather/minimal/cloudy.png");
}
#theme-container.jadesignature .square.icy {
  background-image: url("../../weather/minimal/cloudy.png");
}
#theme-container.jadesignature .square.mist {
  background-image: url("../../weather/minimal/cloudy.png");
}
#theme-container.jadesignature .square.mostly_cloudy {
  background-image: url("../../weather/minimal/cloudy.png");
}
#theme-container.jadesignature .square.mostly_sunny {
  background-image: url("../../weather/minimal/sunny_cloudy.png");
}
#theme-container.jadesignature .square.mostlycloudy {
  background-image: url("../../weather/minimal/cloudy.png");
}
#theme-container.jadesignature .square.mostlysunny {
  background-image: url("../../weather/minimal/sunny_cloudy.png");
}
#theme-container.jadesignature .square.partly_cloudy {
  background-image: url("../../weather/minimal/sunny_cloudy.png");
}
#theme-container.jadesignature .square.partlycloudy {
  background-image: url("../../weather/minimal/sunny_cloudy.png");
}
#theme-container.jadesignature .square.partlysunny {
  background-image: url("../../weather/minimal/sunny_cloudy.png");
}
#theme-container.jadesignature .square.rain {
  background-image: url("../../weather/minimal/rain.png");
}
#theme-container.jadesignature .square.sleet {
  background-image: url("../../weather/minimal/cloudy.png");
}
#theme-container.jadesignature .square.smoke {
  background-image: url("../../weather/minimal/cloudy.png");
}
#theme-container.jadesignature .square.snow {
  background-image: url("../../weather/minimal/rain.png");
}
#theme-container.jadesignature .square.storm {
  background-image: url("../../weather/minimal/storm.png");
}
#theme-container.jadesignature .square.sunny {
  background-image: url("../../weather/minimal/sunny.png");
}
#theme-container.jadesignature .square.thunderstorm {
  background-image: url("../../weather/minimal/storm.png");
}
#theme-container.jadesignature .square.tstorms {
  background-image: url("../../weather/minimal/storm.png");
}
#theme-container.jadesignature .square.nt_clear {
  background-image: url("../../weather/minimal/sunny.png");
}
#theme-container.jadesignature .weather .board .square {
  background-repeat: no-repeat;
  background-position: center;
}
#theme-container.jadesignature .automation.box .widgets-box h2 {
  background: rgba(255, 255, 255, 0.4);
  color: rgb(86, 86, 86);
}
#theme-container.jadesignature .automation.box .widgets-box .zones {
  background: rgba(0, 0, 0, 0.54);
}
#theme-container.jadesignature .automation.box .widgets-box .zones .ant-btn {
  background: rgb(125, 125, 125);
  border: none;
}
#theme-container.jadesignature .automation.box .widgets-box .zones .ant-btn:hover {
  color: white;
}
#theme-container.jadesignature .automation.box .widgets-box .zones .ant-btn:focus {
  color: white;
  box-shadow: none;
}
#theme-container.jadesignature .automation.box .widgets-box .zones .widget.active:hover,
#theme-container.jadesignature .automation.box .widgets-box .zones .widget.active {
  background: white;
  color: rgb(99, 102, 106);
}
#theme-container.jadesignature .square {
  color: rgb(214, 214, 214);
  background: rgba(0, 0, 0, 0.7);
  background-size: cover;
  border: rgb(0, 0, 0);
}
#theme-container.jadesignature .square .widget {
  font-size: 3rem;
  margin: 0.5rem 1rem 0 0;
  line-height: 3.5rem;
}
#theme-container.jadesignature .square.afternoon_tea {
  background-image: url("img/buttons/afternoon_tea.png");
}
#theme-container.jadesignature .square.amenities {
  background-image: url("img/buttons/amenities.png");
}
#theme-container.jadesignature .square.automation {
  background-image: url("img/buttons/automation.png");
}
#theme-container.jadesignature .square.barbecue {
  background-image: url("img/buttons/barbecue.png");
}
#theme-container.jadesignature .square.beach_bar_and_grill_menu {
  background-image: url("img/buttons/beach_bar_and_grill.png");
}
#theme-container.jadesignature .square.beach_chairs {
  background-image: url("img/buttons/beach_chairs.png");
}
#theme-container.jadesignature .square.Beverages {
  background-image: url("img/buttons/cafe.png");
}
#theme-container.jadesignature .square.business_center {
  background-image: url("img/buttons/business_center.png");
}
#theme-container.jadesignature .square.care_care_services {
  background-image: url("img/buttons/care_care_services.png");
}
#theme-container.jadesignature .square.catering {
  background-image: url("img/buttons/catering.png");
}
#theme-container.jadesignature .square.child_care {
  background-image: url("img/buttons/child_care.png");
}
#theme-container.jadesignature .square.childrens_room {
  background-image: url("img/buttons/childrens_room.png");
}
#theme-container.jadesignature .square.class_schedule {
  background-image: url("img/buttons/class_schedules.png");
}
#theme-container.jadesignature .square.club_room {
  background-image: url("img/buttons/club_room.png");
}
#theme-container.jadesignature .square.concierge {
  background-image: url("img/buttons/concierge.png");
}
#theme-container.jadesignature .square.concierge_requests {
  background-image: url("img/buttons/concierge.png");
}
#theme-container.jadesignature .square.construction {
  background-image: url("img/buttons/construction.png");
}
#theme-container.jadesignature .square.contact_us {
  background-image: url("img/buttons/contact_us.png");
}
#theme-container.jadesignature .square.contacts {
  background-image: url("img/buttons/contacts.png");
}
#theme-container.jadesignature .square.deliveries {
  background-image: url("img/buttons/deliveries.png");
}
#theme-container.jadesignature .square.dry_cleaning {
  background-image: url("img/buttons/dry_cleaning.png");
}
#theme-container.jadesignature .square.fitness_room {
  background-image: url("img/buttons/fitness_room.png");
}
#theme-container.jadesignature .square.floral_services {
  background-image: url("img/buttons/floral_services.png");
}
#theme-container.jadesignature .square.food {
  background-image: url("img/buttons/food.png");
}
#theme-container.jadesignature .square.food_breakfast {
  background-image: url("img/buttons/breakfast.png");
}
#theme-container.jadesignature .square.hours_of_operation_food {
  background-image: url("img/buttons/hours_of_operation.png");
}
#theme-container.jadesignature .square.hours_of_operation_wellness {
  background-image: url("img/buttons/hours_of_operation.png");
}
#theme-container.jadesignature .square.house_keeping {
  background-image: url("img/buttons/house_keeping.png");
}
#theme-container.jadesignature .square.juice_bar {
  background-image: url("img/buttons/juice_bar.png");
}
#theme-container.jadesignature .square.kayak {
  background-image: url("img/buttons/kayak.png");
}
#theme-container.jadesignature .square.languages {
  background-image: url("img/buttons/languages.png");
}
#theme-container.jadesignature .square.library {
  background-image: url("img/buttons/library.png");
}
#theme-container.jadesignature .square.live_feed {
  background-image: url("img/buttons/live_feed.png");
}
#theme-container.jadesignature .square.maintenance {
  background-image: url("img/buttons/maintenance.png");
}
#theme-container.jadesignature .square.manicure {
  background-image: url("img/buttons/manicure.png");
}
#theme-container.jadesignature .square.messages {
  background-image: url("img/buttons/messages.png");
}
#theme-container.jadesignature .square.night_life {
  background-image: url("img/buttons/night_life.png");
}
#theme-container.jadesignature .square.other_requests {
  background-image: url("img/buttons/other_requests.png");
}
#theme-container.jadesignature .square.paddle_boards {
  background-image: url("img/buttons/paddle_boards.png");
}
#theme-container.jadesignature .square.pantry_stocking {
  background-image: url("img/buttons/pantry_stocking.png");
}
#theme-container.jadesignature .square.pilates_reformer {
  background-image: url("img/buttons/pilates_reformer.png");
}
#theme-container.jadesignature .square.poker_lounge {
  background-image: url("img/buttons/poker_lounge.png");
}
#theme-container.jadesignature .square.pool_and_beach {
  background-image: url("img/buttons/pool_and_beach.png");
}
#theme-container.jadesignature .square.pool_cabanas {
  background-image: url("img/buttons/pool_cabanas.png");
}
#theme-container.jadesignature .square.request_car {
  background-image: url("img/buttons/request_car.png");
}
#theme-container.jadesignature .square.reduced_menu {
  background-image: url("img/buttons/food.png");
}
#theme-container.jadesignature .square.reservable_66 {
  background-image: url("img/buttons/club_room.png");
}
#theme-container.jadesignature .square.reservable_73 {
  background-image: url("img/buttons/class_schedules.png");
}
#theme-container.jadesignature .square.reservation {
  background-image: url("img/buttons/reservation.png");
}
#theme-container.jadesignature .square.resident_feedback {
  background-image: url("img/buttons/resident_feedback.png");
}
#theme-container.jadesignature .square.restaurant_reservation {
  background-image: url("img/buttons/restaurant_reservation.png");
}
#theme-container.jadesignature .square.settings {
  background-image: url("img/buttons/settings.png");
}
#theme-container.jadesignature .square.spa_menu {
  background-image: url("img/buttons/spa_menu.png");
}
#theme-container.jadesignature .square.spa_treatment_reservation {
  background-image: url("img/buttons/reservation.png");
}
#theme-container.jadesignature .square.Specials {
  background-image: url("img/buttons/catering.png");
}
#theme-container.jadesignature .square.sunset_sunrise {
  background-image: url("img/buttons/sunset_sunrise.png");
}
#theme-container.jadesignature .square.surf_conditions {
  background-image: url("img/buttons/surf_conditions.png");
}
#theme-container.jadesignature .square.teens_tech_lounge {
  background-image: url("img/buttons/teens_tech_lounge.png");
}
#theme-container.jadesignature .square.transportation {
  background-image: url("img/buttons/transportation.png");
}
#theme-container.jadesignature .square.valet {
  background-image: url("img/buttons/valet.png");
}
#theme-container.jadesignature .square.water_therapy_terrace {
  background-image: url("img/buttons/water_therapy_terrace.png");
}
#theme-container.jadesignature .square.wellness {
  background-image: url("img/buttons/wellness.png");
}
#theme-container.jadesignature .square.wine_bar {
  background-image: url("img/buttons/wine_bar.png");
}
#theme-container.jadesignature .square.work_orders {
  background-image: url("img/buttons/work_orders.png");
}
#theme-container.jadesignature .square.yachting {
  background-image: url("img/buttons/yachting.png");
}
#theme-container.jadesignature .page-ul .li-request {
  margin: 0.7rem 1.5rem;
  margin-top: 6px;
}
@media (max-width: 480px) {
  #theme-container.jadesignature .page-ul .li-request {
    margin: 0.7rem 0;
  }
}
@media (min-width: 480px) and (max-width: 680px) {
  #theme-container.jadesignature .page-ul .li-request {
    margin: 0.7rem 0;
  }
}
@media (min-width: 680px) and (max-width: 900px) {
  #theme-container.jadesignature .page-ul .li-request {
    margin: 0.7rem 0;
  }
}
#theme-container.jadesignature .page-ul .li-request.readed {
  color: rgb(195, 195, 195);
}
#theme-container.jadesignature .page-frame {
  background: #fcf7e2 url("img/day_blur.jpg");
  background-size: cover;
  background-position: top left;
}
#theme-container.jadesignature .ant-btn-primary {
  background-color: black;
  border-color: black;
}
#theme-container.jadesignature .ant-switch-checked {
  background-color: black;
}
#theme-container.jadesignature .ant-form-item-control {
  border-color: black;
}
#theme-container.jadesignature a {
  text-decoration: none;
  color: black;
}
#theme-container.jadesignature a:hover {
  color: white;
}
#theme-container.jadesignature .ant-radio-button-wrapper,
#theme-container.jadesignature .ant-time-picker-input,
#theme-container.jadesignature .ant-select-selection--single,
#theme-container.jadesignature .ant-input {
  background: rgba(255, 255, 255, 0.5);
}
#theme-container.jadesignature .ant-radio-button-wrapper-checked,
#theme-container.jadesignature .ant-radio-button-wrapper:focus,
#theme-container.jadesignature .ant-radio-button-wrapper:hover {
  color: black;
  border-color: grey;
}
#theme-container.jadesignature .ant-btn:focus,
#theme-container.jadesignature .ant-btn:hover,
#theme-container.jadesignature .ant-select-selection--single:focus,
#theme-container.jadesignature .ant-select-selection:focus,
#theme-container.jadesignature .ant-time-picker-input:focus,
#theme-container.jadesignature .ant-input:focus {
  color: black;
  border-color: grey;
  box-shadow: 0px 0px 10px 3px white;
}
#theme-container.jadesignature .ant-btn-primary:focus,
#theme-container.jadesignature .ant-btn-primary:hover {
  color: white;
}
#theme-container.jadesignature .ant-select-selection--single:hover,
#theme-container.jadesignature .ant-select-selection:hover,
#theme-container.jadesignature .ant-time-picker-input:hover,
#theme-container.jadesignature .ant-input:hover {
  border-color: black;
}

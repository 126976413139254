@font-face {
  font-family: "Gotham Book";
  src: url("./fonts/GothamBook.ttf");
}
@font-face {
  font-family: "Gotham Medium";
  src: url("./fonts/GothamMedium.ttf");
}
@font-face {
  font-family: "Gotham Bold";
  src: url("./fonts/GothamBold.ttf");
}
@font-face {
  font-family: "Gotham Light";
  src: url("./fonts/GothamLight.ttf");
}
@font-face {
  font-family: "Gotham Light Italic";
  src: url("./fonts/GothamLightItalic.ttf");
}
#theme-container.gryphonhouse {
  color: white;
  font-weight: lighter;
}
#theme-container.gryphonhouse .settings .mail .own-email {
  color: rgb(6, 61, 22);
}
#theme-container.gryphonhouse .dashboard {
  background: url("./img/BG_image.jpg");
  background-color: rgb(94, 81, 66);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
#theme-container.gryphonhouse .dashboard > .enabled-scrollable {
  height: calc(100vh - 10rem);
  overflow-x: hidden;
}
#theme-container.gryphonhouse .dashboard:before {
  content: "";
  position: absolute;
  bottom: 6rem;
  left: 0;
  border-color: gold;
  border-image: url("./img/golden_texture.jpg") 1 round;
  border-width: 1px;
  border-top-style: solid;
  border-bottom-style: solid;
  width: 90vw;
  height: 4px;
  margin-left: 5vw;
  border-image-slice: 1;
  clip-path: polygon(0% 0%, 0% 100%, calc(50% - 15rem / 2) 100%, calc(50% - 15rem / 2) 0, calc(100% - calc(50% - 15rem / 2)) 0, calc(100% - calc(50% - 15rem / 2)) 100%, calc(50% - 15rem / 2) 100%, calc(50% - 15rem / 2) 100%, 100% 100%, 100% 0%);
}
#theme-container.gryphonhouse .dashboard:after {
  content: "";
  position: absolute;
  bottom: 3.4rem;
  transform: translate(-50%, 0);
  left: 50%;
  background-image: url("./img/Footer_LOGO_x2.png");
  height: 5rem;
  width: 12rem;
  background-repeat: round;
}
#theme-container.gryphonhouse .dashboard .header {
  color: rgb(99, 102, 106);
  height: 14rem;
  background: unset;
}
#theme-container.gryphonhouse .dashboard .header a {
  color: rgb(99, 102, 106);
}
#theme-container.gryphonhouse .dashboard .header img {
  height: 8rem;
}
#theme-container.gryphonhouse .dashboard .header .toggle-menu-label, #theme-container.gryphonhouse .dashboard .header .toggle-menu-button {
  color: #605418;
}
#theme-container.gryphonhouse .dashboard .hidden-menu {
  border-image: url("./img/golden_texture.jpg") 1 round;
  border-color: gold;
  background-color: gold;
  border-width: 1px;
  background: url("./img/golden_texture.jpg");
  background-repeat: round;
  border-top-style: solid;
  border-bottom-style: solid;
}
#theme-container.gryphonhouse .dashboard .hidden-menu.hidden {
  height: 4px;
  max-height: 4px;
  max-width: 90vw;
  margin-left: 5vw;
  background: unset;
  box-shadow: unset;
}
#theme-container.gryphonhouse .dashboard .hidden-menu a {
  color: white;
}
#theme-container.gryphonhouse .dashboard .board {
  margin-top: 0;
  margin-bottom: 0;
}
#theme-container.gryphonhouse .dashboard .ant-slider .ant-slider-track {
  background: rgb(69, 64, 64);
}
#theme-container.gryphonhouse .dashboard .ant-slider .ant-slider-rail {
  background-color: rgb(201, 201, 201);
}
#theme-container.gryphonhouse .dashboard .ant-slider .ant-slider-handle {
  background: rgb(69, 64, 64);
  border-color: rgba(233, 233, 233, 0.61);
  box-shadow: none;
}
#theme-container.gryphonhouse .dashboard .ant-slider:focus .ant-slider-track,
#theme-container.gryphonhouse .dashboard .ant-slider:hover .ant-slider-track {
  background: black;
}
#theme-container.gryphonhouse .dashboard .ant-slider:focus .ant-slider-rail,
#theme-container.gryphonhouse .dashboard .ant-slider:hover .ant-slider-rail {
  background-color: rgb(201, 201, 201);
}
#theme-container.gryphonhouse .dashboard .ant-slider:focus .ant-slider-handle,
#theme-container.gryphonhouse .dashboard .ant-slider:hover .ant-slider-handle {
  background: black;
  border-color: white;
  box-shadow: none;
}
#theme-container.gryphonhouse .frame {
  padding: 0;
}
#theme-container.gryphonhouse .page-header h1 {
  text-transform: uppercase;
  font-size: 2.5rem;
  letter-spacing: 2px;
  color: rgb(99, 102, 106);
  font-weight: normal;
}
#theme-container.gryphonhouse .page-header .top {
  color: white;
  height: 7rem;
}
#theme-container.gryphonhouse .page-header .top .back-link .icon {
  color: white;
  font-size: 2rem;
}
#theme-container.gryphonhouse .page-header h1.title {
  font-size: 2rem;
  letter-spacing: 1px;
  color: #fefefe;
  text-transform: uppercase;
  padding: 4rem;
  text-align: left;
  font-weight: 600;
}
#theme-container.gryphonhouse .page-description a {
  text-decoration: underline;
}
#theme-container.gryphonhouse .reservables .page-ul {
  background: rgba(20, 20, 20, 0.8);
}
#theme-container.gryphonhouse .reservables .date-picker {
  padding-left: 7rem;
}
#theme-container.gryphonhouse .reservables .date-picker .calendar {
  display: block;
  width: 30%;
  height: 22rem;
  overflow-y: scroll;
  margin: unset;
  margin-left: 7rem;
}
#theme-container.gryphonhouse .reservables .date-picker .calendar .cell-wrapper {
  margin: 0;
  width: unset;
  background: rgba(20, 20, 20, 0.8);
}
#theme-container.gryphonhouse .reservables .date-picker .calendar .cell-wrapper .date div span:nth-child(3) {
  display: none;
}
#theme-container.gryphonhouse .reservables .date-picker .calendar .cell-wrapper .calendar-cell {
  padding: 0;
  background: unset !important;
  font-size: 2rem;
}
#theme-container.gryphonhouse .reservables .date-picker .calendar .cell-wrapper .calendar-cell span {
  font-size: inherit;
}
#theme-container.gryphonhouse .reservables .date-picker .calendar .cell-wrapper .calendar-cell.forme {
  color: black;
  z-index: 1;
}
#theme-container.gryphonhouse .reservables .date-picker .calendar .cell-wrapper .calendar-cell.forme .date .from::before {
  content: "";
  position: absolute;
  width: 60%;
  height: 2.2rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 2rem;
  background: rgb(250, 250, 250);
  z-index: -1;
}
#theme-container.gryphonhouse .reservables .date-picker .calendar .cell-wrapper .calendar-cell.closed {
  color: black;
}
#theme-container.gryphonhouse .reservables .date-picker .date-slot {
  background-color: gray;
}
#theme-container.gryphonhouse .reservables .date-picker .date-slot a {
  color: black;
}
#theme-container.gryphonhouse .reservables .date-picker .ant-fullcalendar-cell.ant-fullcalendar-next-month-btn-day .date-slot,
#theme-container.gryphonhouse .reservables .date-picker .ant-fullcalendar-cell.ant-fullcalendar-disabled-cell .date-slot {
  opacity: 1;
  background: black;
}
#theme-container.gryphonhouse .reservables .date-picker .ant-fullcalendar-cell.ant-fullcalendar-next-month-btn-day .date-slot a,
#theme-container.gryphonhouse .reservables .date-picker .ant-fullcalendar-cell.ant-fullcalendar-disabled-cell .date-slot a {
  color: gray;
}
#theme-container.gryphonhouse .reservables .date-picker tr {
  padding: 0;
}
#theme-container.gryphonhouse .reservables .date-picker thead {
  background: rgb(51, 59, 66);
  font-size: 1rem;
}
#theme-container.gryphonhouse .reservables .date-picker .ant-fullcalendar-fullscreen {
  max-width: 25rem;
  margin: unset;
}
#theme-container.gryphonhouse .reservables .date-picker .ant-fullcalendar-fullscreen .ant-fullcalendar-header {
  background: rgb(46, 48, 51);
  border: none;
}
#theme-container.gryphonhouse .reservables .date-picker .ant-fullcalendar-fullscreen .ant-fullcalendar-header > div {
  color: white;
}
#theme-container.gryphonhouse .reservables .date-picker .ant-fullcalendar-fullscreen .ant-fullcalendar-calendar-body {
  background-color: black;
}
#theme-container.gryphonhouse .reservables .date-picker .ant-fullcalendar-fullscreen .ant-fullcalendar-calendar-body .ant-fullcalendar-column-header {
  background: rgb(43, 44, 45);
  border: none;
}
#theme-container.gryphonhouse .reservables .date-picker .ant-fullcalendar-fullscreen .ant-select-selection--single {
  background: none;
}
#theme-container.gryphonhouse .reservables .date-picker .ant-fullcalendar-fullscreen .ant-fullcalendar-cell {
  padding: 0;
}
#theme-container.gryphonhouse .square.chance_of_rain {
  background-image: url("img/Rain.png");
}
#theme-container.gryphonhouse .square.chance_of_snow {
  background-image: url("img/Snow.png");
}
#theme-container.gryphonhouse .square.chance_of_storm {
  background-image: url("img/Rain.png");
}
#theme-container.gryphonhouse .square.chance_of_tstorm {
  background-image: url("img/Rain.png");
}
#theme-container.gryphonhouse .square.chanceflurries {
  background-image: url("img/Rain.png");
}
#theme-container.gryphonhouse .square.chancerain {
  background-image: url("img/Rain.png");
}
#theme-container.gryphonhouse .square.chancesleet {
  background-image: url("img/Rain.png");
}
#theme-container.gryphonhouse .square.chancesnow {
  background-image: url("img/Snow.png");
}
#theme-container.gryphonhouse .square.chancetstorms {
  background-image: url("img/Rain.png");
}
#theme-container.gryphonhouse .square.clear {
  background-image: url("img/Sunny.png");
}
#theme-container.gryphonhouse .square.cloudy {
  background-image: url("img/Cloudy.png");
}
#theme-container.gryphonhouse .square.dust {
  background-image: url("img/Sunny.png");
}
#theme-container.gryphonhouse .square.flurries {
  background-image: url("img/Rain.png");
}
#theme-container.gryphonhouse .square.fog {
  background-image: url("img/Cloudy.png");
}
#theme-container.gryphonhouse .square.haze {
  background-image: url("img/Cloudy.png");
}
#theme-container.gryphonhouse .square.hazy {
  background-image: url("img/Cloudy.png");
}
#theme-container.gryphonhouse .square.icy {
  background-image: url("img/Cloudy.png");
}
#theme-container.gryphonhouse .square.mist {
  background-image: url("img/Cloudy.png");
}
#theme-container.gryphonhouse .square.mostly_cloudy {
  background-image: url("img/Cloudy.png");
}
#theme-container.gryphonhouse .square.mostly_sunny {
  background-image: url("img/Sunny.png");
}
#theme-container.gryphonhouse .square.mostlycloudy {
  background-image: url("img/Cloudy.png");
}
#theme-container.gryphonhouse .square.mostlysunny {
  background-image: url("img/Sunny.png");
}
#theme-container.gryphonhouse .square.partly_cloudy {
  background-image: url("img/Sunny.png");
}
#theme-container.gryphonhouse .square.partlycloudy {
  background-image: url("img/Sunny.png");
}
#theme-container.gryphonhouse .square.partlysunny {
  background-image: url("img/Sunny.png");
}
#theme-container.gryphonhouse .square.rain {
  background-image: url("img/Rain.png");
}
#theme-container.gryphonhouse .square.sleet {
  background-image: url("img/Cloudy.png");
}
#theme-container.gryphonhouse .square.smoke {
  background-image: url("img/Cloudy.png");
}
#theme-container.gryphonhouse .square.snow {
  background-image: url("img/Snow.png");
}
#theme-container.gryphonhouse .square.storm {
  background-image: url("img/Rain.png");
}
#theme-container.gryphonhouse .square.sunny {
  background-image: url("img/Sunny.png");
}
#theme-container.gryphonhouse .square.thunderstorm {
  background-image: url("img/Rain.png");
}
#theme-container.gryphonhouse .square.tstorms {
  background-image: url("img/Rain.png");
}
#theme-container.gryphonhouse .square.nt_clear {
  background-image: url("img/Sunny.png");
}
#theme-container.gryphonhouse .weather .board {
  width: 90vw;
  max-width: unset;
  margin: 3rem 5vw;
}
#theme-container.gryphonhouse .weather .board .square {
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgba(1, 1, 1, 0.5);
  border-color: gold;
  border-image: url("./img/golden_texture.jpg") 33% round;
  border-style: solid;
  border-width: 2px;
  width: calc(90vw / 5 - 2px * 2 - 1rem * 2);
  height: calc(90vw / 5 - 2px * 2 - 1rem * 2);
  margin: 0 1rem;
}
#theme-container.gryphonhouse .weather .board .square:nth-of-type(1) {
  margin-left: 0;
}
#theme-container.gryphonhouse .weather .weather-footer {
  transform: translate(-50%);
  margin-left: 50%;
}
#theme-container.gryphonhouse .automation.box .widgets-box h2 {
  background: rgba(255, 255, 255, 0.4);
  color: rgb(86, 86, 86);
}
#theme-container.gryphonhouse .automation.box .widgets-box .zones {
  background: rgba(0, 0, 0, 0.54);
}
#theme-container.gryphonhouse .automation.box .widgets-box .zones .ant-btn {
  background: rgb(125, 125, 125);
  border: none;
}
#theme-container.gryphonhouse .automation.box .widgets-box .zones .ant-btn:hover {
  color: white;
}
#theme-container.gryphonhouse .automation.box .widgets-box .zones .ant-btn:focus {
  color: white;
  box-shadow: none;
}
#theme-container.gryphonhouse .automation.box .widgets-box .zones .widget.active:hover,
#theme-container.gryphonhouse .automation.box .widgets-box .zones .widget.active {
  background: white;
  color: #fefefe;
}
#theme-container.gryphonhouse .page-frame .text {
  font-size: 14px;
  font-weight: lighter;
}
#theme-container.gryphonhouse .page-frame .frame {
  background: rgba(54, 68, 79, 0);
}
#theme-container.gryphonhouse .page-frame .custom-form.frame {
  margin: unset;
  background: unset;
}
#theme-container.gryphonhouse .page-frame .custom-form label {
  margin: 15px 5px;
  font-weight: normal;
  margin-left: 0;
}
#theme-container.gryphonhouse .page-frame .custom-form label p {
  margin-right: 1rem;
}
#theme-container.gryphonhouse .page-frame.automatedlockers .frame {
  padding: 0;
  background: transparent;
}
#theme-container.gryphonhouse .page-frame.automatedlockers div.automatedlockers_form {
  margin: 1rem 0;
  background: rgba(54, 68, 79, 0);
  padding: 1rem;
  text-align: left;
}
#theme-container.gryphonhouse .page-frame.automatedlockers div.automatedlockers_form input {
  width: calc(100% - 6em);
  margin: 1ex 1ex;
  background: rgba(255, 255, 255, 0.2);
  border: 0;
}
#theme-container.gryphonhouse .page-frame.automatedlockers div.automatedlockers_form p {
  margin: 0.2rem 0;
}
#theme-container.gryphonhouse .page-frame.automatedlockers .frame > .md-renderer {
  margin-top: 1rem;
}
#theme-container.gryphonhouse .page-frame.buildingcontacts .frame {
  background: none;
  box-shadow: none;
  padding-left: 0;
}
#theme-container.gryphonhouse .page-frame.buildingcontacts .frame .contact-item {
  background: black;
  width: 30rem;
  max-width: 96vw;
  padding: 1.8rem 2rem;
  margin-bottom: 2rem;
}
#theme-container.gryphonhouse .page-frame.buildingcontacts .frame .contact-item h1 {
  text-align: unset;
  font-weight: 100;
  font-size: 1.6rem;
  margin: 0;
}
#theme-container.gryphonhouse .page-frame.buildingcontacts .frame .contact-item p, #theme-container.gryphonhouse .page-frame.buildingcontacts .frame .contact-item a {
  font-size: small;
}
#theme-container.gryphonhouse .page-frame.buildingcontacts .frame .contact-item .position {
  height: 1em;
  min-height: 1em;
  margin-bottom: 2em;
  color: rgb(177.5, 177.5, 177.5);
}
#theme-container.gryphonhouse .page-frame.fooddrink .item-decription {
  display: block;
  font-size: 85%;
}
#theme-container.gryphonhouse input.ant-input {
  background-color: rgba(7, 7, 7, 0.5);
  color: #fefefe;
}
#theme-container.gryphonhouse .carvalet .board {
  background: none;
  max-width: 80vw;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  box-shadow: none;
}
#theme-container.gryphonhouse .carvalet .board .board-item {
  display: flex;
  justify-content: center;
}
#theme-container.gryphonhouse .carvalet .vehicle {
  justify-content: center;
  width: 12rem;
  height: 13.4rem;
  margin: 0;
  margin-right: 2rem;
}
#theme-container.gryphonhouse .carvalet .vehicle .logo-bar {
  background: white;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
#theme-container.gryphonhouse .carvalet .vehicle .logo-bar img {
  max-height: 90%;
  vertical-align: none;
}
#theme-container.gryphonhouse .carvalet .vehicle .vehicle-info {
  display: flex;
  flex-flow: column;
  background: rgb(37, 37, 37);
  color: rgb(232, 232, 232);
  align-items: center;
  height: 8rem;
  padding-top: 1.2rem;
  padding-bottom: 0.5rem;
}
#theme-container.gryphonhouse .carvalet .vehicle .vehicle-info .vehicle-tag, #theme-container.gryphonhouse .carvalet .vehicle .vehicle-info .vehicle-make, #theme-container.gryphonhouse .carvalet .vehicle .vehicle-info .vehicle-state {
  display: flex;
  justify-content: center;
  font-size: large;
  width: 12rem;
}
#theme-container.gryphonhouse .carvalet .vehicle .vehicle-info .vehicle-state {
  text-transform: capitalize;
}
#theme-container.gryphonhouse .carvalet .vehicle .vehicle-info .button-holder {
  width: calc(100% - 4rem);
}
#theme-container.gryphonhouse .carvalet .vehicle .vehicle-info .button-holder button {
  background: #d0d2d3;
  color: #524f50;
  height: 2.5rem;
  line-height: 2.5rem;
  background: #fefefe;
  color: rgb(97, 97, 99);
  text-transform: uppercase;
}
#theme-container.gryphonhouse .carvalet .vehicle .hidden-message {
  padding: 1ex;
  background: red;
}
#theme-container.gryphonhouse .messages ul.page-ul {
  background: rgba(0, 0, 0, 0.5);
  box-shadow: none;
}
#theme-container.gryphonhouse .messages ul.page-ul li.readed {
  opacity: 0.8;
  background: rgba(54, 68, 79, 0.03);
}
#theme-container.gryphonhouse .messages ul.page-ul li {
  padding: 1em;
  background: rgba(54, 68, 79, 0);
}
#theme-container.gryphonhouse .messages ul.page-ul li p.body {
  text-align: justify;
}
#theme-container.gryphonhouse .square {
  color: rgb(214, 214, 214);
  background: none;
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  width: calc((100vw - 10rem) / 3 - 2px);
  height: calc((100vw - 10rem) / 3 - 2px);
  min-width: 100px;
  min-height: 100px;
}
#theme-container.gryphonhouse .square:hover {
  background-color: rgba(1, 1, 1, 0.5);
  border-color: gold;
  border-image: url("./img/golden_texture.jpg") 33% round;
  border-style: solid;
  border-width: 2px;
}
#theme-container.gryphonhouse .square .widget {
  font-size: 1.2rem;
  margin: 30% 30% 0 0;
  line-height: 2rem;
  text-align: center;
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  background: rgba(154, 154, 154, 0.9);
}
#theme-container.gryphonhouse .square .title {
  text-transform: uppercase;
  font-size: x-small;
  font-weight: 100;
  grid-row-end: 3;
  grid-row-start: 5;
}
@media (max-width: 480px) {
  #theme-container.gryphonhouse .square {
    width: 47vw;
    height: 47vw;
    margin: 0;
  }
}
@media (min-width: 480px) and (max-width: 680px) {
  #theme-container.gryphonhouse .square {
    width: 31.3333333333vw;
    height: 31.3333333333vw;
    margin: 0;
  }
}
@media (min-width: 680px) and (max-width: 900px) {
  #theme-container.gryphonhouse .square {
    width: 23.5vw;
    height: 23.5vw;
    margin: 0;
  }
}
@media (min-width: 900px) and (max-width: 1250px) {
  #theme-container.gryphonhouse .square {
    width: 18.4vw;
    height: 18.4vw;
    margin: 0;
  }
}
@media (min-width: 1250px) and (max-width: 1500px) {
  #theme-container.gryphonhouse .square {
    width: 18.4vw;
    height: 18.4vw;
    margin: 0;
  }
}
@media (min-width: 1500px) and (max-width: 2000px) {
  #theme-container.gryphonhouse .square {
    width: 15.3333333333vw;
    height: 15.3333333333vw;
    margin: 0;
  }
}
@media (min-width: 2000px) {
  #theme-container.gryphonhouse .square {
    width: 15.3333333333vw;
    height: 15.3333333333vw;
    margin: 0;
  }
}
#theme-container.gryphonhouse .square.concierge {
  background-image: url("img/buttons/Concierge.png");
}
#theme-container.gryphonhouse .square.concierge247 {
  background-image: url("img/buttons/Icon__Concierge_24-7_concierge_messaging_x2.png");
  background-size: 50%;
  background-position: 35%;
}
#theme-container.gryphonhouse .square.building_manager {
  background-image: url("img/buttons/Icon__Concierge_Building_Manager_x2.png");
}
#theme-container.gryphonhouse .square.porterservice {
  background-image: url("img/buttons/Porter-Service.png");
}
#theme-container.gryphonhouse .square.maintenance {
  background-image: url("img/buttons/Maintenance.png");
}
#theme-container.gryphonhouse .square.deliveries {
  background-image: url("img/buttons/Parcel-Receipt.png");
}
#theme-container.gryphonhouse .square.automatedlockers {
  background-image: url("img/buttons/Automated-Lockers.png");
}
#theme-container.gryphonhouse .square.parcelderiverylocker {
  background-image: url("img/buttons/Parcel-Receipt.png");
}
#theme-container.gryphonhouse .square.valet {
  background-image: url("img/buttons/Valet.png");
}
#theme-container.gryphonhouse .square.carvalet {
  background-image: url("img/buttons/Car-Valet.png");
}
#theme-container.gryphonhouse .square.bikevalet {
  background-image: url("img/buttons/Bike-Valet.png");
}
#theme-container.gryphonhouse .square.amenities {
  background-image: url("img/buttons/Amenities.png");
}
#theme-container.gryphonhouse .square.observatorylounge {
  background-image: url("img/buttons/Observatory-Lounge.png");
}
#theme-container.gryphonhouse .square.fitness {
  background-image: url("img/buttons/Fitness-Centre.png");
}
#theme-container.gryphonhouse .square.yogaroom {
  background-image: url("img/buttons/Yoga-Room.png");
}
#theme-container.gryphonhouse .square.buildingcontacts {
  background-image: url("img/buttons/Building-Contacts.png");
}
#theme-container.gryphonhouse .square.messages {
  background-image: url("img/buttons/Icon_Messages_x2.png");
}
#theme-container.gryphonhouse .square.fooddrink {
  background-image: url("img/buttons/Food-And-Beverage.png");
}
#theme-container.gryphonhouse .square.weather {
  background-image: url("img/buttons/Weather.png");
}
#theme-container.gryphonhouse .square.otherservices {
  background-image: url("img/buttons/Other-Services.png");
}
#theme-container.gryphonhouse .square.housecleaning {
  background-image: url("img/buttons/House-Cleaning.png");
}
#theme-container.gryphonhouse .square.drycleaning {
  background-image: url("img/buttons/Dry-Cleaning.png");
}
#theme-container.gryphonhouse .square.cardetailing {
  background-image: url("img/buttons/Car-Detailing.png");
}
#theme-container.gryphonhouse .square.petcare {
  background-image: url("img/buttons/Pet-Care.png");
}
#theme-container.gryphonhouse .square.personaltraining {
  background-image: url("img/buttons/Personal-Training.png");
}
#theme-container.gryphonhouse .square.catering {
  background-image: url("img/buttons/Catering-And-Event-Planning.png");
}
#theme-container.gryphonhouse .square.yogaroom {
  background-image: url("img/buttons/Yoga-Room.png");
}
#theme-container.gryphonhouse .square.groceryshopping {
  background-image: url("img/buttons/Icon_Gryphon_Retail_x2.png");
}
#theme-container.gryphonhouse .square.florist {
  background-image: url("img/buttons/Icon_Gryphon_Retail_Florist_x2.png");
}
#theme-container.gryphonhouse .square.bakery {
  background-image: url("img/buttons/Icon_Gryphon_Retail_Bakery_x2.png");
}
#theme-container.gryphonhouse .square.coffee {
  background-image: url("img/buttons/Icon_Gryphon_Retail_Cafe_x2.png");
}
#theme-container.gryphonhouse .square.dentist {
  background-image: url("img/buttons/Icon_Gryphon_Retail_Dentist_x2.png");
}
#theme-container.gryphonhouse .square.royal_curator {
  background-image: url("img/buttons/Icon_Royal_Curator_x2.png");
}
#theme-container.gryphonhouse .square.cleaning {
  background-image: url("img/buttons/Icon_Royal_Curator_Cleaning_services_x2.png");
}
#theme-container.gryphonhouse .square.convenience {
  background-image: url("img/buttons/Icon_Royal_Curator_Convenience_services_x2.png");
}
#theme-container.gryphonhouse .square.beauty {
  background-image: url("img/buttons/Icon_Royal_Curator_Beauty_x2.png");
}
#theme-container.gryphonhouse .square.health {
  background-image: url("img/buttons/Icon_Royal_Curator_Health_Programs_x2.png");
}
#theme-container.gryphonhouse .square.interior_design {
  background-image: url("img/buttons/Icon_Royal_Curator_Interior_Design_x2.png");
}
#theme-container.gryphonhouse .square.moving_arrangements {
  background-image: url("img/buttons/Icon_Royal_Curator_Moving_Arrangements_x2.png");
}
#theme-container.gryphonhouse .square.restaurants {
  background-image: url("img/buttons/Icon_Royal_Curator_Restaurants_x2.png");
}
#theme-container.gryphonhouse .square.special_occasion {
  background-image: url("img/buttons/Icon_Royal_Curator_Special_Occasion_x2.png");
}
#theme-container.gryphonhouse .square.guest_parking_reservation {
  background-image: url("img/buttons/Icon__Concierge_Guest_Parking_x2.png");
}
#theme-container.gryphonhouse .square.reservable_64 {
  background-image: url("img/buttons/Icon__Concierge_Guest_Parking_x2.png");
}
#theme-container.gryphonhouse .square.reservable_59 {
  background-image: url("img/buttons/Icon_Amenities_Fitness_Centre_x2.png");
}
#theme-container.gryphonhouse .square.reservable_63 {
  background-image: url("img/buttons/Icon_Amenities_Infrared_Sauna_x2.png");
}
#theme-container.gryphonhouse .square.reservable_60 {
  background-image: url("img/buttons/Icon_Amenities_Massage_Room_x2.png");
}
#theme-container.gryphonhouse .square.reservable_61 {
  background-image: url("img/buttons/Icon_Amenities_Executive_Meeting_room_x2.png");
}
#theme-container.gryphonhouse .square.reservable_62 {
  background-image: url("img/buttons/Icon_Amenities_Wine_bar_x2.png");
}
#theme-container.gryphonhouse .page-ul .li-request {
  margin: 0.7rem 0;
  background: rgba(54, 68, 79, 0);
}
@media (max-width: 480px) {
  #theme-container.gryphonhouse .page-ul .li-request {
    margin: 0.7rem 0;
  }
}
@media (min-width: 480px) and (max-width: 680px) {
  #theme-container.gryphonhouse .page-ul .li-request {
    margin: 0.7rem 0;
  }
}
@media (min-width: 680px) and (max-width: 900px) {
  #theme-container.gryphonhouse .page-ul .li-request {
    margin: 0.7rem 0;
  }
}
#theme-container.gryphonhouse .page-ul .li-request.readed {
  color: rgb(97, 97, 99);
}
#theme-container.gryphonhouse .page-frame {
  background: url("./img/BG_image.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  right: 0;
}
#theme-container.gryphonhouse .page-frame .back-link {
  padding-right: 2rem;
  padding-left: 2rem;
  margin-left: calc(4vw - 2rem);
  justify-content: unset;
}
#theme-container.gryphonhouse .page-frame h1.title {
  padding: 0;
  padding-bottom: 2rem;
  margin-top: 2rem;
  margin-bottom: 0;
  margin-left: 4vw;
}
@media (max-width: 480px) {
  #theme-container.gryphonhouse .page-frame h1.title {
    margin-left: 1vw;
  }
}
@media (min-width: 480px) and (max-width: 680px) {
  #theme-container.gryphonhouse .page-frame h1.title {
    margin-left: 1vw;
  }
}
@media (min-width: 680px) and (max-width: 900px) {
  #theme-container.gryphonhouse .page-frame h1.title {
    margin-left: 1vw;
  }
}
#theme-container.gryphonhouse .page-frame .submit-button-wrapper {
  background-color: transparent;
  height: 7rem;
  display: flex;
  align-items: center;
  padding-left: 4vw;
}
#theme-container.gryphonhouse .page-frame .submit-button-wrapper:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-color: gold;
  border-image: url("./img/golden_texture.jpg") 1 round;
  border-width: 1px;
  border-top-style: solid;
  border-bottom-style: solid;
  width: 90vw;
  height: 4px;
  margin-left: 4vw;
  border-image-slice: 1;
}
#theme-container.gryphonhouse .page-frame .submit-button-wrapper button {
  background-color: gold;
  background-image: url("./img/golden_texture.jpg");
  background-repeat: round;
  border-width: 0;
  border-radius: 3.5rem;
  width: 12rem;
  color: #605418;
  font-weight: bold;
}
#theme-container.gryphonhouse .page-frame > div > div > .frame, #theme-container.gryphonhouse .page-frame .page-ul {
  margin: 1rem 4vw;
}
@media (max-width: 480px) {
  #theme-container.gryphonhouse .page-frame > div > div > .frame, #theme-container.gryphonhouse .page-frame .page-ul {
    margin: 0.7rem 1vw;
  }
}
@media (min-width: 480px) and (max-width: 680px) {
  #theme-container.gryphonhouse .page-frame > div > div > .frame, #theme-container.gryphonhouse .page-frame .page-ul {
    margin: 0.7rem 1vw;
  }
}
@media (min-width: 680px) and (max-width: 900px) {
  #theme-container.gryphonhouse .page-frame > div > div > .frame, #theme-container.gryphonhouse .page-frame .page-ul {
    margin: 0.7rem 1vw;
  }
}
#theme-container.gryphonhouse .page-frame .ant-row { /*FIXME: Quick hack to make form responsive, we should change this to some native ant solution */ }
@media (max-width: 480px) {
  #theme-container.gryphonhouse .page-frame .ant-row {
    display: block;
  }
}
@media (min-width: 480px) and (max-width: 680px) {
  #theme-container.gryphonhouse .page-frame .ant-row {
    display: block;
  }
}
@media (min-width: 680px) and (max-width: 900px) {
  #theme-container.gryphonhouse .page-frame .ant-row {
    display: block;
  }
}
#theme-container.gryphonhouse .page-frame.fooddrink .item-decription {
  display: block;
  font-size: 85%;
}
#theme-container.gryphonhouse .page-frame .page-header .top {
  background: unset;
}
#theme-container.gryphonhouse .page-frame .page-header .top::after {
  content: "";
  width: 90vw;
  height: 4px;
  position: absolute;
  bottom: 0;
  margin-left: 4vw;
  border-color: gold;
  border-image: url("./img/golden_texture.jpg") 100% round;
  border-image-slice: 1;
  border-top-style: solid;
  border-bottom-style: solid;
  border-width: 1px;
}
#theme-container.gryphonhouse .page-frame .enabled-scrollable {
  height: calc(100vh - 7rem);
  margin-top: 7rem;
}
#theme-container.gryphonhouse .board {
  margin: 3rem 4vw;
  border: none;
  background: unset;
  max-width: 92vw;
  box-shadow: none;
}
@media (max-width: 480px) {
  #theme-container.gryphonhouse .board {
    margin: 0.7rem 3vw;
    max-width: 94vw;
  }
}
@media (min-width: 480px) and (max-width: 680px) {
  #theme-container.gryphonhouse .board {
    margin: 0.7rem 3vw;
    max-width: 94vw;
  }
}
@media (min-width: 680px) and (max-width: 900px) {
  #theme-container.gryphonhouse .board {
    margin: 0.7rem 3vw;
    max-width: 94vw;
  }
}
#theme-container.gryphonhouse .board .board-item {
  align-items: center;
}
#theme-container.gryphonhouse .weather .board .board-item:nth-of-type(6) {
  display: none;
}
#theme-container.gryphonhouse button.ant-btn {
  border-radius: 0;
  background-color: rgb(97, 97, 99);
  padding: 0.5rem;
  height: 3rem;
  width: 7rem;
}
#theme-container.gryphonhouse .ant-btn-primary {
  background-color: black;
  border-color: black;
}
#theme-container.gryphonhouse .ant-switch-checked {
  background-color: black;
}
#theme-container.gryphonhouse .ant-form-item-control {
  border-color: black;
}
#theme-container.gryphonhouse a.page-description, #theme-container.gryphonhouse a {
  text-decoration: none !important;
  color: #fefefe;
}
#theme-container.gryphonhouse .ant-radio-button-wrapper,
#theme-container.gryphonhouse .ant-time-picker-input,
#theme-container.gryphonhouse .ant-select-selection--single,
#theme-container.gryphonhouse .ant-input {
  background: rgba(54, 68, 79, 0);
  padding: 0.2rem 1rem;
  border: unset;
  border-radius: 0;
  height: unset;
}
#theme-container.gryphonhouse .ant-row {
  height: unset;
  margin: 1rem 0;
}
#theme-container.gryphonhouse .ant-row .ant-form-item-label {
  flex-grow: 1;
  line-height: unset;
}
#theme-container.gryphonhouse .ant-row .ant-form-item-label label {
  color: white;
}
#theme-container.gryphonhouse .ant-row .ant-form-item-label label p {
  margin: 0;
}
#theme-container.gryphonhouse .ant-row .ant-form-item-label label::after {
  content: none;
}
#theme-container.gryphonhouse .ant-row .ant-form-item-control-wrapper {
  flex-grow: 3;
}
#theme-container.gryphonhouse .ant-row .ant-form-item-control-wrapper .ant-form-item-control {
  line-height: 1rem;
}
#theme-container.gryphonhouse .ant-form {
  color: unset;
}
#theme-container.gryphonhouse .ant-radio-button-wrapper-checked,
#theme-container.gryphonhouse .ant-radio-button-wrapper:focus,
#theme-container.gryphonhouse .ant-radio-button-wrapper:hover {
  color: black;
  border-color: grey;
}
#theme-container.gryphonhouse .ant-btn-primary:focus,
#theme-container.gryphonhouse .ant-btn-primary:hover {
  color: white;
}
#theme-container.gryphonhouse .ant-select-selection--single:hover,
#theme-container.gryphonhouse .ant-select-selection:hover,
#theme-container.gryphonhouse .ant-time-picker-input:hover,
#theme-container.gryphonhouse .ant-input:hover {
  border-color: black;
}

@charset "UTF-8";
@font-face {
  font-family: "Black";
  src: url("fonts/DINNextLTPro-Black.otf") format("opentype");
}
@font-face {
  font-family: "BlackCondensed";
  src: url("fonts/DINNextLTPro-BlackCondensed.otf") format("opentype");
}
@font-face {
  font-family: "BlackItalic";
  src: url("fonts/DINNextLTPro-BlackItalic.otf") format("opentype");
}
@font-face {
  font-family: "Bold";
  src: url("fonts/DINNextLTPro-Bold.otf") format("opentype");
}
@font-face {
  font-family: "BoldCondensed";
  src: url("fonts/DINNextLTPro-BoldCondensed.otf") format("opentype");
}
@font-face {
  font-family: "BoldItalic";
  src: url("fonts/DINNextLTPro-BoldItalic.otf") format("opentype");
}
@font-face {
  font-family: "Condensed";
  src: url("fonts/DINNextLTPro-Condensed.otf") format("opentype");
}
@font-face {
  font-family: "Heavy";
  src: url("fonts/DINNextLTPro-Heavy.otf") format("opentype");
}
@font-face {
  font-family: "HeavyCondensed";
  src: url("fonts/DINNextLTPro-HeavyCondensed.otf") format("opentype");
}
@font-face {
  font-family: "HeavyItalic";
  src: url("fonts/DINNextLTPro-HeavyItalic.otf") format("opentype");
}
@font-face {
  font-family: "Italic";
  src: url("fonts/DINNextLTPro-Italic.otf") format("opentype");
}
@font-face {
  font-family: "Light";
  src: url("fonts/DINNextLTPro-Light.otf") format("opentype");
}
@font-face {
  font-family: "LightCondensed";
  src: url("fonts/DINNextLTPro-LightCondensed.otf") format("opentype");
}
@font-face {
  font-family: "LightItalic";
  src: url("fonts/DINNextLTPro-LightItalic.otf") format("opentype");
}
@font-face {
  font-family: "Medium";
  src: url("fonts/DINNextLTPro-Medium.otf") format("opentype");
}
@font-face {
  font-family: "MediumCondensed";
  src: url("fonts/DINNextLTPro-MediumCond.otf") format("opentype");
}
@font-face {
  font-family: "MediumItalic";
  src: url("fonts/DINNextLTPro-MediumItalic.otf") format("opentype");
}
@font-face {
  font-family: "Regular";
  src: url("fonts/DINNextLTPro-Regular.otf") format("opentype");
}
@font-face {
  font-family: "UltraLight";
  src: url("fonts/DINNextLTPro-UltraLight.otf") format("opentype");
}
@font-face {
  font-family: "UltraLightCondensed";
  src: url("fonts/DINNextLTPro-UltraLightCond.otf") format("opentype");
}
@font-face {
  font-family: "UltraLightItalic";
  src: url("fonts/DINNextLTPro-UltraLightIt.otf") format("opentype");
}
#theme-container.joy {
  font-family: "Medium";
}
#theme-container.joy body {
  background-color: black;
}
#theme-container.joy .dashboard {
  flex-grow: 1;
  flex-shrink: 1;
  background-color: black;
}
#theme-container.joy .dashboard .header {
  background-color: black;
  color: white;
  height: 40vh;
}
#theme-container.joy .dashboard .header a {
  color: white;
}
#theme-container.joy .dashboard .header img {
  height: 35vh;
  width: 80%;
}
#theme-container.joy .dashboard .header .toggle-menu {
  justify-content: space-between;
  width: auto;
  left: calc(25px + 13px);
  right: calc(25px + 13px);
}
#theme-container.joy .dashboard .header .toggle-menu h3 {
  color: white;
  display: block;
  margin: 0;
}
#theme-container.joy .dashboard .hidden-menu a {
  color: white;
}
#theme-container.joy .dashboard .ant-slider .ant-slider-track {
  background: rgb(69, 64, 64);
}
#theme-container.joy .dashboard .ant-slider .ant-slider-rail {
  background-color: white;
}
#theme-container.joy .dashboard .ant-slider .ant-slider-handle {
  background: black;
  border-color: rgba(233, 233, 233, 0.61);
  box-shadow: none;
}
#theme-container.joy .dashboard .board {
  border-color: rgb(0, 0, 0);
  padding: 1ex 25px;
}
#theme-container.joy .dashboard .board .logo-bar {
  background: rgba(255, 255, 255, 0.65);
}
#theme-container.joy .dashboard .board .vehicle-info {
  color: #1e1414;
}
#theme-container.joy .dashboard > div > .board {
  backdrop-filter: blur(10px) contrast(0.7);
  background: rgba(0, 0, 0, 0.2);
  border: unset;
}
#theme-container.joy .dashboard .ant-slider:focus .ant-slider-track,
#theme-container.joy .dashboard .ant-slider:hover .ant-slider-track {
  background: black;
}
#theme-container.joy .dashboard .ant-slider:focus .ant-slider-rail,
#theme-container.joy .dashboard .ant-slider:hover .ant-slider-rail {
  background-color: white;
}
#theme-container.joy .dashboard .ant-slider:focus .ant-slider-handle,
#theme-container.joy .dashboard .ant-slider:hover .ant-slider-handle {
  background: black;
  border-color: white;
  box-shadow: none;
}
#theme-container.joy .automation .box .zones, #theme-container.joy .automation .box h2, #theme-container.joy .automation .box p, #theme-container.joy .automation .box .ant-result-title {
  background-color: black;
  border: 1px solid white;
  border-radius: 10px;
  color: white;
}
#theme-container.joy .li-request .content-item .ant-collapse {
  background-color: black;
  color: white;
  border-radius: 10px;
}
#theme-container.joy .li-request .content-item .ant-collapse svg {
  color: black;
}
#theme-container.joy .li-request .content-item .ant-collapse .ant-collapse-header, #theme-container.joy .li-request .content-item .ant-collapse .ant-collapse-arrow {
  color: white !important;
}
#theme-container.joy .li-request .content-item .ant-collapse .ant-collapse-header svg, #theme-container.joy .li-request .content-item .ant-collapse .ant-collapse-arrow svg {
  color: white;
}
#theme-container.joy .li-request .content-item .ant-collapse .ant-collapse-content > .ant-collapse-content-box, #theme-container.joy .li-request .content-item .ant-collapse .item {
  background-color: black;
  color: white;
  border: 1px solid white;
}
#theme-container.joy .md-renderer a {
  color: white;
  text-decoration: underline;
}
#theme-container.joy .contact-box {
  background-color: black;
  border: 1px solid white;
  border-radius: 10px;
}
#theme-container.joy .contact-box img {
  box-shadow: 0 0 5px 0 white;
  border-radius: 10px;
  padding: 0;
}
#theme-container.joy .contact-box .contact-item {
  background-color: black;
}
#theme-container.joy .expenses .container-box {
  background-color: black;
  border: 1px solid white;
  color: white;
}
#theme-container.joy .expenses .container-box h4, #theme-container.joy .expenses .container-box h2, #theme-container.joy .expenses .container-box p, #theme-container.joy .expenses .container-box .ant-result-title {
  color: white;
}
#theme-container.joy .expenses .container-box .container-amount-help {
  color: white;
  background-color: black;
}
#theme-container.joy .expenses .container-box .container-balance, #theme-container.joy .expenses .container-box .items-payment-methods, #theme-container.joy .expenses .container-box .ant-card {
  background-color: black;
}
#theme-container.joy .expenses .container-box .container-balance span, #theme-container.joy .expenses .container-box .items-payment-methods span, #theme-container.joy .expenses .container-box .ant-card span {
  color: white;
}
#theme-container.joy .expenses .container-box .container-balance .left-arrow, #theme-container.joy .expenses .container-box .container-balance .right-arrow, #theme-container.joy .expenses .container-box .items-payment-methods .left-arrow, #theme-container.joy .expenses .container-box .items-payment-methods .right-arrow, #theme-container.joy .expenses .container-box .ant-card .left-arrow, #theme-container.joy .expenses .container-box .ant-card .right-arrow {
  background-color: #7e7373;
}
#theme-container.joy .expenses .container-transactions {
  margin: 1rem;
}
#theme-container.joy .expenses .container-transactions span {
  color: white;
}
#theme-container.joy .body-alert {
  align-items: baseline;
}
#theme-container.joy .body-alert .container-emergency {
  color: white;
  background-color: black;
  border: 1px solid white;
  border-radius: 10px;
}
#theme-container.joy .body-alert .container-emergency .help-title, #theme-container.joy .body-alert .container-emergency .help-text, #theme-container.joy .body-alert .container-emergency .emergency-state {
  color: white;
  background-color: black;
}
#theme-container.joy .square.weather {
  background-size: 80%;
}
#theme-container.joy .weather .board .board-item .square {
  background-size: 80%;
}
#theme-container.joy .square.chance_of_rain,
#theme-container.joy .square.chancerain,
#theme-container.joy .square.rain {
  background-image: url("img/buttons/weather/lluvia.svg");
}
#theme-container.joy .square.chance_of_tstorm,
#theme-container.joy .square.chance_of_storm,
#theme-container.joy .square.chancetstorms,
#theme-container.joy .square.thunderstorm,
#theme-container.joy .square.tstorms,
#theme-container.joy .square.storm {
  background-image: url("img/buttons/weather/tormenta_electrica.svg");
}
#theme-container.joy .square.chance_of_snow,
#theme-container.joy .square.chancesnow,
#theme-container.joy .square.chanceflurries,
#theme-container.joy .square.flurries,
#theme-container.joy .square.chancesleet,
#theme-container.joy .square.sleet,
#theme-container.joy .square.icy,
#theme-container.joy .square.snow,
#theme-container.joy .square.cloudy,
#theme-container.joy .square.mostly_cloudy,
#theme-container.joy .square.mostlycloudy,
#theme-container.joy .square.partly_cloudy,
#theme-container.joy .square.partlycloudy,
#theme-container.joy .square.fog,
#theme-container.joy .square.mist,
#theme-container.joy .square.haze,
#theme-container.joy .square.hazy,
#theme-container.joy .square.dust,
#theme-container.joy .square.smoke {
  background-image: url("img/buttons/weather/nublado.svg");
}
#theme-container.joy .square.clear,
#theme-container.joy .square.sunny,
#theme-container.joy .square.mostly_sunny,
#theme-container.joy .square.mostlysunny,
#theme-container.joy .square.partlysunny {
  background-image: url("img/buttons/weather/soleado.svg");
}
#theme-container.joy .square.partlysunny,
#theme-container.joy .square.mostlysunny {
  background-image: url("img/buttons/weather/nublado_y_sol.svg");
}
#theme-container.joy .square.nt_clear {
  background-image: url("img/buttons/weather/luna.svg");
}
#theme-container.joy .square.nt_clear.starry {
  background-image: url("img/buttons/weather/estrellas.svg");
}
#theme-container.joy .square.cloudy.night {
  background-image: url("img/buttons/weather/luna_y_nubes.svg");
}
#theme-container.joy .square.mostly_cloudy.night {
  background-image: url("img/buttons/weather/lunas_y_estrellas.svg");
}
#theme-container.joy .page-frame {
  background-color: black;
  color: white;
  /* Botón */
}
#theme-container.joy .page-frame h1, #theme-container.joy .page-frame h2, #theme-container.joy .page-frame h3, #theme-container.joy .page-frame h4, #theme-container.joy .page-frame h5, #theme-container.joy .page-frame h6 {
  color: white;
}
#theme-container.joy .page-frame .frame {
  background-color: black;
  color: white;
}
#theme-container.joy .page-frame p {
  color: white;
}
#theme-container.joy .page-frame .page-ul {
  padding: 1rem;
  background-color: black;
  color: white;
  border: 1px solid grey;
  margin: 1rem;
  border-radius: 10px;
}
#theme-container.joy .page-frame .page-ul .li-request {
  position: relative;
  margin: 6px 0 25px;
  backdrop-filter: blur(10px) contrast(0.7);
  background: rgba(0, 0, 0, 0.2);
  border-left: 0.6rem solid #3f3f3f;
  border-top: 1px dotted;
  padding: 1rem;
}
#theme-container.joy .page-frame .page-ul .li-request li {
  color: white;
}
#theme-container.joy .page-frame .page-ul .li-request li strong {
  color: white;
}
#theme-container.joy .page-frame .page-ul h1 {
  color: black;
}
#theme-container.joy .page-frame .page-ul .frame {
  background-color: black;
  color: black;
}
#theme-container.joy .page-frame .page-ul p {
  color: white;
}
#theme-container.joy .page-frame .page-ul p.subtitle {
  font-size: 1.5rem;
}
#theme-container.joy .page-frame .square p {
  color: black;
}
#theme-container.joy .page-frame label {
  color: white;
}
#theme-container.joy .page-frame input {
  color: black;
}
#theme-container.joy .page-frame .calendar-cell {
  color: black;
}
#theme-container.joy .page-frame .ant-radio-button-wrapper {
  color: white;
  background-color: black;
  transition: all 0.3s ease;
  border: 0.1px dashed darkgrey;
}
#theme-container.joy .page-frame .ant-radio-button-wrapper-checked {
  background-color: black;
  color: white;
  border: 1px solid #1890ff;
  box-shadow: 0 1px 10px 2px grey !important;
}
#theme-container.joy .page-frame .ant-radio-button-wrapper:active {
  background-color: #d9d9d9;
}
#theme-container.joy .page-frame .ant-btn {
  color: black !important;
  background-color: white !important;
  transition: all 0.3s ease;
  border-radius: 10px !important;
}
#theme-container.joy .page-frame .ant-btn:hover {
  background-color: #f0f0f0;
  border-color: black;
}
#theme-container.joy .page-frame .ant-btn:active {
  background-color: #d9d9d9;
  border-color: black;
}
#theme-container.joy .page-frame .ant-btn:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
#theme-container.joy .page-frame .submit-button-wrapper {
  background-color: black;
  position: relative;
  text-align: center;
  width: 100%;
}
#theme-container.joy .page-frame .ant-input-group-addon {
  background-color: black;
}
#theme-container.joy .page-frame .ant-select-arrow {
  color: black;
}
#theme-container.joy .page-frame .ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link, #theme-container.joy .page-frame .ant-pagination.mini .ant-pagination-next .ant-pagination-item-link {
  color: white;
}
#theme-container.joy .page-frame .ant-pagination-item a {
  color: white;
}
#theme-container.joy .page-frame .ant-pagination-item-active {
  background-color: black;
  box-shadow: 0 1px 5px 1px grey !important;
}
#theme-container.joy .page-frame .banner {
  display: block;
  position: relative;
  height: calc(30vh - 4rem);
  min-height: 12rem;
  margin-top: 4rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
#theme-container.joy .page-frame .banner .banner-background {
  object-fit: cover;
  width: 100%;
  height: 100%;
  background-image: url("./img/banners/mi_perfil.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
#theme-container.joy .page-frame .banner .banner-logo {
  object-fit: contain;
  min-height: 50%;
  max-height: 65%;
  width: 50%;
  position: absolute;
  background-image: url("./img/logos/white_black_border.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
#theme-container.joy .page-frame.settings .banner-background {
  background-image: url("./img/banners/configuracion.jpg");
}
#theme-container.joy .page-frame.messages .banner-background {
  background-image: url("./img/banners/mensajes.jpg");
}
#theme-container.joy .page-frame.messages .banner-logo {
  background-image: url("./img/logos/black_white_border.png");
}
#theme-container.joy .page-frame.deliveries .banner-background {
  background-image: url("./img/banners/entregas.jpg");
}
#theme-container.joy .page-frame.directory .banner-background {
  background-image: url("./img/banners/contactos.jpg");
}
#theme-container.joy .page-frame.expenses .banner-background {
  background-image: url("./img/banners/admin.jpg");
}
#theme-container.joy .page-frame.automation .banner-background {
  background-image: url("./img/banners/domotica.jpg");
}
#theme-container.joy .page-frame.weather .banner-background {
  background-image: url("./img/banners/clima.jpg");
}
#theme-container.joy .page-frame.amenities .banner-background {
  background-image: url("./img/banners/amenities.jpg");
}
#theme-container.joy .page-frame.concierge .banner-background {
  background-image: url("./img/banners/conserje.jpg");
}
#theme-container.joy .page-frame.guest_auth .banner-background {
  background-image: url("./img/banners/invitacion.jpg");
}
#theme-container.joy .page-frame.servicios .banner-background {
  background-image: url("./img/banners/servicios_profesionales.jpg");
}
#theme-container.joy .page-frame.maintenance .banner-background {
  background-image: url("./img/banners/mantenimiento.jpg");
}
#theme-container.joy .page-frame.others .banner-background {
  background-image: url("./img/banners/consultas.jpg");
}
#theme-container.joy .page-frame.emergency .banner-background {
  background-image: url("./img/banners/sos.jpg");
}
#theme-container.joy .page-header h1 {
  margin-top: 2rem;
}
#theme-container.joy .settings .notifications, #theme-container.joy .settings .change-password {
  backdrop-filter: blur(10px) contrast(0.7);
  padding: 1rem;
  border-radius: 10px;
}
#theme-container.joy .square {
  color: #000000;
  background: #D9D9D9;
  background-size: 30%;
  background-position: center 30%;
  background-repeat: no-repeat;
  border: rgb(0, 0, 0);
  border-radius: 15px;
  font-size: 1rem;
  border: 1px solid white;
  box-shadow: 0 1px 10px 2px grey !important;
}
#theme-container.joy .square .widget {
  font-size: 2rem;
  justify-self: end;
  margin: 0.5rem 1rem 0 0;
  line-height: 3.5rem;
}
#theme-container.joy .square .title {
  font-weight: 100;
  grid-row-end: 3;
  grid-row-start: 5;
}
@media (max-width: 480px) {
  #theme-container.joy .square {
    margin: 10px;
    width: calc(33.3333333vw - 20px - 25px * 2 / 3);
    height: calc(33.3333333vw - 20px - 25px * 2 / 3);
  }
  #theme-container.joy .square .title {
    font-size: 0.7rem;
  }
  #theme-container.joy .square .widget {
    font-size: 1.2rem;
    margin: 0.5rem 0.5rem 0 0;
  }
}
@media (min-width: 480px) and (max-width: 680px) {
  #theme-container.joy .square {
    margin: 10px;
    width: calc(33.3333333vw - 20px - 25px * 2 / 3);
    height: calc(33.3333333vw - 20px - 25px * 2 / 3);
  }
  #theme-container.joy .square .title {
    font-size: 1.1rem;
  }
}
@media (min-width: 680px) and (max-width: 900px) {
  #theme-container.joy .square {
    margin: 10px;
    width: calc(25vw - 20px - 25px * 2 / 4);
    height: calc(25vw - 20px - 25px * 2 / 4);
  }
}
@media (min-width: 900px) and (max-width: 1250px) {
  #theme-container.joy .square {
    margin: 12px;
    width: calc(20vw - 24px - 25px * 2 / 5);
    height: calc(20vw - 24px - 25px * 2 / 5);
  }
}
@media (min-width: 1250px) and (max-width: 1500px) {
  #theme-container.joy .square {
    margin: 12px;
    width: calc(16.666666666vw - 24px - 25px * 2 / 6);
    height: calc(16.666666666vw - 24px - 25px * 2 / 6);
  }
}
@media (min-width: 1500px) and (max-width: 2000px) {
  #theme-container.joy .square {
    margin: 15px;
    width: calc(14.285vw - 30px - 25px * 2 / 7);
    height: calc(14.285vw - 30px - 25px * 2 / 7);
  }
}
@media (min-width: 2000px) {
  #theme-container.joy .square {
    margin: 15px;
    width: calc(12.5vw - 30px - 25px * 2 / 8);
    height: calc(12.5vw - 30px - 25px * 2 / 8);
  }
}
#theme-container.joy .square.concierge {
  background-image: url("img/buttons/concierge.svg");
  background-position-y: 25%;
}
#theme-container.joy .square.amenities {
  background-image: url("img/buttons/amenities.svg");
}
#theme-container.joy .square.directory {
  background-image: url("img/buttons/contacts.svg");
  background-size: 40%;
  background-position-y: 22%;
}
#theme-container.joy .square.deliveries {
  background-image: url("img/buttons/deliveries.svg");
}
#theme-container.joy .square.expenses {
  background-image: url("img/buttons/expenses.svg");
}
#theme-container.joy .square.messages {
  background-image: url("img/buttons/messages.svg");
  background-position-y: 32%;
}
#theme-container.joy .square.guest_auth {
  background-image: url("img/buttons/guest_auth.svg");
  background-position-y: 33%;
}
#theme-container.joy .square.servicios {
  background-image: url("img/buttons/servicios.svg");
}
#theme-container.joy .square.questions {
  background-image: url("img/buttons/questions.svg");
}
#theme-container.joy .square.emergency {
  background-image: url("img/buttons/emergency.svg");
  background-color: red;
  border: red;
  background-position-y: 25%;
  color: white;
  background-size: 40%;
}
#theme-container.joy .square.maintenance {
  background-image: url("img/buttons/maintenance.svg");
  background-position-y: 33%;
}
#theme-container.joy .square.work_orders {
  background-image: url("img/buttons/mantenimiento_basico.svg");
}
#theme-container.joy .square.automation {
  background-image: url("img/buttons/domotica.svg");
  background-size: 70%;
  background-position-y: 21%;
}
#theme-container.joy .square.others {
  background-image: url("img/buttons/otros.svg");
  background-size: 50%;
  background-position-y: 35%;
}
#theme-container.joy .square.suggestion {
  background-image: url("img/buttons/sugerencias.svg");
  background-size: 60%;
}
#theme-container.joy .square.help_app {
  background-image: url("img/buttons/ayuda_con_la_app.svg");
  background-size: 60%;
}
#theme-container.joy .square.mucama {
  background-image: url("img/buttons/mucama.svg");
  background-position-y: 27%;
}
#theme-container.joy .square.taxi {
  background-image: url("img/buttons/taxi.svg");
  background-size: 50%;
  background-position-y: 34%;
}
#theme-container.joy .square.generic_concierge {
  background-image: url("img/buttons/asistencia.svg");
}
#theme-container.joy .square.p_trainer {
  background-image: url("img/buttons/e_personal.svg");
}
#theme-container.joy .square.fisio {
  background-image: url("img/buttons/fisio.svg");
  background-position-y: 38%;
}
#theme-container.joy .square.masajista {
  background-image: url("img/buttons/masajes.svg");
  background-position-y: 35%;
}
#theme-container.joy .square.niniera_personal {
  background-image: url("img/buttons/niniera.svg");
  background-position-y: 31%;
}
#theme-container.joy .square.niniera_personal .title {
  font-size: 0.8rem;
}
@media (max-width: 480px) {
  #theme-container.joy .square.niniera_personal .title {
    font-size: 0.7rem;
  }
}
#theme-container.joy .square.pilates {
  background-image: url("img/buttons/pilates.svg");
  background-position-y: 40%;
}
#theme-container.joy .square.fire {
  background-image: url("img/buttons/fire.svg");
  background-size: 37%;
}
#theme-container.joy .square.security {
  background-image: url("img/buttons/security.svg");
}
#theme-container.joy .square.medical {
  background-image: url("img/buttons/medic.svg");
  background-size: 34%;
}
#theme-container.joy .square.reservable_88 {
  background-image: url("img/buttons/sala_de_masajes.svg");
  background-position-y: 34%;
}
#theme-container.joy .square.reservable_82 {
  background-image: url("img/buttons/fogon.svg");
}
#theme-container.joy .square.reservable_76 {
  background-image: url("img/buttons/barbacoas.svg");
  background-position-y: 37%;
}
#theme-container.joy .square.reservable_90, #theme-container.joy .square.reservable_91 {
  background-image: url("img/buttons/jacuzzi.svg");
}
#theme-container.joy .square.reservable_84 {
  background-image: url("img/buttons/saunas.svg");
}
#theme-container.joy .square.reservable_83 {
  background-image: url("img/buttons/mascotas.svg");
  background-position-y: 37%;
  background-size: 45%;
}
#theme-container.joy .square.reservable_81 {
  background-image: url("img/buttons/microcine.svg");
}
#theme-container.joy .square.reservable_80 {
  background-image: url("img/buttons/bcenter.svg");
  background-position-y: 38%;
}
#theme-container.joy .square.reservable_79 {
  background-image: url("img/buttons/pantry.svg");
  background-size: 60%;
}
#theme-container.joy .square.reservable_92 {
  background-image: url("img/buttons/sala_de_lectura.svg");
  background-size: 60%;
}

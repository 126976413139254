#theme-container.avenue89 .settings .mail .own-email {
  color: rgb(6, 61, 22);
}
#theme-container.avenue89 .dashboard {
  background: gray;
}
#theme-container.avenue89 .dashboard .header {
  color: rgb(99, 102, 106);
  height: 12rem;
  background-image: url("img/Cybersuite_Black_Icon.png");
  background-size: 10rem;
  background-repeat: no-repeat;
  background-position: left center;
  z-index: 2;
}
#theme-container.avenue89 .dashboard .header a {
  color: rgb(99, 102, 106);
}
#theme-container.avenue89 .dashboard .header img {
  height: 5.8rem;
}
#theme-container.avenue89 .dashboard .hidden-menu .inner {
  z-index: 2;
  background: black;
}
#theme-container.avenue89 .dashboard .hidden-menu a {
  color: white;
}
#theme-container.avenue89 .dashboard .frame {
  background: rgba(0, 0, 0, 0.7);
}
#theme-container.avenue89 .dashboard .text {
  color: white;
}
#theme-container.avenue89 .dashboard label {
  color: white;
}
#theme-container.avenue89 .dashboard > div > .board {
  margin: 0;
  border: unset;
  position: relative;
}
#theme-container.avenue89 .dashboard > div > .board:before {
  content: "";
  width: 100%;
  height: 100%;
  position: fixed;
  background-image: url("img/background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  filter: grayscale(90%);
  z-index: 1;
  bottom: 0;
}
#theme-container.avenue89 .dashboard > div > .board:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1;
}
#theme-container.avenue89 .dashboard > div > .board > * {
  z-index: 2;
}
#theme-container.avenue89 .dashboard .ant-slider .ant-slider-track {
  background: rgb(69, 64, 64);
}
#theme-container.avenue89 .dashboard .ant-slider .ant-slider-rail {
  background-color: rgb(201, 201, 201);
}
#theme-container.avenue89 .dashboard .ant-slider .ant-slider-handle {
  background: rgb(69, 64, 64);
  border-color: rgba(233, 233, 233, 0.61);
  box-shadow: none;
}
#theme-container.avenue89 .dashboard .ant-slider:focus .ant-slider-track,
#theme-container.avenue89 .dashboard .ant-slider:hover .ant-slider-track {
  background: black;
}
#theme-container.avenue89 .dashboard .ant-slider:focus .ant-slider-rail,
#theme-container.avenue89 .dashboard .ant-slider:hover .ant-slider-rail {
  background-color: rgb(201, 201, 201);
}
#theme-container.avenue89 .dashboard .ant-slider:focus .ant-slider-handle,
#theme-container.avenue89 .dashboard .ant-slider:hover .ant-slider-handle {
  background: black;
  border-color: white;
  box-shadow: none;
}
#theme-container.avenue89 .page-header h1 {
  font-size: 2.5rem;
  letter-spacing: 2px;
  color: rgb(99, 102, 106);
  font-weight: normal;
}
#theme-container.avenue89 .page-header .top {
  color: white;
}
#theme-container.avenue89 .page-header .top .back-link .icon {
  color: white;
  font-size: 2rem;
}
#theme-container.avenue89 .page-header h1.title {
  font-size: 4rem;
  letter-spacing: 1px;
  color: white;
  text-transform: capitalize;
  margin-left: 2rem;
  margin-bottom: 1rem;
  text-align: left;
  font-weight: normal;
  text-align: left;
}
#theme-container.avenue89 .reservables .ant-fullcalendar-fullscreen .ant-fullcalendar-header {
  background: rgb(43, 44, 45);
  border: none;
}
#theme-container.avenue89 .reservables .ant-fullcalendar-fullscreen .ant-fullcalendar-calendar-body .ant-fullcalendar-column-header {
  background: rgb(43, 44, 45);
  border: none;
}
#theme-container.avenue89 .reservables .ant-fullcalendar-fullscreen .ant-select-selection--single {
  background: none;
}
#theme-container.avenue89 .square.chance_of_rain {
  background-image: url("../../weather/minimal/sunny_rain.png");
}
#theme-container.avenue89 .square.chance_of_snow {
  background-image: url("../../weather/minimal/sunny_rain.png");
}
#theme-container.avenue89 .square.chance_of_storm {
  background-image: url("../../weather/minimal/sunny_rain.png");
}
#theme-container.avenue89 .square.chance_of_tstorm {
  background-image: url("../../weather/minimal/sunny_storm.png");
}
#theme-container.avenue89 .square.chanceflurries {
  background-image: url("../../weather/minimal/sunny_storm.png");
}
#theme-container.avenue89 .square.chancerain {
  background-image: url("../../weather/minimal/sunny_rain.png");
}
#theme-container.avenue89 .square.chancesleet {
  background-image: url("../../weather/minimal/sunny_rain.png");
}
#theme-container.avenue89 .square.chancesnow {
  background-image: url("../../weather/minimal/sunny_rain.png");
}
#theme-container.avenue89 .square.chancetstorms {
  background-image: url("../../weather/minimal/sunny_storm.png");
}
#theme-container.avenue89 .square.clear {
  background-image: url("../../weather/minimal/sunny.png");
}
#theme-container.avenue89 .square.cloudy {
  background-image: url("../../weather/minimal/cloudy.png");
}
#theme-container.avenue89 .square.dust {
  background-image: url("../../weather/minimal/sunny_cloudy.png");
}
#theme-container.avenue89 .square.flurries {
  background-image: url("../../weather/minimal/sunny_rain.png");
}
#theme-container.avenue89 .square.fog {
  background-image: url("../../weather/minimal/cloudy.png");
}
#theme-container.avenue89 .square.haze {
  background-image: url("../../weather/minimal/cloudy.png");
}
#theme-container.avenue89 .square.hazy {
  background-image: url("../../weather/minimal/cloudy.png");
}
#theme-container.avenue89 .square.icy {
  background-image: url("../../weather/minimal/cloudy.png");
}
#theme-container.avenue89 .square.mist {
  background-image: url("../../weather/minimal/cloudy.png");
}
#theme-container.avenue89 .square.mostly_cloudy {
  background-image: url("../../weather/minimal/cloudy.png");
}
#theme-container.avenue89 .square.mostly_sunny {
  background-image: url("../../weather/minimal/sunny_cloudy.png");
}
#theme-container.avenue89 .square.mostlycloudy {
  background-image: url("../../weather/minimal/cloudy.png");
}
#theme-container.avenue89 .square.mostlysunny {
  background-image: url("../../weather/minimal/sunny_cloudy.png");
}
#theme-container.avenue89 .square.partly_cloudy {
  background-image: url("../../weather/minimal/sunny_cloudy.png");
}
#theme-container.avenue89 .square.partlycloudy {
  background-image: url("../../weather/minimal/sunny_cloudy.png");
}
#theme-container.avenue89 .square.partlysunny {
  background-image: url("../../weather/minimal/sunny_cloudy.png");
}
#theme-container.avenue89 .square.rain {
  background-image: url("../../weather/minimal/rain.png");
}
#theme-container.avenue89 .square.sleet {
  background-image: url("../../weather/minimal/cloudy.png");
}
#theme-container.avenue89 .square.smoke {
  background-image: url("../../weather/minimal/cloudy.png");
}
#theme-container.avenue89 .square.snow {
  background-image: url("../../weather/minimal/rain.png");
}
#theme-container.avenue89 .square.storm {
  background-image: url("../../weather/minimal/storm.png");
}
#theme-container.avenue89 .square.sunny {
  background-image: url("../../weather/minimal/sunny.png");
}
#theme-container.avenue89 .square.thunderstorm {
  background-image: url("../../weather/minimal/storm.png");
}
#theme-container.avenue89 .square.tstorms {
  background-image: url("../../weather/minimal/storm.png");
}
#theme-container.avenue89 .square.nt_clear {
  background-image: url("../../weather/minimal/sunny.png");
}
#theme-container.avenue89 .weather .board {
  position: relative;
}
#theme-container.avenue89 .weather .board .square {
  background-repeat: no-repeat;
  background-position: center;
}
#theme-container.avenue89 .weather .board:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  z-index: -1;
}
#theme-container.avenue89 .automation.box .widgets-box h2 {
  background: rgba(255, 255, 255, 0.4);
  color: rgb(86, 86, 86);
}
#theme-container.avenue89 .automation.box .widgets-box .zones {
  background: rgba(0, 0, 0, 0.54);
}
#theme-container.avenue89 .automation.box .widgets-box .zones .ant-btn {
  background: rgb(125, 125, 125);
  border: none;
}
#theme-container.avenue89 .automation.box .widgets-box .zones .ant-btn:hover {
  color: white;
}
#theme-container.avenue89 .automation.box .widgets-box .zones .ant-btn:focus {
  color: white;
  box-shadow: none;
}
#theme-container.avenue89 .automation.box .widgets-box .zones .widget.active:hover,
#theme-container.avenue89 .automation.box .widgets-box .zones .widget.active {
  background: white;
  color: white;
}
#theme-container.avenue89 .custom .text {
  font-size: 14px;
  font-weight: lighter;
}
#theme-container.avenue89 .custom .custom-form {
  background: unset;
}
#theme-container.avenue89 .custom .custom-form .form-input {
  padding: 10px;
  display: block;
}
#theme-container.avenue89 .custom .custom-form .form-input label {
  color: white;
  margin: 15px 5px;
}
#theme-container.avenue89 .custom .custom-form h1, #theme-container.avenue89 .custom .custom-form h2, #theme-container.avenue89 .custom .custom-form h3, #theme-container.avenue89 .custom .custom-form h4, #theme-container.avenue89 .custom .custom-form h5, #theme-container.avenue89 .custom .custom-form h6, #theme-container.avenue89 .custom .custom-form p {
  color: white;
}
#theme-container.avenue89 .square {
  color: #fff;
  background: rgba(0, 0, 0, 0);
  background-size: cover;
  margin: 0;
  border-style: solid;
  border-color: rgba(150, 150, 150, 0.5);
  border-width: 1px;
}
@media (max-width: 480px) {
  #theme-container.avenue89 .square {
    width: 33.3333333vw;
    height: 33.3333333vw;
  }
}
@media (min-width: 480px) and (max-width: 680px) {
  #theme-container.avenue89 .square {
    width: 33.3333333vw;
    height: 33.3333333vw;
  }
}
@media (min-width: 680px) and (max-width: 900px) {
  #theme-container.avenue89 .square {
    width: 25vw;
    height: 25vw;
  }
}
@media (min-width: 900px) and (max-width: 1250px) {
  #theme-container.avenue89 .square {
    width: 20vw;
    height: 20vw;
  }
}
@media (min-width: 1250px) and (max-width: 1500px) {
  #theme-container.avenue89 .square {
    width: 16.666666666vw;
    height: 16.666666666vw;
  }
}
@media (min-width: 1500px) and (max-width: 2000px) {
  #theme-container.avenue89 .square {
    width: 14.285vw;
    height: 14.285vw;
  }
}
@media (min-width: 2000px) {
  #theme-container.avenue89 .square {
    width: 12.5vw;
    height: 12.5vw;
  }
}
#theme-container.avenue89 .square .widget {
  font-size: 3rem;
  margin: 0.5rem 1rem 0 0;
  line-height: 3.5rem;
}
#theme-container.avenue89 .square.afternoon_tea {
  background-image: url("img/buttons/afternoon_tea.png");
}
#theme-container.avenue89 .square.amenities {
  background-image: url("img/buttons/amenities.png");
}
#theme-container.avenue89 .square.automation {
  background-image: url("img/buttons/automation.png");
}
#theme-container.avenue89 .square.barbecue {
  background-image: url("img/buttons/barbecue.png");
}
#theme-container.avenue89 .square.beach_bar_and_grill_menu {
  background-image: url("img/buttons/beach_bar_and_grill.png");
}
#theme-container.avenue89 .square.beach_chairs {
  background-image: url("img/buttons/beach_chairs.png");
}
#theme-container.avenue89 .square.business_center {
  background-image: url("img/buttons/business_center.png");
}
#theme-container.avenue89 .square.care_care_services {
  background-image: url("img/buttons/care_care_services.png");
}
#theme-container.avenue89 .square.catering {
  background-image: url("img/buttons/catering.png");
}
#theme-container.avenue89 .square.child_care {
  background-image: url("img/buttons/child_care.png");
}
#theme-container.avenue89 .square.childrens_room {
  background-image: url("img/buttons/childrens_room.png");
}
#theme-container.avenue89 .square.class_schedule {
  background-image: url("img/buttons/class_schedules.png");
}
#theme-container.avenue89 .square.club_room {
  background-image: url("img/buttons/club_room.png");
}
#theme-container.avenue89 .square.concierge {
  background-image: url("img/buttons/concierge.png");
}
#theme-container.avenue89 .square.construction {
  background-image: url("img/buttons/construction.png");
}
#theme-container.avenue89 .square.contact_us {
  background-image: url("img/buttons/contact_us.png");
}
#theme-container.avenue89 .square.contacts {
  background-image: url("img/buttons/contacts.png");
}
#theme-container.avenue89 .square.deliveries {
  background-image: url("img/buttons/deliveries.png");
}
#theme-container.avenue89 .square.dry_cleaning {
  background-image: url("img/buttons/dry_cleaning.png");
}
#theme-container.avenue89 .square.fitness_room {
  background-image: url("img/buttons/fitness_room.png");
}
#theme-container.avenue89 .square.floral_services {
  background-image: url("img/buttons/floral_services.png");
}
#theme-container.avenue89 .square.food {
  background-image: url("img/buttons/food.png");
}
#theme-container.avenue89 .square.food_breakfast {
  background-image: url("img/buttons/breakfast.png");
}
#theme-container.avenue89 .square.hours_of_operation_food {
  background-image: url("img/buttons/hours_of_operation.png");
}
#theme-container.avenue89 .square.hours_of_operation_wellness {
  background-image: url("img/buttons/hours_of_operation.png");
}
#theme-container.avenue89 .square.house_keeping {
  background-image: url("img/buttons/house_keeping.png");
}
#theme-container.avenue89 .square.juice_bar {
  background-image: url("img/buttons/juice_bar.png");
}
#theme-container.avenue89 .square.kayak {
  background-image: url("img/buttons/kayak.png");
}
#theme-container.avenue89 .square.languages {
  background-image: url("img/buttons/languages.png");
}
#theme-container.avenue89 .square.library {
  background-image: url("img/buttons/library.png");
}
#theme-container.avenue89 .square.live_feed {
  background-image: url("img/buttons/live_feed.png");
}
#theme-container.avenue89 .square.maintenance {
  background-image: url("img/buttons/maintenance.png");
}
#theme-container.avenue89 .square.manicure {
  background-image: url("img/buttons/manicure.png");
}
#theme-container.avenue89 .square.messages {
  background-image: url("img/buttons/messages.png");
}
#theme-container.avenue89 .square.night_life {
  background-image: url("img/buttons/night_life.png");
}
#theme-container.avenue89 .square.other_requests {
  background-image: url("img/buttons/other_requests.png");
}
#theme-container.avenue89 .square.paddle_boards {
  background-image: url("img/buttons/paddle_boards.png");
}
#theme-container.avenue89 .square.pantry_stocking {
  background-image: url("img/buttons/pantry_stocking.png");
}
#theme-container.avenue89 .square.pilates_reformer {
  background-image: url("img/buttons/pilates_reformer.png");
}
#theme-container.avenue89 .square.poker_lounge {
  background-image: url("img/buttons/poker_lounge.png");
}
#theme-container.avenue89 .square.pool_and_beach {
  background-image: url("img/buttons/pool_and_beach.png");
}
#theme-container.avenue89 .square.pool_cabanas {
  background-image: url("img/buttons/pool_cabanas.png");
}
#theme-container.avenue89 .square.request_car {
  background-image: url("img/buttons/request_car.png");
}
#theme-container.avenue89 .square.reservation {
  background-image: url("img/buttons/reservation.png");
}
#theme-container.avenue89 .square.resident_feedback {
  background-image: url("img/buttons/resident_feedback.png");
}
#theme-container.avenue89 .square.restaurant_reservation {
  background-image: url("img/buttons/restaurant_reservation.png");
}
#theme-container.avenue89 .square.settings {
  background-image: url("img/buttons/settings.png");
}
#theme-container.avenue89 .square.spa_menu {
  background-image: url("img/buttons/spa_menu.png");
}
#theme-container.avenue89 .square.spa_treatment_reservation {
  background-image: url("img/buttons/reservation.png");
}
#theme-container.avenue89 .square.sunset_sunrise {
  background-image: url("img/buttons/sunset_sunrise.png");
}
#theme-container.avenue89 .square.surf_conditions {
  background-image: url("img/buttons/surf_conditions.png");
}
#theme-container.avenue89 .square.teens_tech_lounge {
  background-image: url("img/buttons/teens_tech_lounge.png");
}
#theme-container.avenue89 .square.transportation {
  background-image: url("img/buttons/transportation.png");
}
#theme-container.avenue89 .square.valet {
  background-image: url("img/buttons/valet.png");
}
#theme-container.avenue89 .square.water_therapy_terrace {
  background-image: url("img/buttons/water_therapy_terrace.png");
}
#theme-container.avenue89 .square.wellness {
  background-image: url("img/buttons/wellness.png");
}
#theme-container.avenue89 .square.wine_bar {
  background-image: url("img/buttons/wine_bar.png");
}
#theme-container.avenue89 .square.work_orders {
  background-image: url("img/buttons/work_orders.png");
}
#theme-container.avenue89 .square.yachting {
  background-image: url("img/buttons/yachting.png");
}
#theme-container.avenue89 .square.water_detection {
  background-image: url("img/buttons/water_detection.png");
}
#theme-container.avenue89 .square.energy_monitoring {
  background-image: url("img/buttons/enery_monitoring.png");
}
#theme-container.avenue89 .page-ul .li-request {
  margin: 0.7rem 1.5rem;
  margin-top: 6px;
}
@media (max-width: 480px) {
  #theme-container.avenue89 .page-ul .li-request {
    margin: 0.7rem 0;
  }
}
@media (min-width: 480px) and (max-width: 680px) {
  #theme-container.avenue89 .page-ul .li-request {
    margin: 0.7rem 0;
  }
}
@media (min-width: 680px) and (max-width: 900px) {
  #theme-container.avenue89 .page-ul .li-request {
    margin: 0.7rem 0;
  }
}
#theme-container.avenue89 .page-ul .li-request.readed {
  color: rgb(195, 195, 195);
}
#theme-container.avenue89 .page-frame:before {
  content: "";
  width: 100%;
  height: 100%;
  position: fixed;
  background-image: url("img/background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  filter: grayscale(90%);
  z-index: -1;
  bottom: 0;
}
#theme-container.avenue89 .ant-btn-primary {
  background-color: black;
  border-color: black;
}
#theme-container.avenue89 .ant-switch-checked {
  background-color: black;
}
#theme-container.avenue89 .ant-form-item-control {
  border-color: black;
}
#theme-container.avenue89 a {
  text-decoration: none;
  color: black;
}
#theme-container.avenue89 a:hover {
  color: white;
}
#theme-container.avenue89 .ant-radio-button-wrapper,
#theme-container.avenue89 .ant-time-picker-input,
#theme-container.avenue89 .ant-select-selection--single,
#theme-container.avenue89 .ant-input {
  background: rgba(255, 255, 255, 0.5);
}
#theme-container.avenue89 .ant-radio-button-wrapper-checked,
#theme-container.avenue89 .ant-radio-button-wrapper:focus,
#theme-container.avenue89 .ant-radio-button-wrapper:hover {
  color: black;
  border-color: grey;
}
#theme-container.avenue89 .ant-btn:focus,
#theme-container.avenue89 .ant-btn:hover,
#theme-container.avenue89 .ant-select-selection--single:focus,
#theme-container.avenue89 .ant-select-selection:focus,
#theme-container.avenue89 .ant-time-picker-input:focus,
#theme-container.avenue89 .ant-input:focus {
  color: black;
  border-color: grey;
  box-shadow: 0px 0px 10px 3px white;
}
#theme-container.avenue89 .ant-btn-primary:focus,
#theme-container.avenue89 .ant-btn-primary:hover {
  color: white;
}
#theme-container.avenue89 .ant-select-selection--single:hover,
#theme-container.avenue89 .ant-select-selection:hover,
#theme-container.avenue89 .ant-time-picker-input:hover,
#theme-container.avenue89 .ant-input:hover {
  border-color: black;
}
